/* eslint-disable no-restricted-syntax */
import { isInactiveOptionType } from '../provider/helpers';

/**
 * Helper functions to validate conflicts dependencies
 */
const equalSets = (setA, setB) => {
  if (setA.length !== setB.length) return false;
  for (const a of setA) if (!setB.find((b) => b.ec === a.ec)) return false;
  return true;
};
const findContradictEc = (ecItem, bucket) => {
  for (const ec of ecItem.excludes)
    if (bucket.find((i) => i.ec === ec)) return ec;
  return null;
};
const pushBucket = (destBuckets, srcBuckets) => {
  for (const bucket of srcBuckets)
    if (!destBuckets.some((dest) => equalSets(dest, bucket)))
      destBuckets.push(bucket);
};

const getConflictEcList = (optionalECs) => {
  const ecList = [];
  optionalECs.forEach((cat) => {
    ecList.push(
      ...cat.data
        .filter(({ inputOptionType }) => !isInactiveOptionType(inputOptionType))
        .map(({ id, equipmentCode: ec, includes, excludes, requires }) => ({
          id,
          ec,
          includes: includes.value,
          excludes: excludes.value,
          requires: requires.value,
        })),
    );
  });
  return ecList;
};

const processMust = (EC, parent, relation, buckets, conflictECs) => {
  const newBuckets = [];
  for (const bucket of buckets) {
    const me = conflictECs.find(({ ec }) => EC === ec);
    if (!me || bucket.find(({ ec }) => EC === ec)) newBuckets.push(bucket);
    else {
      const copyBucket = [...bucket];
      copyBucket.push({ ...me, parent, relation });
      if (!me.includes.length && !me.requires.length)
        newBuckets.push(copyBucket);
      else {
        const myNewBuckets = [];
        const myrequires = me.requires.filter((req) => req.length > 0);
        const requires = myrequires.length ? myrequires : [[]];
        for (const R of requires) {
          let myBuckets = [[...copyBucket]];
          for (const ec of me.includes) {
            const newEcBuckets = [];
            for (const myBucket of myBuckets) {
              const ecBuckets = processMust(
                ec,
                EC,
                'includes',
                [myBucket],
                conflictECs,
              );
              newEcBuckets.push(...ecBuckets);
            }
            myBuckets = newEcBuckets;
          }
          for (const ec of R) {
            const newEcBuckets = [];
            for (const myBucket of myBuckets) {
              const ecBuckets = processMust(
                ec,
                EC,
                'requires',
                [myBucket],
                conflictECs,
              );
              newEcBuckets.push(...ecBuckets);
            }
            myBuckets = newEcBuckets;
          }
          pushBucket(myNewBuckets, myBuckets);
        }
        pushBucket(newBuckets, myNewBuckets);
      }
    }
  }
  return newBuckets;
};

const traceConflict = (ec, list) => {
  const ecItem = list.find((i) => i.ec === ec);
  if (ecItem.parent) {
    const trace = traceConflict(ecItem.parent, list);
    return `${trace} ${trace.search(' ') < 0 ? '' : 'which '}${
      ecItem.relation
    } ${ec}`;
  }
  return ec;
};

const validateEcConflict = (ec, conflictECs) => {
  const musts = processMust(ec, undefined, undefined, [[]], conflictECs);
  for (const mustSet of musts)
    for (const ecItem of mustSet) {
      const contradictEc = findContradictEc(ecItem, mustSet);
      const excTrace = traceConflict(ecItem.ec, mustSet);
      if (contradictEc)
        return {
          isValid: false,
          rootEc: ec,
          contradictEc,
          includeTrace: traceConflict(contradictEc, mustSet),
          excludeTrace: `${excTrace} ${
            excTrace.search(' ') < 0 ? '' : 'which '
          }excludes ${contradictEc}`,
        };
    }
  return { isValid: true };
};

// find contradicting conflicts
const getInvalidConflicts = (conflictECs) => {
  const invalidConflictECs = [];
  conflictECs.forEach((EC) => {
    const result = validateEcConflict(EC.ec, conflictECs);
    if (!result.isValid) invalidConflictECs.push({ id: EC.id, ...result });
  });
  return invalidConflictECs;
};

// validate original conflicts
export const getInvalidConflictECs = (optECs) => {
  const conflictECs = getConflictEcList(optECs);
  return getInvalidConflicts(conflictECs);
};

// re-validate conflicts with updated field value
export const validateConflicts = (EC, field, value, optionalECs) => {
  const conflictECs = getConflictEcList(optionalECs);
  const ecIndex = conflictECs.findIndex(({ ec }) => ec === EC);
  conflictECs.splice(ecIndex, 1, {
    ...conflictECs[ecIndex],
    [field]: value,
  });
  return getInvalidConflicts(conflictECs);
};

export const getInvalidConflictECsByEC = (invalidECs, ec) => {
  const regex = `(^${ec}$)|(^${ec} )|( ${ec} )|( ${ec}$)`;
  return invalidECs.filter(
    ({ includeTrace, excludeTrace }) =>
      includeTrace.search(regex) >= 0 || excludeTrace.search(regex) >= 0,
  );
};

export const exportsForTesting = {
  equalSets,
  pushBucket,
};
