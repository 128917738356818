import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@gsa/afp-component-library';
import { useAppAbility, useCurrentUser } from '@gsa/afp-shared-ui-utils';
import Attachment from '../../../../components/Attachment';
import AttachmentProvider, {
  useAttachments,
} from '../../../../components/Attachment/AttachmentProvider';

import { DELETE_FVS_DOC, GET_STANDARDS_REL_DOCS } from '../../fvs.gql';
import { useFederalStandards } from '../../fvs-provider';
import { useFvsSupportingDocs } from '../../fvs-supporting-docs-provider';
import {
  OPERATIONS,
  SUBJECTS,
  UPLOAD_FILE_TYPES,
  MAX_ATTACHMENT_SIZE_200,
} from '../../../../utilities/constants';

const AttachmentWrapper = ({ uploadPath, allowChanges }) => {
  const {
    fetching,
    rows,
    count,
    hasMore,
    loadMore,
    saving,
    saved,
    updating,
    updated,
    deleting,
    currentFetchVariables: { limit },
    downloadAttachment,
    deleteAttachment,
    sortAttachment,
    saveAttachment,
    updateDescription,
    attachmentErrors,
    setAttachmentErrors,
    currentUser,
    linkedEntities,
    uploadButtonLabel,
    updateDocumentStatus,
  } = useAttachments();

  const { vehicleStandard } = useFederalStandards();

  const { uploadFvsDocument } = useFvsSupportingDocs();

  const handleDocUpload = async (formData) => {
    const docMetadata = await uploadFvsDocument({
      variables: {
        input: {
          fvsId: Number.parseInt(vehicleStandard?.id, 10),
          fvsCode: vehicleStandard?.fedStandardCode,
          name: formData.get('attachment').name,
          description: formData.get('description'),
          size: formData.get('attachment').size,
          fileMimeType: formData.get('attachment').type,
          status: formData.get('status'),
        },
      },
    });

    if (docMetadata?.data?.uploadSupportingDoc) {
      await saveAttachment(formData, docMetadata.data.uploadSupportingDoc);
    }
  };

  return (
    <div className="attachment" data-testid="attachment-container">
      <Attachment
        title=""
        loading={fetching}
        data={rows}
        count={count}
        hasMore={hasMore}
        loadMoreLimit={5}
        paginationLimit={limit}
        uploadPath={uploadPath}
        onLoadMore={loadMore}
        adding={saving}
        added={saved}
        updating={updating}
        updated={updated}
        deleting={deleting}
        onDownload={downloadAttachment}
        onSort={sortAttachment}
        onDelete={deleteAttachment}
        onUpdateDescription={updateDescription}
        onAdd={handleDocUpload}
        currentUser={currentUser}
        errors={attachmentErrors}
        setErrors={setAttachmentErrors}
        linkedEntities={linkedEntities}
        allowChanges={allowChanges}
        uploadButtonLabel={uploadButtonLabel}
        isFromTaskDetail={false}
        updateDocumentStatus={updateDocumentStatus}
        fileTypes={UPLOAD_FILE_TYPES.fvsSupportingDoc}
        maxFileSize={MAX_ATTACHMENT_SIZE_200} // 200MB
      />
    </div>
  );
};

AttachmentWrapper.defaultProps = {
  allowChanges: true,
  uploadPath: '',
};
AttachmentWrapper.propTypes = {
  allowChanges: PropTypes.bool,
  uploadPath: PropTypes.string,
};

const VehicleStandardsSupportingDoc = () => {
  const { vehicleStandard } = useFederalStandards();

  const {
    getStandardsSupportingDocs,
    setSupportingDocs,
    isAttachmentLoading,
  } = useFvsSupportingDocs();

  const ability = useAppAbility();

  const [showAttachments, setShowAttachments] = useState(false);

  const allowChanges = ability.can(OPERATIONS.Update, SUBJECTS.FVS);

  const { currentUser } = useCurrentUser();

  useEffect(() => {
    setShowAttachments(!isAttachmentLoading);
  }, [isAttachmentLoading]);

  useEffect(() => {
    if (vehicleStandard && vehicleStandard.id) {
      getStandardsSupportingDocs({
        variables: {
          filters: {
            operator: 'AND',
            value: [
              {
                operator: 'EQ',
                key: 'fvsId',
                value: vehicleStandard.id,
              },
            ],
          },
          limit: 5,
          offset: 0,
          order: 'createdAt DESC',
        },
      });
    }
  }, [vehicleStandard]);

  return (
    <>
      {!showAttachments && <Spinner size="small" />}

      {showAttachments && (
        <AttachmentProvider
          uploadButtonLabel="Add supporting document"
          fetchGql={GET_STANDARDS_REL_DOCS}
          deleteGql={DELETE_FVS_DOC}
          fetchVariables={{
            filters: {
              operator: 'AND',
              value: [
                {
                  operator: 'EQ',
                  key: 'fvsId',
                  value: vehicleStandard?.id,
                },
              ],
            },
            limit: 5,
            offset: 0,
            order: 'createdAt DESC',
          }}
          setParentContext={setSupportingDocs}
          linkedEntities={{}}
          currentUser={currentUser}
        >
          <AttachmentWrapper allowChanges={allowChanges} />
        </AttachmentProvider>
      )}
    </>
  );
};

export default VehicleStandardsSupportingDoc;
