/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { Spinner } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import {
  reopenPeriodModalVendorIds,
  vendorIds,
} from '../atoms/solicitation-helpers';
import useVendor from '../add-edit-solicitation/apis/vendor-apis';
import AudienceListSelectionBlock from './audience-list-selection-block';
import '../styles/select-standard.scss';

const AudienceList = ({ audienceList, isModal }) => {
  const [selections, setSelections] = useState([]);
  const [, setVendorIds] = useRecoilState(
    isModal ? reopenPeriodModalVendorIds : vendorIds,
  );
  const {
    vendors,
    activeBidders,
    contractAwardees,
    getContractAwardees,
    getActiveBidders,
    getVendors,
    isVendorDataLoading,
    isActiveDataLoading,
  } = useVendor();

  const { id } = useParams();

  useEffect(() => {
    if (audienceList) {
      const selectedVendors = audienceList.map((item) => ({
        vendorId: item.vendorId,
        audienceType: item.audienceType,
      }));
      setSelections(selectedVendors);
      if (selectedVendors.length) setVendorIds(selectedVendors);
    }
  }, [audienceList]);

  useEffect(() => {
    getVendors({
      variables: {
        filters: {
          operator: '$and',
          conditions: [
            {
              operator: '$in',
              key: 'vendorTypeCode',
              value: ['VehMfg', 'VehSupplier'],
            },
            {
              operator: '$exact',
              key: 'fleetStatus',
              value: 'Active',
            },
          ],
        },
        offset: 0,
        limit: 5000,
      },
    });
  }, []);

  useEffect(() => {
    if (id) {
      getActiveBidders({ variables: { solicitationId: +id } });
      getContractAwardees({ variables: { solicitationId: +id } });
    }
  }, [id]);

  // active bidders/ vendors participated on the previous period of a solicitation
  const activeBidderOptions = (activeBidders?.rows ?? []).map((vendor) => ({
    value: vendor.id,
    label: vendor.vendorName,
  }));

  // awardees/vendors with a contract associated with the solicitation
  const contractAwardeeOptions = (contractAwardees?.rows ?? []).map(
    (vendor) => ({
      value: vendor.vendorId,
      label: vendor.vendorName,
    }),
  );

  // all vendors which are not active bidders or awardees
  const filteredCustomVendorOptions = (vendors?.rows ?? [])
    .map((vendor) => ({
      value: vendor.id,
      label: vendor.vendorName,
    }))
    .filter(
      (vendor) =>
        !activeBidderOptions.some((bidder) => bidder.value === vendor.value) &&
        !contractAwardeeOptions.some(
          (awardee) => awardee.value === vendor.value,
        ),
    );

  // audience groups config
  const audiences = [
    {
      title: 'Awardees',
      audienceType: 'Awardees',
      description:
        'All Vehicle Suppliers with a contract associated with this solicitation.',
      options: contractAwardeeOptions,
    },
    {
      title: 'Active bidders',
      audienceType: 'Bidders',
      description:
        'All Vehicle Suppliers who have bid on this solicitation in a prior open period this contact year.',
      options: activeBidderOptions,
    },
    {
      title: 'Custom audience',
      audienceType: 'Custom',
      description:
        'Ad hoc selection of Vehicle Suppliers. You may also use this to add additional Vehicle Suppliers to an Awardees or Active bidders selection.',
      options: filteredCustomVendorOptions,
    },
  ];

  const onSelect = (vendorId, checked, audienceType) => {
    const newSelections = checked
      ? [...selections, { vendorId, audienceType }]
      : selections.filter((sel) => sel.vendorId !== vendorId);
    setSelections(newSelections);
    setVendorIds(newSelections);
  };

  const onClear = (selectedIds) => {
    const newSelections = selections.filter(
      (sel) => !selectedIds.includes(sel.vendorId),
    );
    setSelections(newSelections);
    setVendorIds(newSelections);
  };

  return (
    <div data-testid="audience-list-container" className="grid-col-12 ">
      {(isVendorDataLoading || isActiveDataLoading) && <Spinner />}
      <div className="display-flex flex-row flex-justify margin-top-4">
        <div className="title-s-caps text-primary">Choose Vendor</div>
        <div>
          <span className="text-bold font-sans-lg">{selections.length}</span>
          <span className="padding-left-1 font-sans-lg">
            vendor{selections.length > 1 ? 's' : ''} selected
          </span>
        </div>
      </div>

      <div>
        {!isVendorDataLoading &&
          !isActiveDataLoading &&
          audiences.map((audience) => (
            <AudienceListSelectionBlock
              key={audience.audienceType}
              audience={audience}
              selections={selections}
              onSelect={onSelect}
              onClear={onClear}
            />
          ))}
      </div>
    </div>
  );
};

AudienceList.propTypes = {
  audienceList: PropTypes.shape(Object).isRequired,
  isModal: PropTypes.bool,
};
AudienceList.defaultProps = {
  isModal: PropTypes.false,
};

export default AudienceList;
