import PropTypes from 'prop-types';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import {
  GET_SOLICITATION_BY_ID_FOR_BID,
  GET_BID_LINE,
} from '../../../solicitation/queries';
import {
  GET_EQUIPMENT_OPTIONS,
  GET_GSA_COLORS,
  GET_FUEL_TYPES,
  GET_STATES,
  GET_COUNTRIES,
  GET_BID_LINE_ATTACHMENTS,
  UPDATE_BID_LINE_STATUS,
  GET_BID_LINE_HIGHLIGHT,
  REMOVE_BID_LINE_HIGHLIGHT,
  REMOVE_BID_LINE_HIGHLIGHTS,
} from './queries';
import {
  processEquipmentCodesData,
  mapEngineDataToTable,
  isEcTabReady,
  isEngineTabReady,
  isColorsTabReady,
  isDocumentationTabReady,
  isBidDetailTabReady,
} from './helpers';
import { GET_VENDOR_LOCATIONS_BY_TYPES } from '../../../../utilities/feature-toggle/get-vendors-query';
import { OPERATIONS, SUBJECTS } from '../../../../utilities/constants';
import { getInvalidConflictECs } from '../optional-equipment/conflicts-helpers';

const BidLineDetailsContext = createContext({});

const useHighLightedFields = (bidLineId, bidLineStatus) => {
  const [isShowHighlights, setIsShowHighlights] = useState(false);
  const [highlights, setHighlights] = useState([]);

  const highlightQueryResult = useQuery(GET_BID_LINE_HIGHLIGHT, {
    fetchPolicy: 'cache-and-network',
    variables: { bidLineId: parseInt(bidLineId, 10) },
    skip: !bidLineId || !bidLineStatus || bidLineStatus === 'Completed',
    onCompleted: (data) => {
      setHighlights(data.getBidLineHighlights || []);
    },
  });

  const history = useHistory();

  const ability = useAppAbility();
  const isAdmin = ability.can(OPERATIONS.Manage, SUBJECTS.All);

  const [removeHighlightMutation] = useMutation(REMOVE_BID_LINE_HIGHLIGHT);
  const [removeHighlightsMutation] = useMutation(REMOVE_BID_LINE_HIGHLIGHTS, {
    onCompleted: (data) => {
      if (data.removeBidLineHighlights) {
        history.go(0);
      }
    },
  });

  const isFieldHighlighted = useCallback(
    (tabKey, tableName, rowId, fieldName) => {
      if (
        isShowHighlights === false ||
        highlightQueryResult.loading ||
        highlightQueryResult.error
      ) {
        return false;
      }

      return highlights.some((highlight) => {
        return (
          highlight.tabKey === tabKey &&
          highlight.tableName === tableName &&
          highlight.rowId === rowId &&
          highlight.fieldName === fieldName
        );
      });
    },
    [highlights, highlightQueryResult.loading, highlightQueryResult.error],
  );

  const hasSomeHighlights = useMemo(() => {
    if (highlightQueryResult.loading || highlightQueryResult.error) {
      return false;
    }
    return highlights?.length > 0;
  }, [highlights, highlightQueryResult.loading, highlightQueryResult.error]);

  const hasSomeHighlightByRowIds = useCallback(
    (tabKey, tableName, rowIds = [], exceptFieldNames = []) => {
      if (
        isShowHighlights === false ||
        highlightQueryResult.loading ||
        highlightQueryResult.error
      ) {
        return false;
      }
      return highlights.some((highlight) => {
        return (
          highlight.tabKey === tabKey &&
          highlight.tableName === tableName &&
          rowIds?.includes(highlight.rowId) &&
          !exceptFieldNames.includes(highlight.fieldName)
        );
      });
    },
    [
      highlights,
      highlightQueryResult.loading,
      highlightQueryResult.error,
      isShowHighlights,
    ],
  );

  const hasSomeHighlightByTabKey = useCallback(
    (tabKey) => {
      if (
        isShowHighlights === false ||
        highlightQueryResult.loading ||
        highlightQueryResult.error
      ) {
        return false;
      }

      return highlights.some((highlight) => {
        return highlight.tabKey === tabKey;
      });
    },
    [
      highlights,
      highlightQueryResult.loading,
      highlightQueryResult.error,
      isShowHighlights,
    ],
  );

  const findHighlight = useCallback(
    (tabKey, tableName, rowId, fieldName) => {
      if (
        isShowHighlights === false ||
        highlightQueryResult.loading ||
        highlightQueryResult.error
      ) {
        return false;
      }

      return highlights.find((highlight) => {
        return (
          highlight.tabKey === tabKey &&
          highlight.tableName === tableName &&
          highlight.rowId === rowId &&
          highlight.fieldName === fieldName
        );
      });
    },
    [
      highlights,
      highlightQueryResult.loading,
      highlightQueryResult.error,
      isShowHighlights,
    ],
  );

  const removeHighlight = useCallback(
    (highlightId) => {
      if (isShowHighlights === false) {
        return;
      }
      const newHighlights = highlights.filter((highlight) => {
        return highlight.id !== highlightId;
      });
      setHighlights(newHighlights);
      removeHighlightMutation({
        variables: { highlightId },
      });
    },
    [highlights, removeHighlightMutation, isShowHighlights],
  );

  const removeHighlights = useCallback(() => {
    if (isShowHighlights === false) {
      return;
    }
    removeHighlightsMutation({
      variables: { bidLineId: parseInt(bidLineId, 10) },
    });
  }, [bidLineId, removeHighlightsMutation, isShowHighlights]);

  return {
    isFieldHighlighted,
    findHighlight,
    removeHighlight,
    removeHighlights,
    hasSomeHighlightByRowIds,
    hasSomeHighlightByTabKey,
    highlightQueryResult,
    hasSomeHighlights,
    isShowHighlights,
    setIsShowHighlights,
    isAdmin,
  };
};

const BidLineDetailsProvider = ({
  children,
  blId,
  isReviewSubmit,
  ...props
}) => {
  const { bidLineId, solId } = useParams();
  const receivedBlId = blId || bidLineId;
  const location = useLocation();

  const [updateBidLineStatus] = useMutation(UPDATE_BID_LINE_STATUS);
  const updateLineReadyStatus = (allTabStatuses) => {
    if (isReviewSubmit) return;
    if (allTabStatuses.every((status) => status === true)) {
      setTimeout(
        () =>
          updateBidLineStatus({
            variables: {
              bidLineId: parseInt(receivedBlId, 10),
              status: 'Ready to submit',
            },
          }),
        1000, // delay to avoid overwritten by autosave() calls
      );
    }
  };

  const [tabIndex, setTabIndex] = useState(location.state?.tabIndex || 0);
  const [tabStatuses, setTabStatuses] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const setTabStatus = (ind, status) => {
    setTabStatuses((prev) => {
      const newVals = [...prev];
      newVals.splice(ind, 1, status);
      updateLineReadyStatus(newVals);
      return newVals;
    });
  };
  const [loading, setLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(null);
  const [alert, setAlert] = useState(null);
  const [solicitation, setSolicitation] = useState(null);
  const [bidLine, setBidLine] = useState(null);
  const [gsaColors, setGsaColors] = useState([]);
  const [FuelTypes, setFuelTypes] = useState([]);
  const [States, setStates] = useState([]);
  const [Countries, setCountries] = useState([]);

  const [standardECs, setStandardECs] = useState([]);
  const [standardECTable, setStandardECTable] = useState([]);
  const [optionalECs, setOptionalECs] = useState([]);
  const [optionalECTable, setOptionalECTable] = useState([]);
  const [invalidConflictECs, setInvalidConflictECs] = useState([]);
  const [engineData, setEngineData] = useState([]);
  const [engineTable, setEngineTable] = useState([]);

  const [vendorData, setVendorData] = useState(null);

  const [colors, setColors] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [bidDetailData, setBidDetailData] = useState({});
  const updateBidDetailData = (field, value) => {
    setBidDetailData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
  const [associatedLocations, setAssociatedLocations] = useState([]);
  const [vendorLocations, setVendorLocations] = useState([]);
  const useHighLightedFieldsResults = useHighLightedFields(
    bidLineId,
    bidLine?.status,
  );

  const getStandardEC = ({ iCat, iData }) => standardECs[iCat].data[iData];
  const getOptionalEC = ({ iCat, iData }) => optionalECs[iCat].data[iData];

  const setEC = (
    setEcFn,
    setEcTableFn,
    { categoryCode, id },
    { data, table },
    isConflict,
  ) => {
    const genMapFn = (update) => (item) => {
      if (item.id !== id) return item;
      return { ...item, ...update };
    };
    if (table)
      setEcTableFn((prevItems) =>
        prevItems.map((cat) => {
          if (cat.id === categoryCode) {
            const tableItem = cat.data.find((item) => item.id === id);
            if (table.isExpanded !== tableItem.isExpanded)
              return { ...cat, data: cat.data.map(genMapFn(table)) };
          }
          return cat;
        }),
      );
    if (data)
      setEcFn((prevItems) => {
        const newItems = prevItems.map((cat) => {
          if (cat.id !== categoryCode) return cat;
          return { ...cat, data: cat.data.map(genMapFn(data)) };
        });
        if (isConflict) setInvalidConflictECs(getInvalidConflictECs(newItems));
        return newItems;
      });
  };
  const setStandardEC = (original, updates) =>
    setEC(setStandardECs, setStandardECTable, original, updates);
  const setOptionalEC = (original, updates, isConflict) =>
    setEC(setOptionalECs, setOptionalECTable, original, updates, isConflict);

  const setEngine = (id, { data, table }) => {
    if (table)
      setEngineTable((prevItems) =>
        prevItems.map((line) => {
          if (line.id !== id) return line;
          return { ...line, ...table };
        }),
      );
    if (data)
      setEngineData((prevItems) =>
        prevItems.map((line) => {
          if (line.id !== id) return line;
          return { ...line, ...data };
        }),
      );
  };

  const [getMinReq, { data: ecData, loading: mrLoading }] = useLazyQuery(
    GET_EQUIPMENT_OPTIONS,
    {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      onError: (err) => setLoadingError(err),
    },
  );

  const { loading: soliLoading } = useQuery(GET_SOLICITATION_BY_ID_FOR_BID, {
    fetchPolicy: 'cache-and-network',
    variables: { id: parseInt(solId, 10) },
    skip: !solId,
    onCompleted: ({ getSolicitationById }) => {
      if (getSolicitationById) setSolicitation(getSolicitationById);
      else setLoadingError(new Error('Solicitation not found'));
    },
    onError: (err) => setLoadingError(err),
  });

  // ! Do NOT use refetch
  const { loading: blLoading } = useQuery(GET_BID_LINE, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: { id: parseInt(receivedBlId, 10) },
    skip: !receivedBlId,
    onCompleted: ({ getBidLine }) => {
      if (getBidLine) {
        const { standardItemId } = getBidLine;
        if (standardItemId === undefined)
          setLoadingError(new Error('Missing Standard Item'));
        else {
          setVendorData({
            id: getBidLine?.bid?.vendorId,
            uniqueEntityIdentifier:
              getBidLine?.bid?.vendorDetail?.uniqueEntityIdentifier,
            name: getBidLine?.bid?.vendorDetail?.vendorName,
          });
          setBidLine(getBidLine);
          setColors(getBidLine.colors);
          setBidDetailData({
            originCountryId: getBidLine?.originCountryId,
            isIn1122Program: getBidLine?.isIn1122Program,
            shipmentDays: getBidLine?.shipmentDays,
            shipmentJustification: getBidLine?.shipmentJustification,
            unitPrice: getBidLine?.unitPrice,
            invoicePrice: getBidLine?.invoicePrice,
            msrp: getBidLine?.msrp,
            hasSPLTag: getBidLine?.standardItem?.tags?.value.some(
              (tag) => tag === 'SPL',
            ),
            asePrice: getBidLine?.asePrice,
            quantity: getBidLine?.quantity,
            destinationCharge: getBidLine?.destinationCharge,
          });
          setAssociatedLocations(getBidLine?.associatedLocations);

          // retrieve Active EQ associated with the SIN
          getMinReq({
            variables: {
              standardItemId: parseInt(standardItemId, 10),
              getActive: true,
            },
          });
        }
      } else
        setLoadingError(new Error('Bid line not found or Not authorized.'));
    },
    onError: (err) => setLoadingError(err),
  });

  const [getBidLineDocuments] = useLazyQuery(GET_BID_LINE_ATTACHMENTS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const docs = data?.getBidLineAttachments?.filter(
        (d) => d.documentType === 'D',
      );
      const pics = data?.getBidLineAttachments?.filter(
        (d) => d.documentType === 'I',
      );
      setDocuments(docs);
      setPhotos(pics);
      setTabStatus(5, isDocumentationTabReady(docs, pics));
    },
    onError: (err) => {
      setLoadingError(err);
    },
  });

  // // TODO: getVendorsByPermission for Admin user returns the whole list of vendors
  // // Admin login needs a separet design

  // get vendor associated locations
  const { loading: isVendorLocationsLoading } = useQuery(
    GET_VENDOR_LOCATIONS_BY_TYPES,
    {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      variables: {
        vendorId: vendorData?.id,
        locationTypeCodes: ['AsmPoint', 'InspPoint'],
      },
      onCompleted: ({ getVendorLocationsByTypes }) => {
        setVendorLocations(getVendorLocationsByTypes);
      },
      skip: !vendorData?.id,
    },
  );

  const reloadBidLineDocuments = () => {
    getBidLineDocuments({
      variables: { bidLineId: parseInt(receivedBlId, 10) },
    });
  };

  useEffect(() => {
    getBidLineDocuments({
      variables: { bidLineId: parseInt(receivedBlId, 10) },
    });
  }, [receivedBlId]);

  useQuery(GET_GSA_COLORS, {
    fetchPolicy: 'cache-and-network',
    variables: {},
    onCompleted: ({ getGSAColors }) => {
      setGsaColors(getGSAColors);
    },
  });

  useQuery(GET_FUEL_TYPES, {
    fetchPolicy: 'cache-and-network',
    variables: { isForBids: true },
    onCompleted: ({ getFuelTypes }) => {
      setFuelTypes(getFuelTypes);
    },
  });
  useQuery(GET_STATES, {
    fetchPolicy: 'cache-and-network',
    variables: {},
    onCompleted: ({ getStates }) => {
      setStates(
        getStates.filter(
          (st) =>
            st.isoCountryCode2 === 'US' &&
            !['AA', 'AE', 'AP', 'FM', 'GE', 'IT', 'MH', 'NU', 'PW'].includes(
              st.stateCode,
            ),
        ),
      );
    },
  });
  useQuery(GET_COUNTRIES, {
    fetchPolicy: 'cache-and-network',
    variables: {},
    onCompleted: ({ getCountries }) => {
      setCountries(getCountries);
    },
  });

  useEffect(() => {
    setLoading(soliLoading || blLoading || mrLoading);
  }, [soliLoading, blLoading, mrLoading]);

  useEffect(() => {
    const ecs = ecData?.getEquipmentOptions;
    if (!bidLine || !FuelTypes || !ecs) return;
    if (!ecs.length)
      setLoadingError(new Error('Standard Item equipment options not found'));
    else {
      const procData = processEquipmentCodesData(ecs, bidLine, FuelTypes);
      const mapDataFn = ({ id, data }) => ({ id, data });
      const mapTableFn = ({ data, tableData, ...catData }) => ({
        ...catData,
        data: tableData,
      });
      setStandardECs(procData.standard.map(mapDataFn));
      setStandardECTable(procData.standard.map(mapTableFn));
      const optECs = procData.optional.map(mapDataFn);
      setOptionalECs(optECs);
      setOptionalECTable(procData.optional.map(mapTableFn));
      setInvalidConflictECs(getInvalidConflictECs(optECs));
      setEngineData(procData.engines);
      setEngineTable(procData.engines.map(mapEngineDataToTable));
    }
  }, [bidLine, ecData, FuelTypes]);

  // update tab status
  useEffect(() => {
    setTabStatus(0, isBidDetailTabReady(bidDetailData, associatedLocations));
  }, [bidDetailData, associatedLocations]);
  useEffect(() => {
    setTabStatus(1, isEcTabReady(standardECs));
  }, [standardECs]);
  useEffect(() => {
    setTabStatus(2, isEcTabReady(optionalECs));
  }, [optionalECs]);
  useEffect(() => {
    setTabStatus(3, isEngineTabReady(engineData));
  }, [engineData]);
  useEffect(() => {
    setTabStatus(4, isColorsTabReady(colors));
  }, [colors]);

  // providor context
  const contextValue = useMemo(() => ({
    loading,
    loadingError,
    alert,
    setAlert,
    tabIndex,
    setTabIndex,
    tabStatuses,
    setTabStatus,
    solicitation,
    bidLine,

    ...useHighLightedFieldsResults,

    updateBidDetailData,
    bidDetailData,

    gsaColors,
    FuelTypes,
    States,
    Countries,

    standardECs,
    standardECTable,
    setStandardECTable,
    getStandardEC,
    setStandardEC,

    optionalECs,
    optionalECTable,
    setOptionalECTable,
    getOptionalEC,
    setOptionalEC,
    invalidConflictECs,

    engineData,
    setEngineData,
    engineTable,
    setEngineTable,
    setEngine,

    colors,
    setColors,
    documents,
    setDocuments,
    photos,
    setPhotos,
    reloadBidLineDocuments,

    isVendorDataLoading: false,
    vendorData,
    isVendorLocationsLoading,
    vendorLocations,

    setAssociatedLocations,
    associatedLocations,

    // updateLineReadyStatus,
    ...props,
  }));

  return (
    <BidLineDetailsContext.Provider value={contextValue}>
      {children}
    </BidLineDetailsContext.Provider>
  );
};

BidLineDetailsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  blId: PropTypes.number,
  isReviewSubmit: PropTypes.bool,
};

BidLineDetailsProvider.defaultProps = {
  blId: null,
  isReviewSubmit: false,
};

export default BidLineDetailsProvider;

export const useBidLineDetails = () => useContext(BidLineDetailsContext);
