import React from 'react';
import PropTypes from 'prop-types';
import { compact } from 'lodash';
import { Button } from '@gsa/afp-component-library';
import { useBidLineDetails } from '../provider/bid-line-details-provider';
import {
  getOptionalEcList,
  ecTitleLookup,
  hasConflicts,
} from '../provider/helpers';

export const getEditConflictBlock = (
  equipment,
  field,
  getFormFn,
  getSummaryFn,
  onClickSave,
  disabled,
) => {
  return (
    <div className="grid-row bg-primary-lightest padding-2">
      <div className="grid-col-7">
        <div className="display-flex flex-row flex-justify">
          <div>
            <div className="text-bold text-primary text-uppercase">{field}</div>
            <div>Equipment code(s)</div>
          </div>
          <div className="margin-top-1 margin-right-8">
            <Button
              data-testid={`save-${field}-button`}
              label="Save"
              variant="outline"
              onClick={onClickSave}
              disabled={disabled}
            />
          </div>
        </div>
        {getFormFn()}
      </div>

      <div className="grid-col-5">
        <div className="text-bold text-primary text-uppercase">
          {field} summary
        </div>
        <div className="margin-bottom-1">
          {equipment} <span className="text-bold text-uppercase">{field}</span>
        </div>
        {getSummaryFn()}
      </div>
    </div>
  );
};

export const displayCludes = (ecs, ecList) => {
  return (
    <ul className="margin-y-0">
      {ecs.map((ec) => (
        <li key={ec}>{ecTitleLookup(ec, ecList)}</li>
      ))}
    </ul>
  );
};

export const displayRequires = (relations, ecList) => {
  return relations.map((row, i) => (
    <div key={`requires-${row.join('')}`}>
      <ul className="margin-y-0">
        {row.map((ec, j) => (
          <li key={ec}>
            {ecTitleLookup(ec, ecList)}
            {j < row.length - 1 && ', '}
            {j < row.length - 1 && (
              <span className="text-bold font-sans-3xs">AND</span>
            )}
          </li>
        ))}
      </ul>
      {i < relations.length - 1 && (
        <div className="text-bold font-sans-2xs">OR</div>
      )}
    </div>
  ));
};

export const ConflictsDisplayBlock = ({
  row,
  original,
  onActionClick,
  isReadOnly,
}) => {
  const { optionalECs, findHighlight } = useBidLineDetails();
  const ecList = getOptionalEcList(optionalECs);
  const noConflict = !hasConflicts(original);
  const ecRelIds = compact([
    original?.includes?.id,
    original?.excludes?.id,
    original?.requires?.id,
  ]);

  const isConflictsHighlighted = ecRelIds.some((rowId) =>
    findHighlight('optional', 'bid_line_ec_relation', rowId, 'conflicts'),
  );

  const showConflict = (header, field, displayFn) => {
    if (!original[field].value.length) return null;
    return (
      <div className="grid-row margin-bottom-2">
        <div className="grid-col-2 text-bold text-right padding-right-1">
          {header}
        </div>
        <div className="grid-col-10">
          {displayFn(original[field].value, ecList)}
        </div>
      </div>
    );
  };

  return (
    <div
      className={
        isConflictsHighlighted
          ? 'bid-line-highlighted-input-data padding-1'
          : ''
      }
    >
      <div className="grid-row">
        <div className="grid-col-2 text-bold text-primary padding-top-05">
          CONFLICTS
        </div>
        {!isReadOnly && (
          <div className="grid-col-10 padding-left-3">
            <Button
              variant="unstyled"
              label={`${noConflict ? 'Add' : 'Edit'} conflict`}
              leftIcon={{ name: noConflict ? 'add' : 'edit' }}
              onClick={() => onActionClick('conflict', row)}
            />
          </div>
        )}
      </div>
      <div className="margin-top-1 margin-bottom-2">
        {showConflict('Includes', 'includes', displayCludes)}
        {showConflict('Excludes', 'excludes', displayCludes)}
        {showConflict('Requires', 'requires', displayRequires)}
      </div>
    </div>
  );
};

ConflictsDisplayBlock.propTypes = {
  row: PropTypes.shape(PropTypes.objectOf({})),
  original: PropTypes.shape(PropTypes.objectOf({})),
  onActionClick: PropTypes.func,
  isReadOnly: PropTypes.bool,
};

ConflictsDisplayBlock.defaultProps = {
  row: {},
  original: {},
  isReadOnly: false,
  onActionClick: () => {},
};
