import {
  AFPTable,
  Pagination,
  Button,
  AFPTableRowAction,
} from '@gsa/afp-component-library';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import useDidMountEffect from '../../hooks/use-did-mount';
import { useEquipmentDetailCodes } from './equipment-code-detail-provider';
import { SI_LABELS } from '../standard-items/constants';
import EquipmentCodeAssociationFilter from './equipment-code-association-filter-panel';
import PredeterminedInput from '../../widgets/predetermined-input';
import { OPERATIONS, SUBJECTS } from '../../utilities/constants';

const actionList = [
  {
    icon: 'edit',
    label: 'Edit',
    canShowIndicator: 'canEdit',
  },
  {
    icon: 'delete',
    label: 'Remove',
    canShowIndicator: 'canDelete',
  },
];

const AssociatedStandardItemsTable = () => {
  const {
    selectedEquipmentCode: selectedItem,
    getMultipleOptions,
    associationList,
    getEquipmentAssociations,
    MODAL_MODES,
    setData,
    selectedAssociations,
    selectAllSelected,
    clearCheckBox,
  } = useEquipmentDetailCodes();
  const ability = useAppAbility();

  const multipleOptions = [
    {
      model: 'StandardsCodeModel',
      label: 'title',
      value: 'code',
      filter: {
        operator: 'EQ',
        key: 'standardCodeMetadataId',
        value: '8',
      },
      uniqueKey: 'program',
      includeValueInLabel: true,
    },
    {
      model: 'StandardsCodeModel',
      label: 'title',
      value: 'code',
      filter: { operator: 'EQ', key: 'standardCodeMetadataId', value: '14' },
      uniqueKey: 'units',
    },
    {
      model: 'StandardsCodeModel',
      label: 'title',
      value: 'code',
      filter: {
        operator: 'EQ',
        key: 'standardCodeMetadataId',
        value: '7',
      },
      uniqueKey: 'quantityRequired',
    },
    {
      model: 'StandardsCodeModel',
      label: 'title',
      value: 'code',
      filter: {
        operator: 'EQ',
        key: 'standardCodeMetadataId',
        value: '3',
      },
      uniqueKey: 'tags',
    },
    {
      model: 'StandardsCodeModel',
      label: 'title',
      value: 'code',
      limit: 1000,
      filter: {
        operator: 'EQ',
        key: 'standardCodeMetadataId',
        value: '6',
      },
      uniqueKey: 'category',
    },
    {
      model: 'StandardsCodeModel',
      label: 'title',
      value: 'code',
      filter: {
        operator: 'EQ',
        key: 'standardCodeMetadataId',
        value: '2',
      },
      uniqueKey: 'associationType',
    },
    {
      model: 'StandardsCodeModel',
      label: 'title',
      value: 'code',
      filter: {
        operator: 'EQ',
        key: 'standardCodeMetadataId',
        value: '4',
      },
      uniqueKey: 'inputType',
    },
    {
      model: 'StandardsCodeModel',
      label: 'title',
      value: 'code',
      filter: {
        operator: 'EQ',
        key: 'standardCodeMetadataId',
        value: '5',
      },
      uniqueKey: 'criteria',
    },
  ];

  const getActions = (row) => {
    const isSINReadOnly = row?.standardItem?.isStandardItemInOpenSolicitation;
    if (isSINReadOnly) {
      return [];
    }
    return actionList;
  };

  const [filters, setFilters] = useState({});
  const [order, setOrder] = useState('`standardItem.standardItemNumber` ASC');
  const [paginationState, setPaginationState] = useState({
    limit: 10,
    offset: 0,
    currentPage: 1,
  });

  const tableRef = React.createRef();

  useEffect(() => {
    if (!selectedItem?.id) return;
    getMultipleOptions({
      variables: {
        options: multipleOptions,
      },
    });

    setFilters({
      operator: 'AND',
      value: [
        {
          operator: 'EQ',
          value: selectedItem.id,
          key: 'equipmentCodeId',
        },
        {
          operator: 'EQ',
          value: selectedItem.year,
          key: '$standardItem.year$',
        },
      ],
    });
  }, [selectedItem?.id]);

  useEffect(() => {
    if (selectAllSelected) {
      setPaginationState((prev) => ({
        ...prev,
        limit: associationList?.count,
      }));
      const checkbox = document.getElementById('select-row-select-all-header');
      checkbox.checked = true;
    } else {
      setPaginationState((prev) => ({ ...prev, limit: 10 }));
    }
  }, [selectAllSelected]);

  useEffect(() => {
    if (selectAllSelected && associationList?.rows) {
      tableRef?.current?.toggleAllRowsSelected();
    }
  }, [associationList]);

  const getData = () => {
    const { limit, offset } = paginationState;
    getEquipmentAssociations({
      variables: { filters, limit, offset, order },
    });
  };

  useDidMountEffect(() => {
    getData();
  }, [filters, order]);

  useDidMountEffect(() => {
    getData();
    tableRef?.current?.toggleAllRowsSelected(false);
  }, [paginationState]);

  const handleSelectedAction = useCallback((e, row) => {
    setData('SET_EQUIPMENT_ASSOCIATION', row.original);
    setData('SET_FORM_MODAL', true);

    if (e === 'Edit') {
      setData('SET_MODAL_MODE', MODAL_MODES.EDIT_ASSOCIATION);
      setData('SET_SUCCESS_MESSAGE', '');
    }
    if (e === 'Remove') {
      setData('SET_MODAL_MODE', MODAL_MODES.REMOVE_ASSOCIATION);
      setData('SET_SUCCESS_MESSAGE', '');
    }
    return null;
  }, []);

  const InputType = ({
    row: {
      original: { inputType, unit, lowerBound, upperBound, preDefinedValue },
    },
  }) => {
    switch (inputType?.code?.toLowerCase()) {
      case 'c': // Comply yes/no
        return 'Comply yes/no';
      case 'f': // Free text
        return 'Free text';
      case 'p': // Predefined value
        return <PredeterminedInput preDefinedValue={preDefinedValue} />;
      case 'n': {
        // Numerical
        if (lowerBound && unit?.title) return `${lowerBound} ${unit.title}`;
        return '-';
      }
      case 'r': {
        // Numerical range
        if (lowerBound && upperBound && unit?.title)
          return `${lowerBound}-${upperBound} ${unit.title}`;
        return '-';
      }
      default:
        return inputType?.title ?? '-';
    }
  };

  const columns = useMemo(() => {
    const columnList = [
      {
        Header: SI_LABELS.NUMBER,
        accessor: 'standardItem.standardItemNumber',
      },
      {
        Header: 'Type',
        accessor: 'associationType.title',
        Cell: ({ value }) => {
          return value?.length ? value : '-';
        },
      },
      {
        Header: 'Title',
        accessor: 'standardItem.title',
        Cell: ({ value }) => {
          return value?.length ? value : '-';
        },
      },
      {
        Header: 'Standard details',
        accessor: 'associationText',
        Cell: ({ value }) => {
          return value?.length ? value : '-';
        },
      },
      {
        Header: 'Minimums',
        accessor: 'inputType.title',
        Cell: (props) => <InputType {...props} />,
      },
    ];

    if (ability.can(OPERATIONS.Manage, SUBJECTS.EquipmentCodes)) {
      columnList.push({
        Header: 'Actions',
        sortable: false,
        headerClassName: 'cell-center',
        cellClassName: 'cell-center',
        Cell: (props) => {
          // eslint-disable-next-line react/prop-types
          const { row } = props;
          return (
            <AFPTableRowAction
              // eslint-disable-next-line react/prop-types
              actions={getActions(row.original)}
              onSelectAction={(evt) => handleSelectedAction(evt, row)}
              {...props}
            />
          );
        },
      });
    }

    return columnList;
  }, []);

  const handleClear = () => {
    tableRef?.current?.toggleAllRowsSelected(false);
    setData('SET_SELECTALL_SELECTED', false);
    setPaginationState((prev) => ({ ...prev, limit: 10 }));
  };

  useEffect(() => {
    if (clearCheckBox) {
      tableRef?.current?.toggleAllRowsSelected(false);
      setData('SET_CLEAR_CHECKBOX', false);
    }
  }, [clearCheckBox]);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    const offsetvalue = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset: offsetvalue,
      currentPage,
    });
  };

  useDidMountEffect(() => {
    handlePaginationChange(1, paginationState.limit);
    setOrder('`standardItem.standardItemNumber` ASC');
    tableRef.current.toggleSortBy('standardItem.standardItemNumber', false);
    getData();
  }, [filters]);

  return (
    <div className="equipment-association">
      <div className="grid-row grid-gap">
        <div className="desktop:grid-col-2 tablet-lg:grid-col-3" />
        <div className="desktop:grid-col-10 tablet-lg:grid-col-9">
          <div className="grid-row margin-top-4 margin-bottom-6">
            <div className="grid-col padding-left-2">
              {selectedAssociations?.length ? (
                <span>
                  <b>{selectedAssociations.length}</b> Standard Items selected
                  <Button
                    onClick={handleClear}
                    className="usa-button usa-button--unstyled margin-left-1"
                    data-testid="standerd-item-clear-button"
                    label="Clear"
                    leftIcon={{
                      name: 'close',
                      className: 'usa-icon--size-2',
                    }}
                  />
                </span>
              ) : null}
            </div>
            <div className="gird-col">
              {selectedAssociations?.length &&
              ability.can(OPERATIONS.Manage, SUBJECTS.EquipmentCodes) ? (
                <>
                  <Button
                    variant="outline"
                    data-testid="standerd-item-copy-button"
                    onClick={() => {
                      setData('SET_FORM_MODAL', true);
                      setData('SET_MODAL_MODE', MODAL_MODES.COPY);
                    }}
                    label="Copy Standard Items to Eq. Codes"
                    leftIcon={{
                      name: 'content_copy',
                    }}
                  />
                  <Button
                    variant="secondary"
                    data-testid="standerd-item-bulk-delete-button"
                    onClick={() => {
                      setData('SET_FORM_MODAL', true);
                      setData(
                        'SET_MODAL_MODE',
                        MODAL_MODES.CONFIRM_BULK_DELETE,
                      );
                    }}
                    label="Remove Standard Items"
                    leftIcon={{
                      name: 'delete',
                    }}
                  />
                </>
              ) : null}
              {selectedItem?.canEdit &&
                selectedItem?.status === 'Active' &&
                ability.can(OPERATIONS.Manage, SUBJECTS.EquipmentCodes) && (
                  <Button
                    data-testid="standerd-item-add-button"
                    onClick={() => {
                      setData('SET_FORM_MODAL', true);
                      setData('SET_MODAL_MODE', MODAL_MODES.ADD_ASSOCIATION); // Reset
                      setData('SET_SUCCESS_MESSAGE', '');
                    }}
                    label="Add Standard Item"
                    leftIcon={{
                      name: 'add',
                    }}
                  />
                )}
            </div>
          </div>
        </div>
      </div>

      <div className="grid-row grid-gap">
        <div className="desktop:grid-col-3 tablet-lg:grid-col-3 association-filter-wrapper margin-top-7">
          <EquipmentCodeAssociationFilter setFilters={setFilters} />
        </div>
        <div className="desktop:grid-col-9 tablet-lg:grid-col-9 association-table-wrapper margin-top-5">
          <AFPTable
            testId="associations-table"
            ref={tableRef}
            columns={columns}
            data={associationList.rows}
            onSort={setOrder}
            defaultSort={order}
            onRowSelect={({ selectedFlatRows }) => {
              setData('SET_EQUIPMENT_ASSOCIATION_SELECTIONS', selectedFlatRows);
            }}
            selectable={ability.can(OPERATIONS.Manage, SUBJECTS.EquipmentCodes)}
            selectAllHeader="Select to copy"
            selectAllHeaderClicked={() => {
              const checkbox = document.getElementById(
                'select-row-select-all-header',
              );
              setData('SET_SELECTALL_SELECTED', checkbox?.checked);
            }}
          />

          {!selectAllSelected && associationList?.rows ? (
            <div className="padding-y-4">
              <Pagination
                itemsPerPageOptions={[10, 25, 50]}
                onPageChange={handlePaginationChange}
                variant="advanced"
                itemsPerPage={paginationState.limit}
                currentPage={paginationState.currentPage}
                itemsCount={associationList?.count}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AssociatedStandardItemsTable;
