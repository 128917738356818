import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Modal, Button, Alert, Icon } from '@gsa/afp-component-library';
import OverlaySpinner from '../../../../components/overlay-spinner';
import { useBidLineDetails } from '../provider/bid-line-details-provider';
import { SAVE_BID_LINE_EC_RELATION } from '../provider/queries';
import EditIncludesExcludesBlock from './edit-includes-excludes-block';
import EditRequiresBlock from './edit-requires-block';
import {
  EC_CONFLICT_TYPE,
  getOptionalEcList,
  isInactiveOptionType,
} from '../provider/helpers';
import {
  validateConflicts,
  getInvalidConflictECsByEC,
} from './conflicts-helpers';

const ALL_ENABLED = {
  includes: false,
  excludes: false,
  requires: false,
};
const ALL_DISABLED = {
  includes: true,
  excludes: true,
  requires: true,
};

const EditConflictModal = ({ row, onClose }) => {
  const [alert, setAlert] = useState(null);
  const {
    optionalECs,
    getOptionalEC,
    setOptionalEC,
    invalidConflictECs,
    isAdmin,
  } = useBidLineDetails();
  const [invalidConflicts, setInvalidConflicts] = useState(invalidConflictECs);
  const [disabled, setDisabled] = useState(ALL_ENABLED);
  const original = getOptionalEC(row.original);
  const ecList = getOptionalEcList(optionalECs);

  const options = ecList
    .filter(
      ({ equipmentCode, inputOptionType }) =>
        equipmentCode !== original.equipmentCode &&
        !isInactiveOptionType(inputOptionType),
    )
    .sort((a, b) => (a.equipmentCode < b.equipmentCode ? -1 : 1))
    .map(({ equipmentCode, equipment }) => ({
      label: equipment,
      value: equipmentCode,
    }));

  const includesRequiresOptions = options.filter(
    ({ value }) => !original.excludes.value.includes(value),
  );
  const excludesOptions = options.filter(
    ({ value }) =>
      !original.includes.value.includes(value) &&
      original.requires.value.every((ecs) => !ecs.includes(value)),
  );

  const [saveEcRelation, { loading }] = useMutation(SAVE_BID_LINE_EC_RELATION);

  const saveConflict = async (field, value) => {
    const conflict = original[field];
    const ecMapFn = (ec) => ({
      relatedEc: ec,
      relatedEcId: ecList.find((item) => item.equipmentCode === ec).id,
    });

    // prepare api variables
    const input = { relationshipCode: EC_CONFLICT_TYPE[field] };
    if (conflict.id) {
      // existing conflict
      input.id = conflict.id;
    } else {
      // new conflict
      input.bidLineEcId = original.bidLineEcId;
      input.associationId = original.id;
      input.equipmentCode = original.equipmentCode;
    }
    if (field === 'requires') {
      const newValue = value.filter((ecs) => ecs.length > 0);
      input.conflicts = {
        operator: '$or',
        relations: newValue.map((ecs) => ({
          operator: '$and',
          ecs: ecs.map(ecMapFn),
        })),
      };
    } else {
      input.conflicts = {
        operator: '$and',
        ecs: value.map(ecMapFn),
      };
    }

    // call api to save conflicts
    if (isAdmin) return { id: conflict.id || 0 }; // do NOT save for admin
    return saveEcRelation({ variables: { input } })
      .then(({ data }) => ({ id: data.saveBidLineEcRelation }))
      .catch((error) => ({ error }));
  };

  const onUpdate = (field, value) => {
    setAlert(null);
    const val = field === 'requires' ? value.filter((v) => v.length) : value;
    const validateResult = validateConflicts(
      original.equipmentCode,
      field,
      val,
      optionalECs,
    );
    setInvalidConflicts(validateResult);
    if (JSON.stringify(original[field].value) === JSON.stringify(val)) {
      setDisabled(ALL_ENABLED);
    } else {
      setDisabled({ ...ALL_DISABLED, [field]: false });
    }
  };

  const onSave = async (field, value) => {
    // call API to save conflict
    const { id, error } = await saveConflict(field, value);
    if (id) {
      // success - show success message; update provider data
      setAlert({
        type: 'success',
        message: `${field.toUpperCase()} has been saved successfully`,
      });
      setOptionalEC(
        row.original,
        {
          data: {
            [field]: {
              id,
              value: value.filter((item) => item.length > 0),
            },
          },
        },
        true, // isConflict flag true to revalidate conflicts
      );
      setDisabled(ALL_ENABLED);
    } else {
      // error - show error message
      setAlert({
        type: 'error',
        message: `Unable to save ${field.toUpperCase()}: ${error.message}`,
      });
    }
  };

  const getAlert = () => {
    const invalidECs = getInvalidConflictECsByEC(
      invalidConflicts,
      original.equipmentCode,
    );
    if (invalidECs.length === 0) return null;
    return (
      <Alert
        type="warning"
        slim
        focused
        className="margin-top-0 margin-bottom-2"
      >
        <div>
          <span className="text-bold">{original.equipment}</span> is invloved in
          the following contradicting conflicts:
        </div>
        {invalidECs.map(
          ({ rootEc, contradictEc, includeTrace, excludeTrace }) => (
            <div key={rootEc}>
              <div>
                Equipment code <span className="text-bold">{rootEc}</span>
              </div>
              <li className="margin-left-2">
                <u>
                  {rootEc} must have {contradictEc}
                </u>
                : {includeTrace}
              </li>
              <li className="margin-left-2">
                <u>
                  {rootEc} cannot have {contradictEc}
                </u>
                : {excludeTrace}
              </li>
            </div>
          ),
        )}
      </Alert>
    );
  };

  return (
    <div className="afp-modal-wrapper edit-conflicts-modal">
      <div className="afp-modal-overlay">
        <Modal
          id="edit-conflict-modal"
          variant="extra-large"
          title={<h2>Manage conflicts</h2>}
          onClose={onClose}
          actions={
            <div>
              <Button
                data-testid="close-edit-conflict-modal-btn"
                variant="primary"
                onClick={onClose}
                label="Close"
              />
            </div>
          }
        >
          {loading && <OverlaySpinner />}
          <div className="margin-y-2 text-bold">{original.equipment}</div>
          {alert && (
            <Alert
              type={alert.type}
              slim
              focused
              className="margin-top-0 margin-bottom-2"
              showClose
              onClose={() => setAlert(null)}
            >
              {alert.message}
            </Alert>
          )}
          {getAlert()}
          <div className="margin-bottom-2">
            <EditIncludesExcludesBlock
              original={original}
              onUpdate={onUpdate}
              onSave={onSave}
              options={includesRequiresOptions}
              field="includes"
              disabled={disabled.includes}
            />
          </div>
          <div className="margin-bottom-2">
            <EditIncludesExcludesBlock
              original={original}
              onUpdate={onUpdate}
              onSave={onSave}
              options={excludesOptions}
              field="excludes"
              disabled={disabled.excludes}
            />
          </div>
          <div>
            <EditRequiresBlock
              original={original}
              onUpdate={onUpdate}
              onSave={onSave}
              options={includesRequiresOptions}
              field="requires"
              disabled={disabled.requires}
            />
          </div>
          <div style={{ marginTop: 48, marginBottom: -48 }}>
            <Icon iconName="warning" className="usa-icon--size-3 margin-x-1" />
            Please use the Save buttons to save your changes. Any unsaved
            changes will be lost when you close the modal.
          </div>
        </Modal>
      </div>
    </div>
  );
};

EditConflictModal.propTypes = {
  row: PropTypes.shape(PropTypes.objectOf({})).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EditConflictModal;
