import { emDashUnicode } from '../../../utilities/constants';

export const CONTRACT_TYPE_MAP = {
  SOP: 'SOP',
  NON_SOP: 'Non-SOP',
  NA: 'NA',
};

export const CONTRACT_SOURCE = {
  AFP: 'AFP',
  LEGACY: 'legacy',
  NA: 'NA',
};

export const getContractPeriod = (solPeriod) => {
  return solPeriod?.periodType === 'M'
    ? 2 // midcycle
    : 1; // open or reopen
};

export const isContractSOP = (purchaseTypeCode) => {
  return purchaseTypeCode === 'S' || purchaseTypeCode === 'Y';
};

export const getContractType = (purchaseTypeCode) => {
  if (purchaseTypeCode === 'S' || purchaseTypeCode === 'Y') {
    return CONTRACT_TYPE_MAP.SOP;
  }
  if (
    purchaseTypeCode === 'M' ||
    purchaseTypeCode === 'E' ||
    purchaseTypeCode === 'O' ||
    purchaseTypeCode === 'N'
  ) {
    return CONTRACT_TYPE_MAP.NON_SOP;
  }
  return CONTRACT_TYPE_MAP.NA;
};

const defaultFleetContractYear = '2021';

// if fleetAwarded is 0 or null, it is a legacy contract
export const isLegacyContract = (fleetAwarded) => {
  return fleetAwarded === null || fleetAwarded === 0;
};

export const getContractYear = (contract) => {
  const contractType = getContractType(
    contract?.solicitation?.purchaseTypeCode,
  );

  switch (contractType) {
    case CONTRACT_TYPE_MAP.SOP:
      // if either contractYear or renewalYear is less than 2021, return defaultContractYear
      if (contract?.renewalYear) {
        return contract.renewalYear > defaultFleetContractYear
          ? contract.renewalYear
          : defaultFleetContractYear;
      }

      if (contract?.contractYear) {
        return contract.contractYear > defaultFleetContractYear
          ? contract.contractYear
          : defaultFleetContractYear;
      }

      return emDashUnicode;
    case CONTRACT_TYPE_MAP.NON_SOP:
      return contract?.solicitation?.contractYear || emDashUnicode;
    default:
      return emDashUnicode;
  }
};
