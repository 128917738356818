/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import {
  ErrorMessage,
  MultiSelect,
  SelectDropdown,
  TextInput,
  RichTextEditor,
} from '@gsa/afp-component-library';
import { isEmpty } from 'lodash';
import EquipmentCode from './equipment-code-typeahead';
import {
  getRules,
  renderCodeError,
  renderSequenceError,
  renderTitleError,
  validate,
} from '../../utilities/validations/error-rules';
import InteractiveCheck from '../InteractiveCheck/InteractiveCheck';
import { emDashUnicode } from '../../utilities/constants';

const EquipmentCodeForm = ({
  formId,
  handleSave,
  isAddForm,
  yearOptions,
  categoryOptions,
  quantityRequiredOptions,
  unitOptions,
  tagOptions,
  programs,
  isReadOnly,
  isPartialEditable,
}) => {
  const {
    register,
    watch,
    errors,
    control,
    setValue,
    handleSubmit,
    clearErrors,
  } = useFormContext();
  const watchContent = watch('content');
  const watchEqYear = !isAddForm ? watch('year') : null;

  const onSubmit = (data) => {
    handleSave({ ...data, content: watchContent });
  };

  const statuses = [
    { value: '', label: 'Select' },
    { value: 'Active', label: 'Active' },
    { value: 'Inactive', label: 'Inactive' },
  ];

  const equipmentCodeCreationYearOptions = yearOptions?.filter((year) => {
    return year?.value > new Date().getFullYear().toString();
  });

  const nextYear = new Date().getFullYear() + 1;
  const currentYear = new Date().getFullYear();
  const isEqInCurrentYear = watchEqYear === currentYear;

  // code
  const isDirty = !isEmpty(watch('code')) || errors?.code?.message;
  const rules = getRules(watch('code'), 'EquipmentCode');
  const hasCodeError = errors?.code?.message;

  // Catalog Title
  const isTitleDirty = !isEmpty(watch('title')) || errors?.title?.message;
  const titleRules = getRules(watch('title'), 'Title');
  const isTitleValid = validate(titleRules);
  const hasTittleError = errors?.title?.message;
  const canShowTitleError = !isTitleValid && isTitleDirty && hasTittleError;

  // Sequence
  const isSequenceDirty =
    !isEmpty(watch('sequence')) || errors?.sequence?.message;
  const Sequencerules = getRules(watch('sequence'), 'Sequence');
  const isSequenceValid = validate(Sequencerules);
  const hasSequenceError = !isSequenceValid && isSequenceDirty;

  // if form is Edit and isReadOnly is true then disable the inputs
  const disabled = !isAddForm && isReadOnly;
  // Edit form and partial editable
  const partialEditable = !isAddForm && isPartialEditable;

  // tags that can't be edited in the current year
  const noneEditableTags = ['MPG'];

  const eQTagOptions = tagOptions.map((tag) => ({
    ...tag,
    disabled: noneEditableTags.includes(tag.value) && partialEditable,
  }));

  // Renders the red red asterisk next the h2.
  const renderRequiredComponent = (value) => {
    return (
      <>
        {/* eslint-disable-next-line */}
        <h2 className="font-sans-xs" tabIndex="0" aria-label={value}>
          {value} <span className="usa-hint usa-hint--required">*</span>
        </h2>
      </>
    );
  };

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      {/* Program */}
      <div className="grid-row padding-bottom-2">
        <div className="grid-col-10">
          <>
            {renderRequiredComponent('Program')}
            <Controller
              data-testid="eq_edit_program"
              name="program"
              control={control}
              defaultValue=""
              render={({ onChange, value, ref }) => (
                <SelectDropdown
                  data-testid="eq_edit_program_sel"
                  aria-label="program"
                  aria-required="true"
                  onChange={(ev) => {
                    onChange(ev.target.value);
                  }}
                  options={
                    programs
                      ? [{ label: '- Select -', value: '' }, ...programs]
                      : []
                  }
                  inputRef={ref}
                  value={value}
                  disabled={isReadOnly || isEqInCurrentYear}
                />
              )}
            />
            {errors?.program?.message && (
              <ErrorMessage>{errors.program.message}</ErrorMessage>
            )}
          </>
        </div>
      </div>
      {/* Code */}
      <div
        className={`grid-row padding-bottom-2 ${
          hasCodeError ? 'padding-left-2' : null
        }`}
      >
        <div className="grid-col-10">
          {isAddForm && (
            <>
              {renderRequiredComponent('Code')}
              <Controller
                data-testid="code"
                name="code"
                defaultValue=""
                control={control}
                render={({ onChange, value, ref }) => (
                  <TextInput
                    data-testid="code"
                    aria-label="code"
                    aria-required="true"
                    name="code"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    type="text"
                    inputRef={ref}
                    errorMessage={renderCodeError(
                      hasCodeError,
                      'Make sure the code meets the requirements listed below.',
                    )}
                  />
                )}
              />
            </>
          )}
          {!isAddForm && (
            <>
              {/* eslint-disable-next-line */}
              <h2 className="font-sans-xs" tabIndex="0" aria-label="Code">
                Code
              </h2>
              <span className=".text-normal">{watch('code')}</span>
            </>
          )}
          {hasCodeError && (
            <InteractiveCheck checks={rules} isDirty={isDirty} />
          )}
        </div>
      </div>

      {/* Title */}
      <div
        className={`grid-row padding-bottom-2 ${
          hasTittleError ? 'padding-left-2' : null
        }`}
      >
        <div className="grid-col-10">
          {renderRequiredComponent('Title')}
          <TextInput
            data-testid="eq_edit_title"
            name="title"
            aria-label="title"
            aria-required="true"
            type="text"
            inputRef={register}
            errorMessage={renderTitleError(canShowTitleError)}
          />
          {hasTittleError && (
            <InteractiveCheck checks={titleRules} isDirty={isTitleDirty} />
          )}
        </div>
      </div>

      <div className="grid-row padding-bottom-2">
        <div className="grid-col-10">
          {isAddForm ? (
            <>
              {renderRequiredComponent('Contract year')}
              <Controller
                data-testid="eq_startYear"
                name="year"
                control={control}
                defaultValue={setValue('year', nextYear.toString())}
                render={({ onChange, value, ref }) => (
                  <SelectDropdown
                    data-testid="eq_startYear_sel"
                    aria-label="Contract year"
                    aria-required="true"
                    onChange={(ev) => {
                      onChange(ev.target.value);
                    }}
                    value={value}
                    options={[...equipmentCodeCreationYearOptions]}
                    inputRef={ref}
                  />
                )}
              />
              {errors?.year?.message && (
                <ErrorMessage>{errors.year.message}</ErrorMessage>
              )}
            </>
          ) : (
            <>
              <h2 className="font-sans-xs">Contract year</h2>
              <span className=".text-normal">{watch('year')}</span>
            </>
          )}
        </div>
      </div>

      {/* Status */}
      <div className="grid-row padding-bottom-2">
        <div className="grid-col-10">
          {renderRequiredComponent('Status')}
          <Controller
            data-testid="eq_edit_status"
            name="status"
            control={control}
            defaultValue="Active"
            render={({ onChange, value }) => (
              <SelectDropdown
                data-testid="eq_edit_status_sel"
                aria-label="status"
                aria-required="true"
                onChange={(ev) => {
                  onChange(ev.target.value);
                }}
                value={value}
                options={statuses}
                disabled={disabled || isEqInCurrentYear}
              />
            )}
          />
          {errors?.status?.message && (
            <ErrorMessage>{errors.status.message}</ErrorMessage>
          )}
        </div>
      </div>

      {/* Category */}
      <div className="grid-row padding-bottom-2">
        <div className="grid-col-10">
          {renderRequiredComponent('Category Code')}
          <Controller
            data-testid="eq_category"
            name="category"
            control={control}
            defaultValue=""
            render={({ onChange, value, ref }) => (
              <SelectDropdown
                data-testid="eq_category_sel"
                aria-label="category"
                aria-required="true"
                onChange={(ev) => {
                  onChange(ev.target.value);
                }}
                value={value}
                inputRef={ref}
                options={[
                  { label: '- Select -', value: '' },
                  ...categoryOptions,
                ]}
                disabled={disabled}
              />
            )}
          />
          {errors?.category?.message && (
            <ErrorMessage>{errors.category.message}</ErrorMessage>
          )}
        </div>
      </div>

      {/* Sequence */}
      <div
        className={`grid-row padding-bottom-2 ${
          hasSequenceError ? 'padding-left-2' : null
        }`}
      >
        <div className="grid-col-10">
          {renderRequiredComponent('Sequence')}
          <Controller
            data-testid="sequence"
            name="sequence"
            defaultValue=""
            control={control}
            render={({ onChange, value, ref }) => (
              <TextInput
                data-testid="eq_edit_sequence"
                aria-label="sequence"
                aria-required="true"
                name="sequence"
                type="text"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                errorMessage={renderSequenceError(
                  hasSequenceError,
                  'Make sure the Sequence meets the requirements listed below.',
                )}
                inputRef={ref}
              />
            )}
          />
          {hasSequenceError && (
            <InteractiveCheck
              checks={Sequencerules}
              isDirty={isSequenceDirty}
            />
          )}
        </div>
      </div>

      {/* Valid Quanity */}
      <div className="grid-row padding-bottom-2">
        <div className="grid-col-10">
          {renderRequiredComponent('Valid quantity')}
          <Controller
            data-testid="eq_edit_quantityRequiredCode"
            name="quantityRequired"
            control={control}
            render={({ onChange, value }) => (
              <SelectDropdown
                data-testid="eq_edit_quantityRequiredCode_sel"
                aria-label="Valid quantity required"
                onChange={(ev) => {
                  onChange(ev.target.value);
                }}
                value={value}
                options={quantityRequiredOptions}
                disabled={disabled || isEqInCurrentYear}
              />
            )}
          />
          {errors?.quantityRequired?.message && (
            <ErrorMessage>{errors.quantityRequired.message}</ErrorMessage>
          )}
        </div>
      </div>

      {/* Unit */}
      <div className="grid-row padding-bottom-2">
        <div className="grid-col-10">
          <h2 className="font-sans-xs">Unit</h2>
          <Controller
            data-testid="eq_edit_unit"
            name="unit"
            control={control}
            render={({ onChange, value }) => (
              <SelectDropdown
                data-testid="eq_edit_unit_sel"
                aria-label="unit"
                onChange={(ev) => {
                  onChange(ev.target.value);
                }}
                value={value}
                options={unitOptions}
                disabled={disabled || isEqInCurrentYear}
              />
            )}
          />
          {errors?.unitCode?.message && (
            <ErrorMessage>{errors.unitCode.message}</ErrorMessage>
          )}
        </div>
      </div>

      <div className="grid-row padding-bottom-2">
        <div className="grid-col-10">
          <h2 className="font-sans-xs">Tags</h2>
          {disabled ? (
            <span className=".text-normal">
              {watch('tags')
                ?.map((tag) => tag)
                .join(', ') || emDashUnicode}
            </span>
          ) : (
            <>
              <Controller
                id="eq_form_tags"
                name="tags"
                control={control}
                render={({ onChange, value = [] }) => {
                  return (
                    <MultiSelect
                      data-testid="si_form_tags_sel"
                      key="eq_form_tags_sel"
                      name="tags"
                      options={eQTagOptions}
                      value={value}
                      onChange={(val, checked) => {
                        let changedValue;
                        if (checked) {
                          changedValue = [...value, val];
                        } else {
                          changedValue = value.filter((el) => el !== val);
                        }
                        onChange(changedValue);
                      }}
                      selectedValues={value}
                    />
                  );
                }}
              />
              {errors?.tags?.message && (
                <ErrorMessage>{errors.tags.message}</ErrorMessage>
              )}
            </>
          )}
        </div>
      </div>

      {/* Description */}
      <div className="grid-row padding-bottom-2">
        <div className="grid-col-auto">
          <h2 className="font-sans-xs"> Description </h2>
        </div>

        <>
          <Controller
            data-testid="eq_edit_description"
            name="content"
            control={control}
            render={({ onChange, value }) => (
              <RichTextEditor
                data-testid="eq_edit_description"
                label="Description"
                aria-label="description"
                onChange={(val) => {
                  onChange(val);
                }}
                content={value}
              />
            )}
          />
          {errors?.content?.message && (
            <ErrorMessage>{errors.content.message}</ErrorMessage>
          )}
        </>
      </div>

      {isAddForm && (
        <div className="grid-row padding-bottom-1">
          <div className="grid-col-4">
            <h2
              className="font-sans-xs text-bold text-primary"
              style={{
                fontSize: '16px',
                letterSpacing: '1px',
                textTransform: 'uppercase',
              }}
            >
              Copy Standard Items From
            </h2>
          </div>
        </div>
      )}

      {isAddForm && (
        <div className="grid-row padding-bottom-2">
          <div className="grid-col-6">
            <h2 className="font-sans-xs">Copy from Contract year</h2>
            <Controller
              data-testid="eq_copyFromYear"
              name="copyFromYear"
              control={control}
              defaultValue=""
              render={({ onChange, value }) => (
                <SelectDropdown
                  data-testid="eq_copyFromYear_sel"
                  aria-label="Copy from Contract year"
                  onChange={(ev) => {
                    onChange(ev.target.value);
                  }}
                  value={value}
                  options={[{ label: 'Select', value: '' }, ...yearOptions]}
                />
              )}
            />
            {errors?.copyFromYear?.message && (
              <ErrorMessage>{errors.copyFromYear.message}</ErrorMessage>
            )}
          </div>
        </div>
      )}

      <EquipmentCode
        isNew={isAddForm}
        control={control}
        errors={errors}
        setValue={setValue}
        register={register}
        watch={watch}
        year={watch('copyFromYear')}
        nameValue="copyFromEquipmentCodeId"
        clearErrors={clearErrors}
      />
    </form>
  );
};

EquipmentCodeForm.defaultProps = {
  isAddForm: false,
  isReadOnly: false,
  isPartialEditable: false,
  handleSave: () => null,
};
EquipmentCodeForm.propTypes = {
  formId: PropTypes.string.isRequired,
  handleSave: PropTypes.func,
  isAddForm: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isPartialEditable: PropTypes.bool,
};

export default EquipmentCodeForm;
