import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from '@gsa/afp-component-library';
import { Can } from '@gsa/afp-shared-ui-utils';
import DetailTable from '../../components/DetailTable';
import { useStandardItem } from './standard-item-provider';
import { OPERATIONS, SUBJECTS } from '../../utilities/constants';

const StandardItemInfo = () => {
  const { standardItem, setData, MODAL_MODES, showFormModal } =
    useStandardItem();
  const [standardItemData, setStandardItemData] = useState([]);
  const history = useHistory();

  const mapStatusToBadgeColor = (status) => {
    const itemStatus = status?.toLowerCase();
    if (!itemStatus) return '';
    if (itemStatus === 'active') return 'Ready-Gray';
    if (itemStatus === 'archived') return 'Inactive-Gray';
    return 'Inactive-Gray';
  };

  useEffect(() => {
    if (typeof history.location.state === 'string' && history.action === 'PUSH')
      setData('SET_SUCCESS_MESSAGE', history.location.state);
  }, [history.location.state]);

  const handleData = () => {
    const tableData = [
      {
        header: 'Standard Item',
        value: standardItem?.standardItemNumber,
        type: 'text',
      },
      {
        header: 'Contract year',
        value: standardItem?.year,
        type: 'text',
      },
      {
        header: 'Vehicle type',
        value: standardItem?.vehicleTypeCode?.title,
        type: 'text',
      },
      {
        header: 'Status',
        value: standardItem?.status,
        variant: mapStatusToBadgeColor(standardItem?.status),
        type: 'status',
      },
      {
        header: 'Title',
        value: standardItem?.title,
        type: 'text',
      },
      {
        header: 'Federal Standard Code',
        value: standardItem?.fedStandard?.code,
        type: 'text',
      },
      {
        header: 'FAST Code',
        value: standardItem?.fast,
        type: 'text',
      },
      {
        header: 'Tags',
        value: standardItem?.tags?.value,
        type: 'tags',
      },
      {
        header: 'Description',
        value: standardItem?.content,
        type: 'truncate',
        className: 'text-left',
      },
    ];
    setStandardItemData(tableData);
  };

  useEffect(() => {
    if (standardItem) handleData();
  }, [standardItem]);

  return (
    <>
      <div className="grid-row">
        <div className="desktop:grid-col-7 ">
          <div className="detail-table-links">
            <div>
              <button
                type="button"
                className="usa-button usa-button--unstyled"
                onClick={() => {
                  history.push('/catalog/standard-items');
                }}
              >
                <span className="dropdown-content-icon">
                  <Icon iconName="arrow_back" className="usa-icon--size-2" />
                </span>
                Back to Standard Items
              </button>
            </div>
            <Can I={OPERATIONS.Manage} a={SUBJECTS.StandardItem}>
              <div>
                {(standardItem?.canEdit || standardItem?.canPartialEdit) && (
                  <button
                    type="button"
                    className="usa-button usa-button--unstyled"
                    onClick={() => {
                      setData('SET_FORM_MODAL', true);
                      setData('SET_MODAL_MODE', MODAL_MODES.EDIT_STANDARD_ITEM);
                      setData('SET_SUCCESS_MESSAGE', '');
                    }}
                  >
                    <span className="dropdown-content-icon">
                      <Icon iconName="edit" className="usa-icon--size-2" />
                    </span>
                    Edit
                  </button>
                )}
              </div>
            </Can>
          </div>
          <div className="bg-base-lightest padding-205 margin-top-105">
            <DetailTable
              data={standardItemData}
              showFormModal={showFormModal}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default StandardItemInfo;
