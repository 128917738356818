import { pick } from 'lodash';
import React from 'react';
import { useEquipmentDetailCodes } from './equipment-code-detail-provider';
import EquipmentCodeForm from '../../components/forms/equipment-code-form';
import EquipmentCodeFormContext from '../../components/forms/equipment-code-form-context';

const EquipmentCodesEdit = () => {
  const {
    selectedEquipmentCode,
    updateEquipmentCode,
    multipleOptions,
    groupMultipleOptions,
    setData,
  } = useEquipmentDetailCodes();

  const isEquipCodeInOpenSoli =
    selectedEquipmentCode?.isEquipmentCodeInOpenSolicitation;

  const isEqPartiallyEditable = selectedEquipmentCode?.canPartialEdit;

  const onSubmit = (data) => {
    const tags = { value: [] };
    if (data?.tags) {
      tags.value = data.tags;
    }
    const filteredData = pick(data, [
      'category',
      'code',
      'content',
      'program',
      'quantityRequired',
      'sequence',
      'status',
      'title',
      'unit',
    ]);

    const equipmentCode = {
      ...filteredData,
      tags,
      contentId: filteredData?.content?.id,
      year: Number(data?.year),
    };

    setData('SET_UPDATING_EQUIPMENT_CODE', equipmentCode);
    updateEquipmentCode({
      variables: {
        id: Number(selectedEquipmentCode.id),
        equipmentcode: equipmentCode,
      },
    });
  };
  const categoryOptions = multipleOptions
    ?.filter(
      (o) => o?.type === 'standardsCodeModel' && o?.uniqueKey === 'category',
    )
    .map((o) => ({ ...o, label: `${o.value} - ${o.label}` }));

  const isEqualOrGreaterThanCurrentYear = (year) => {
    const currentYear = multipleOptions.find((o) => o.currentYear === true);
    return year >= currentYear.value;
  };
  const isYearOption = (o) => o?.type === 'year' && o?.uniqueKey === 'year';

  const yearOptions = multipleOptions
    ?.filter((o) => isYearOption(o) && isEqualOrGreaterThanCurrentYear(o.value))
    .reverse();

  const tagOptions = multipleOptions
    ?.filter((o) => o?.type === 'standardsCodeModel' && o?.uniqueKey === 'tags')
    .map((o) => ({ ...o, label: `${o.value} - ${o.label}` }));

  const unitOptions = [
    { label: '- Select -', value: '' },
    ...multipleOptions
      ?.filter(
        (o) => o?.type === 'standardsCodeModel' && o?.uniqueKey === 'units',
      )
      .map((o) => ({ ...o, label: `${o.value} - ${o.label}` })),
  ];

  const quantityRequiredOptions = [
    { label: 'Select', value: '' },
    ...multipleOptions
      ?.filter(
        (o) =>
          o?.type === 'standardsCodeModel' &&
          o?.uniqueKey === 'quantityRequired',
      )
      .map((o) => ({ ...o, label: `${o.value} - ${o.label}` })),
  ];

  return (
    <>
      <EquipmentCodeFormContext equipmentCode={selectedEquipmentCode}>
        <EquipmentCodeForm
          yearOptions={yearOptions}
          categoryOptions={categoryOptions}
          quantityRequiredOptions={quantityRequiredOptions}
          unitOptions={unitOptions}
          tagOptions={tagOptions}
          formId="equipment-code-edit"
          handleSave={(d) => {
            onSubmit(d);
          }}
          isAddForm={false}
          isReadOnly={isEquipCodeInOpenSoli}
          programs={groupMultipleOptions?.program}
          isPartialEditable={isEqPartiallyEditable}
        />
      </EquipmentCodeFormContext>
    </>
  );
};

export default EquipmentCodesEdit;
