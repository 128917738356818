import React from 'react';
import PropTypes from 'prop-types';
import {
  EmptyState,
  Accordion,
  CounterTag,
  MultiSelect,
  Button,
} from '@gsa/afp-component-library';

const AudienceListSelectionBlock = ({
  audience,
  selections,
  onSelect,
  onClear,
}) => {
  const { title, description, audienceType, options } = audience;
  const selectedIds = options
    .filter((opt) => selections.some((sel) => sel.vendorId === opt.value))
    .map((opt) => opt.value);
  const selectionCount = selectedIds.length;
  const emptyStateMsg = `There are no ${title}.`;

  const accordianTitle = (
    <div className="display-flex flex-row flex-justify">
      <div aria-label={title}>{title}</div>
      <CounterTag
        aria-label={`selected ${title} Count`}
        count={selectionCount}
        className="margin-left-2 text-thin font-sans-xs text-center"
      />
    </div>
  );

  const content = (
    <div className="grid-container audienceList">
      <div className="grid-row">
        <div className="tablet:grid-col">
          <span
            className="text-body text-base-dark font-sans-xs"
            aria-label={description}
          >
            {description}
          </span>
          {options.length > 0 ? (
            <>
              {selectionCount > 0 && (
                <div className="padding-y-1">
                  <Button
                    data-testid={`clear-all-btn-${audienceType}`}
                    variant="outline"
                    onClick={() => onClear(selectedIds)}
                    label="Clear all"
                  />
                </div>
              )}
              <MultiSelect
                id={`audience-multiselect-${audienceType}`}
                data-testid={`audience-multiselect-${audienceType}`}
                role="checkbox"
                options={options}
                onChange={(v, checked) => {
                  onSelect(v, checked, audienceType);
                }}
                selectedValues={selectedIds}
              />
            </>
          ) : (
            <EmptyState
              hasBackground={false}
              topText={<span aria-label={emptyStateMsg}>{emptyStateMsg}</span>}
              containerStyles="padding-y-1"
            />
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className="grid-row flex-justify-self-start flex-align-baseline">
      <Accordion
        multiselectable
        bordered
        className="margin-top-2"
        items={[
          {
            title: accordianTitle,
            content,
          },
        ]}
      />
    </div>
  );
};

AudienceListSelectionBlock.propTypes = {
  audience: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    audienceType: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  selections: PropTypes.arrayOf(
    PropTypes.shape({
      vendorId: PropTypes.string.isRequired,
      vendorName: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};

export default AudienceListSelectionBlock;
