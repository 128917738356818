import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from '@gsa/afp-component-library';
import { Can } from '@gsa/afp-shared-ui-utils';
import DetailTable from '../../components/DetailTable';
import { useEquipmentDetailCodes } from './equipment-code-detail-provider';
import { OPERATIONS, SUBJECTS } from '../../utilities/constants';

const EquipmentCodeInfo = () => {
  const { selectedEquipmentCode, setData, MODAL_MODES, showFormModal } =
    useEquipmentDetailCodes();
  const [equipmentCodeData, setEquipmentCodeData] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if (typeof history.location.state === 'string' && history.action === 'PUSH')
      setData('SET_SUCCESS_MESSAGE', history.location.state);
  }, [history.location.state]);

  const handleData = () => {
    const tableData = [
      {
        header: 'Equipment Code',
        value: selectedEquipmentCode?.code,
        type: 'text',
      },
      {
        header: 'Title',
        value: selectedEquipmentCode?.title,
        type: 'text',
      },
      {
        header: 'Program',
        value: selectedEquipmentCode?.program,
        type: 'text',
      },
      {
        header: 'Contract year',
        value: selectedEquipmentCode?.year,
        type: 'text',
      },
      {
        header: 'Status',
        value: selectedEquipmentCode?.status,
        variant:
          selectedEquipmentCode?.status === 'Active'
            ? 'Ready-Gray'
            : 'Inactive-Gray',
        type: 'status',
      },
      {
        header: 'Valid Quantity',
        value: selectedEquipmentCode?.quantityRequiredCode?.title,
        type: 'text',
      },
      {
        header: 'Category Code',
        value: selectedEquipmentCode?.category,
        type: 'text',
      },
      {
        header: 'Unit',
        value: selectedEquipmentCode?.unitCode?.title,
        type: 'text',
      },
      {
        header: 'Tags',
        value: selectedEquipmentCode?.tags?.value,
        type: 'tags',
      },
      {
        header: 'Sequence',
        value: selectedEquipmentCode?.sequence,
        type: 'text',
      },
      {
        header: 'Description',
        value: selectedEquipmentCode?.content,
        type: 'truncate',
        className: 'text-left',
      },
    ];
    setEquipmentCodeData(tableData);
  };

  useEffect(() => {
    if (selectedEquipmentCode) {
      setData('SET_EQUIPMENT_CODE', selectedEquipmentCode);
      handleData();
    }
  }, [selectedEquipmentCode]);

  return (
    <>
      <div className="grid-row">
        <div className="desktop:grid-col-7 ">
          <div className="detail-table-links">
            <div>
              <button
                type="button"
                className="usa-button usa-button--unstyled"
                data-testId="equipment-code-info-back-button"
                onClick={() => {
                  history.push('/catalog/equip-codes');
                }}
              >
                <span className="dropdown-content-icon">
                  <Icon iconName="arrow_back" className="usa-icon--size-2" />
                </span>
                Back to Equipment Codes
              </button>
            </div>
            <Can I={OPERATIONS.Manage} a={SUBJECTS.EquipmentCodes}>
              <div>
                {(selectedEquipmentCode?.canEdit ||
                  selectedEquipmentCode?.canPartialEdit) && (
                  <button
                    type="button"
                    className="usa-button usa-button--unstyled"
                    data-testId="equipment-code-info-edit-button"
                    onClick={() => {
                      setData('SET_FORM_MODAL', true);
                      setData('SET_MODAL_MODE', MODAL_MODES.EDIT);
                    }}
                  >
                    <span className="dropdown-content-icon">
                      <Icon iconName="edit" className="usa-icon--size-2" />
                    </span>
                    Edit
                  </button>
                )}
              </div>
            </Can>
          </div>
          <div className="bg-base-lightest padding-205 margin-top-105">
            <DetailTable
              data={equipmentCodeData}
              showFormModal={showFormModal}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EquipmentCodeInfo;
