/* eslint-disable import/prefer-default-export */
import {
  MAKE_MODEL_APPROVAL_STATUS_BADGE as BADGE,
  CHASSIS_TAG_REGEX,
} from './constants';

export const getMakeModelStatusBadgeConfig = (makeModelApproval) => {
  const status = makeModelApproval?.fleetApprovalStatus;
  if (!status || !BADGE[status]) return null;
  return BADGE[status];
};

export const getMakeModelStatusBadge = (status) => {
  if (!status || !BADGE[status]) return null;
  return BADGE[status];
};

export const hasChassisTag = (tags) => {
  // Check if the standard item has a chassis required tag
  const hasChassis = tags?.value.some((tag) => tag.match(CHASSIS_TAG_REGEX));
  return hasChassis;
};

const determineApprovalStatus = (makeStatus, modelStatus) => {
  // check rejected first, and if  rejected, return rejected
  if (makeStatus === 'Rejected' || modelStatus === 'Rejected') {
    return 'Rejected';
  }

  // if one is pending review, return pending review
  if (makeStatus === 'PendingReview' || modelStatus === 'PendingReview') {
    return 'PendingReview';
  }

  // if both are approved, return approved
  if (makeStatus === 'Approved' && modelStatus === 'Approved') {
    return 'Approved';
  }

  return 'PendingReview';
};

export const getMakeModelApprovalStatus = (item, tags) => {
  if (hasChassisTag(tags) && item?.makeModelApproval?.fleetApprovalStatus) {
    return item?.makeModelApproval?.fleetApprovalStatus;
  }

  return determineApprovalStatus(
    item?.make?.fleetStatus,
    item?.model?.fleetStatus,
  );
};

export const getApprovalStatus = (data) => {
  if (
    hasChassisTag(data?.standardItem?.tags) &&
    data?.makeModelApproval?.fleetApprovalStatus
  ) {
    return data?.makeModelApproval?.fleetApprovalStatus;
  }

  const makeStatus = data?.make
    ? data?.make?.fleetStatus
    : data?.afpMake?.fleetStatus;
  const modelStatus = data?.model
    ? data?.model?.fleetStatus
    : data?.afpModel?.fleetStatus;

  return determineApprovalStatus(makeStatus, modelStatus);
};
