import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Alert } from '@gsa/afp-component-library';
import { useContractLine } from '../provider/contract-line-provider';
import EditIncludesExcludesBlock from './edit-includes-excludes-block';
import EditRequiresBlock from './edit-requires-block';
import {
  getOptionalEcList,
  isInactiveOptionType,
  objEq,
} from '../helpers/ec-helpers';
import {
  validateConflicts,
  getInvalidConflictECsByEC,
} from './conflicts-helpers';

const EditConflictModal = ({ row, onClose }) => {
  const {
    optionalECs,
    getOptionalEC,
    invalidConflictECs,
    setInvalidConflictECs,
  } = useContractLine();
  const original = getOptionalEC(row.original);
  const ecList = getOptionalEcList(optionalECs);

  const [conflicts, setConflicts] = useState({
    includes: original.includes.newValue,
    excludes: original.excludes.newValue,
    requires: original.requires.newValue,
  });
  const [invalidConflicts, setInvalidConflicts] = useState(invalidConflictECs);

  const options = ecList
    .filter(
      ({ equipmentCode, inputOptionType }) =>
        equipmentCode !== original.equipmentCode &&
        !isInactiveOptionType(inputOptionType),
    )
    .sort((a, b) => (a.equipmentCode < b.equipmentCode ? -1 : 1))
    .map(({ equipmentCode, equipment }) => ({
      label: equipment,
      value: equipmentCode,
    }));

  const includesRequiresOptions = options.filter(
    ({ value }) => !conflicts.excludes.includes(value),
  );
  const excludesOptions = options.filter(
    ({ value }) =>
      !conflicts.includes.includes(value) &&
      conflicts.requires.every((ecs) => !ecs.includes(value)),
  );

  const onUpdate = (field, value) => {
    const val = field === 'requires' ? value.filter((v) => v.length) : value;
    if (objEq(conflicts[field], val)) return;

    setConflicts((prev) => ({ ...prev, [field]: val }));
    const validateResult = validateConflicts(
      original.equipmentCode,
      field,
      val,
      optionalECs,
    );
    setInvalidConflicts(validateResult);
  };

  const onCloseModal = () => onClose();

  const onUpdateConflicts = () => {
    const { includes, excludes, requires } = conflicts;
    const update = {};
    if (!objEq(original.includes.newValue, includes))
      update.includes = includes;
    if (!objEq(original.excludes.newValue, excludes))
      update.excludes = excludes;
    if (!objEq(original.requires.newValue, requires))
      update.requires = requires;
    if (Object.keys(update).length) {
      setInvalidConflictECs(invalidConflicts);
      onClose(original, update);
    } else onClose();
  };

  const getAlert = () => {
    const invalidECs = getInvalidConflictECsByEC(
      invalidConflicts,
      original.equipmentCode,
    );
    if (invalidECs.length === 0) return null;
    return (
      <Alert
        type="warning"
        slim
        focused
        className="margin-top-0 margin-bottom-2"
      >
        <div>
          <span className="text-bold">{original.equipment}</span> is invloved in
          the following contradicting conflicts:
        </div>
        {invalidECs.map(
          ({ rootEc, contradictEc, includeTrace, excludeTrace }) => (
            <div key={rootEc}>
              <div>
                Equipment code <span className="text-bold">{rootEc}</span>
              </div>
              <li className="margin-left-2">
                <u>
                  {rootEc} must have {contradictEc}
                </u>
                : {includeTrace}
              </li>
              <li className="margin-left-2">
                <u>
                  {rootEc} cannot have {contradictEc}
                </u>
                : {excludeTrace}
              </li>
            </div>
          ),
        )}
      </Alert>
    );
  };

  return (
    <div className="afp-modal-wrapper edit-conflicts-modal">
      <div className="afp-modal-overlay">
        <Modal
          id="edit-conflict-modal"
          variant="extra-large"
          title={<h2>Manage conflicts</h2>}
          onClose={onCloseModal}
          actions={
            <div>
              <Button
                data-testid="close-edit-conflict-modal-btn"
                variant="unstyled"
                onClick={onCloseModal}
                label="Cancel"
              />
              <Button
                data-testid="close-edit-conflict-modal-btn"
                variant="primary"
                onClick={onUpdateConflicts}
                label="Update"
              />
            </div>
          }
        >
          <div className="margin-y-2 text-bold">{original.equipment}</div>
          {getAlert()}
          <div className="margin-bottom-2">
            <EditIncludesExcludesBlock
              equipment={original.equipment}
              field="includes"
              data={conflicts.includes}
              onUpdate={onUpdate}
              options={includesRequiresOptions}
            />
          </div>
          <div className="margin-bottom-2">
            <EditIncludesExcludesBlock
              equipment={original.equipment}
              field="excludes"
              data={conflicts.excludes}
              onUpdate={onUpdate}
              options={excludesOptions}
            />
          </div>
          <div>
            <EditRequiresBlock
              equipment={original.equipment}
              field="requires"
              data={conflicts.requires}
              onUpdate={onUpdate}
              options={includesRequiresOptions}
            />
          </div>
        </Modal>
      </div>
    </div>
  );
};

EditConflictModal.propTypes = {
  row: PropTypes.shape(PropTypes.objectOf({})).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EditConflictModal;
