import { gql } from '@apollo/client';

// GetContractHeaderById
export const GET_CONTRACT_HEADER_BY_ID = gql`
  query GetContractHeaderById($contractHeaderId: String!) {
    getContractHeaderById(contractHeaderId: $contractHeaderId) {
      contractHeaderId
      contractNumber
      solicitationNumber
      contractStartDate
      contractEndDate
      estimatedValue
      dollarValueMaximumOrder
      contractSpecialistName
      contractSpecialistEmail
      formalContractNumber
      contractingOfficerName
      contractingOfficerEmail
      solicitationId
      solicitationPeriodId
      contractUpiid
      vendorId
      contractYear
      renewalYear
      fleetAwarded
      awardedDate
      awardCancelledDate
      pocName
      pocEmail
      pocPhone
      parentContractNumber
      isActive
      status
      publishedInFleet
      updatedBy
      updatedAt
      datasource
      contractHeaderRenewals {
        contractHeaderId
        contractYear
        renewalYear
      }
      vendor {
        id
        vendorName
        uniqueEntityIdentifier
        fleetVendorNo
        fleetVendorNumber
        fleetStatus
        registrationExpirationDate
      }
      solicitation {
        purchaseTypeCode
        solicitationNumber
        solicitationNumberLegacy
        contractYear
        programs {
          id
          program
          solicitationLines {
            id
            standardItem {
              id
              standardItemNumber
              status
              title
              tags
            }
          }
        }
      }
      solicitationPeriod {
        periodType
      }
    }
  }
`;

export const UPDATE_CONTRACT_HEADER = gql`
  mutation UpdateContractHeader(
    $contractHeaderId: String!
    $contractHeaderUpdateInput: ContractHeaderUpdateInput!
    $notifyUnpublished: Boolean
  ) {
    updateContractHeader(
      contractHeaderId: $contractHeaderId
      contractHeaderUpdateInput: $contractHeaderUpdateInput
      notifyUnpublished: $notifyUnpublished
    ) {
      contractHeaderId
      estimatedValue
      pocName
      pocEmail
      pocPhone
      publishedInFleet
    }
  }
`;

export const UNPUBLISH_CONTRACT_HEADER = gql`
  mutation UnpublishContractHeader(
    $contractHeaderId: String!
    $emailType: String!
  ) {
    unpublishContractHeader(
      contractHeaderId: $contractHeaderId
      emailType: $emailType
    ) {
      contractHeaderId
      publishedInFleet
    }
  }
`;

export const GET_VENDOR_DETAIL_BY_ID = gql`
  query GetVendorDetailById($id: String!) {
    getVendorDetailById(id: $id) {
      id
      vendorName
      uniqueEntityIdentifier
      fleetVendorNo
      fleetVendorNumber
      fleetStatus
      registrationExpirationDate
      vendorOrderTransmittals {
        orderTransmittalCode
        emailAddr
      }
    }
  }
`;
