export const ACTIONS = {
  EDIT: 'Edit',
  DELETE: 'Delete',
  ARCHIVE: 'Archive',
  REACTIVATE: 'Reactivate',
  RENEW: 'Renew',
  REOPEN: 'Reopen',
  MIDCYCLE: 'Mid-cycle',
};

export const MODAL_MODES = {
  SOLICITATION_CONFIRM_DELETE: 'Solicitation_Confirm_Delete',
  REOPEN_PERIOD_CONFIRM_DELETE: 'Reopen_Period_Confirm_Delete',
};

export const COLUMN_HEADERS = {
  uPIID: 'Solicitation uPIID',
  title: 'Solicitation title',
  program: 'Program name',
  process: 'Solicitation process',
  status: 'Status',
  action: 'Actions',
};

export const SOLICITATION_STATUS_BADGE_VARIANT_MAP = {
  Draft: 'Warning-Gray',
  New: 'Info-Gray',
  Open: 'Ready-Gray',
  Closed: 'Urgent-Gray',
  Archived: 'Inactive-Gray',
};
export const SOLICITATION_PERIOD_STATUS = {
  ACTIVE: 'ACTIVE',
  COMPLETED: 'COMPLETED',
  DEACTIVATED: 'DEACTIVATED',
  DEACTIVATED_PAST_DATE: 'DEACTIVATED_PAST_DATE',
  SCHEDULED: 'SCHEDULED',
};
export const SOLICITATION_PERIOD_STATUS_CONTENT_MAP = {
  ACTIVE: 'Active',
  COMPLETED: 'Completed',
  DEACTIVATED: 'Deactivated',
  DEACTIVATED_PAST_DATE: 'Deactivated',
  SCHEDULED: 'Scheduled',
};
export const SOLICITATION_PERIOD_STATUS_BADGE_VARIANT_MAP = {
  [SOLICITATION_PERIOD_STATUS.ACTIVE]: 'Ready-Gray',
  [SOLICITATION_PERIOD_STATUS.COMPLETED]: 'Info-Gray',
  [SOLICITATION_PERIOD_STATUS.DEACTIVATED]: 'Inactive-Gray',
  [SOLICITATION_PERIOD_STATUS.DEACTIVATED_PAST_DATE]: 'Inactive-Gray',
  [SOLICITATION_PERIOD_STATUS.SCHEDULED]: 'Warning-Gray',
};

export const SOLICITATION_PERIOD_STATUS_FIELD_NAME = 'status';
export const SOLICITATION_PERIOD_AUDIENCE_TYPE_FIELD_NAME = 'audienceType';

export const PURCHASE_TYPE_MAP = {
  E: 'Express',
  M: 'MAS',
  N: 'Non-Standard',
  O: 'Other',
  S: 'SOP',
  Y: 'Proprietary',
};

export const STATUS_OPTIONS = [
  { label: '', value: '' },
  { label: 'Draft', value: 'Draft' },
  { label: 'Archived', value: 'Archived' },
  { label: 'New', value: 'New' },
  { label: 'Open', value: 'Open' },
  { label: 'Closed', value: 'Closed' },
];

export const AUDIENCE_MAP = {
  Open: 'Open',
  Other: 'Other',
  Custom: 'Custom',
  Awardees: 'Awardees',
  Bidders: 'Bidders',
};

export const CUSTOM_SOL_PERIOD_AUDIENCE_DESCRIPTION = {
  Open: 'Open season',
  Custom: 'Custom audience',
  Bidders: 'Active bidders',
  Awardees: 'Awardees',
  null: 'Custom audience',
};
export const AUDIENCE_FULL_DESCRIPTION_MAP = {
  O: 'Open season',
};

export const END_DATE_TIME_MSG =
  'Edit the Closing date/Closing time of this Reopen period.';

export const initialOpenPeriodFieldProps = {
  startDateProps: { label: 'Posting date' },
  endDateProps: { label: 'Closing date' },
  startTimeProps: { label: 'Posting time' },
  endTimeProps: { label: 'Closing time' },
};
export const reopenPeriodFieldProps = {
  startDateProps: { label: 'Posting date', required: true },
  endDateProps: { label: 'Closing date', required: true },
  startTimeProps: { label: 'Posting time', required: true },
  endTimeProps: { label: 'Closing time', required: true },
};

export const SOLICITATION_LINES_CSV = {
  header: 'Standard Items; Total Quantity; Fleet Quantity',
};

export const CSV_DELIMITER = {
  comma: ',',
  semiColon: ';',
  tab: ' ',
  space: ' ',
};

export const SOLICITATION_STATUSES = {
  Draft: 'Draft',
  Archived: 'Archived',
  New: 'New',
  Open: 'Open',
  Closed: 'Closed',
};

export const SOLICITATION_TYPES = {
  Standalone: 'Standalone',
  Recompete: 'Recompete',
  Renewal: 'Renewal',
};

export const SOLICITATION_PERIOD_TYPES = {
  Open: 'O',
  Reopen: 'R',
  Midcycle: 'M',
};
