/* eslint-disable react/prop-types */
import React from 'react';
import './make-model-table.scss';
import { Button, StatusBadge } from '@gsa/afp-component-library';
import {
  getMakeModelStatusBadge,
  getApprovalStatus,
  hasChassisTag,
} from '../../../../bid-dashboard/helpers';
import { emDashUnicode } from '../../../../../utilities/constants';

const MakeAndModelTableBody = ({
  makeModelApproval,
  id,
  setMakeAndModeToBeDeleted,
  openModal,
  modelYear,
  make,
  model,
  chassisMake,
  chassisModel,
  standardItem,
}) => {
  const hasChassis = hasChassisTag(standardItem?.tags);

  const preBidMakeModelData = {
    make,
    model,
    chassisMake,
    chassisModel,
    standardItem,
    makeModelApproval,
  };
  const status = getApprovalStatus(preBidMakeModelData);
  const badge = getMakeModelStatusBadge(status);

  const tableWidth = hasChassis ? 'chassisWidth' : 'makeAndModelWidth';

  return (
    <div className={`bodyWrapper ${tableWidth}`}>
      <div
        className="statusWrapper"
        style={{ width: hasChassis ? '230px' : '235px' }}
      >
        {badge ? (
          <StatusBadge variant={badge.variant}>{badge.label}</StatusBadge>
        ) : (
          emDashUnicode
        )}
      </div>
      <div className="bodyTextWrapper">
        <p style={{ paddingLeft: hasChassis ? '8px' : '0' }}>
          {make?.makeName}
        </p>
      </div>
      <div className="bodyTextWrapper">
        <p style={{ paddingLeft: hasChassis ? '8px' : '0' }}>
          {model?.modelName}
        </p>
      </div>
      {hasChassis && (
        <div className="bodyTextWrapper">
          <p style={{ paddingLeft: '8px' }}>{chassisMake?.makeName || ''}</p>
        </div>
      )}
      {hasChassis && (
        <div className="bodyTextWrapper">
          <p style={{ paddingLeft: '8px' }}>{chassisModel?.modelName || ''}</p>
        </div>
      )}
      <div className="bodyTextWrapper">
        <p style={{ paddingLeft: hasChassis ? '8px' : '0' }}>{modelYear}</p>
      </div>
      <div className="deleteBtn">
        <Button
          variant="unstyled"
          className="margin-right-2"
          onClick={() => {
            openModal();
            setMakeAndModeToBeDeleted({
              id,
              modelName: model?.modelName,
              makeName: make?.makeName,
            });
          }}
          data-testid={`delete-${make?.makeName}`}
          label="Delete"
        />
      </div>
    </div>
  );
};

export default MakeAndModelTableBody;
