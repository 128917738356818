import React from 'react';
import PropTypes from 'prop-types';
import { Modal, connectModal, Button } from '@gsa/afp-component-library';

const ImportResultsModal = ({ results, isOpen, onClose }) => {
  const resultsNew = results.filter((l) => l.isNew);
  const resultsExist = results.filter((l) => !l.isNew);
  return (
    <Modal
      id="copy-results-modal"
      variant="large"
      title={
        <div className="title-l">Import awarded contract lines result</div>
      }
      isOpen={isOpen}
      onClose={onClose}
      actions={
        <Button
          data-testid="close-copy-results-modal-btn"
          variant="unstyled"
          onClick={onClose}
          label="Close"
        />
      }
    >
      <div className="minh-mobile maxh-mobile-lg overflow-y-auto">
        {resultsNew.length > 0 && (
          <div>
            <div>Import new contract lines</div>
            <ul>
              {resultsNew.map((f) => (
                <li key={f.label}>
                  <b>{f.label}</b> - {f.errorMessage || 'imported'}
                </li>
              ))}
            </ul>
          </div>
        )}
        {resultsExist.length > 0 && (
          <div>
            <div>Updates to existing contract lines</div>
            <ul>
              {resultsExist.map((f) => (
                <li key={f.label}>
                  <b>{f.label}</b> - {f.errorMessage || 'imported'}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </Modal>
  );
};

ImportResultsModal.propTypes = {
  results: PropTypes.arrayOf(
    PropTypes.shape({
      isNew: PropTypes.bool.isRequired,
      label: PropTypes.string.isRequired,
      errorMessage: PropTypes.string,
    }),
  ).isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

ImportResultsModal.defaultProps = {
  isOpen: false,
};

export default connectModal(ImportResultsModal);
