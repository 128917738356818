import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Spinner, ErrorMessage, TabSet } from '@gsa/afp-component-library';
import { useSetRecoilState, useRecoilState } from 'recoil';
import SIFilterTableFrame from './si-filter-table-frame';
import LineItemsFilterTableFrame from './line-items-filter-table-frame';
import BidCommunicationCenter from '../bids/communication-center/index';
import {
  bidSolicitationAtom,
  bidsDashboardMessageAtom,
  bidLineAtom,
  lineItemsAtom,
  bidDashboardActiveTabAtom,
} from '../bids/atoms/bids-atoms';
import { useBid } from './bid-provider';

const Tabs = () => {
  const {
    bid,
    bidId,
    refetch,

    soliCalled,
    soliLoading,
    soliData,
    soliError,

    bidSICalled,
    bidSILoading,
    bidSIData,
    bidSIError,
    refetchSI,
  } = useBid();

  const setBidSolicitation = useSetRecoilState(bidSolicitationAtom);
  const setToaster = useSetRecoilState(bidsDashboardMessageAtom);
  const setBidLine = useSetRecoilState(bidLineAtom);
  const [tabIndex, setTabIndex] = useRecoilState(bidDashboardActiveTabAtom);
  const [processedBidData, setProcessedBidData] = useState([]);

  const setSiData = useSetRecoilState(lineItemsAtom);
  useEffect(() => {
    if (bidSIData?.getBidStandardItems) {
      setSiData(bidSIData.getBidStandardItems);
    }
  }, [bidSIData?.getBidStandardItems]);

  const processSolicitationStandardItems = (unprocessedSoliSinData) => {
    const tempObject = {};

    // Iterate over each object in the newObject array
    unprocessedSoliSinData.forEach((obj) => {
      const makeModel = {
        bidLineId: obj.id,
        make: obj.afpMake,
        model: obj.afpModel,
        chassisMake: obj.chassisMake,
        chassisModel: obj.chassisModel,
        makeCode: obj.makeCode,
        modelCode: obj.modelCode,
        modelYear: obj.modelYear,
        chassisMakeCode: obj.chassisMakeCode,
        chassisModelCode: obj.chassisModelCode,
        scheduleLine: obj.scheduleLine,
        makeModelApproval: obj.makeModelApproval,
        standardItem: obj?.standardItem,
      };
      // If the SI  id is already in the tempObject, merge the make property
      if (obj.standardItem.id in tempObject) {
        tempObject[obj.standardItem.id].afpMake.push(makeModel);
      }
      // Otherwise, add the object to the tempObject
      else {
        tempObject[obj.standardItem.id] = {
          id: obj.standardItem.id,
          vendorId: obj.bid.vendorId,
          solicitationLineId: obj.solicitationLine.id,
          bidId: obj.bidId,
          scheduleLine: obj.scheduleLine,
          title: obj.standardItem.title,
          standardItemId: obj.standardItem.id,
          standardItemNumber: obj.standardItem.standardItemNumber,
          tags: obj.standardItem.tags,
          programCode:
            obj.solicitationLine.solicitationProgram.programInfo.code,
          programTitle:
            obj.solicitationLine.solicitationProgram.programInfo.title,
          vehicleGroup: obj.standardItem.vehicleGroup.title,
          vehicleType: obj.standardItem.vehicleTypeCode.title,
          afpMake: [makeModel],
        };
      }
    });

    // Add the merged objects from the tempObject to the standardItemWithMakesAndModelsCount
    const values = Object.values(tempObject);
    const standardItemWithMakesAndModels = Array.from(values);

    return standardItemWithMakesAndModels;
  };

  useEffect(() => {
    if (soliData) {
      setBidSolicitation(soliData.getSolicitationById);
    }
  }, [soliData]);

  useEffect(() => {
    if (bidSIData?.getBidStandardItems) {
      const processed = processSolicitationStandardItems(
        bidSIData.getBidStandardItems,
      );
      setProcessedBidData(processed);
      setBidLine(processed);
    }
  }, [bidSIData?.getBidStandardItems]);

  const solicitation = soliData?.getSolicitationById;

  const isBidClosed = useCallback(() => {
    const period = bid?.solicitationPeriod;
    return (
      period?.closeInDays?.Days === 0 &&
      period?.closeInDays?.Hours === 0 &&
      period?.closeInDays?.Minutes === 0
    );
  }, [bid]);

  const tabs = useMemo(() => {
    return [
      {
        heading: 'Standard Items',
        tabSelectedWhenOpen: tabIndex < 1 || tabIndex > 2,
        content: (
          <section className="margin-top-6">
            <SIFilterTableFrame
              upRightProps={{
                solicitation,
                isBidClosed: isBidClosed(),
              }}
              filterToggle
              lowLeftProps={{
                filterProps: {
                  data: processedBidData,
                },
              }}
              lowRightProps={{
                tableProps: { expandable: true },
                isDataLoading: bidSILoading,
                refetchData: refetchSI,
                isBidClosed: isBidClosed(),
              }}
            />
          </section>
        ),
      },
      {
        heading: 'Line Items',
        tabSelectedWhenOpen: tabIndex === 1,
        content: (
          <section className="margin-top-6">
            <LineItemsFilterTableFrame
              title="Line Items"
              filterToggle
              filterProps={{}}
              upRightProps={{
                siData: processedBidData,
                refetchData: () => {
                  refetch();
                  refetchSI();
                },
                setToaster,
                isBidClosed: isBidClosed(),
              }}
              lowRightProps={{
                tableProps: {
                  isPending: bidSILoading,
                },
                refetchData: () => {
                  refetch();
                  refetchSI();
                },
                isBidClosed: isBidClosed(),
              }}
              lowLeftProps={{
                filterProps: {
                  siData: processedBidData,
                  allLineItems: bidSIData?.getBidStandardItems || [],
                },
              }}
            />
          </section>
        ),
      },
      {
        heading: 'Notifications',
        tabSelectedWhenOpen: tabIndex === 2,
        content: (
          <section className="margin-top-6">
            <BidCommunicationCenter bidId={parseInt(bidId, 10)} />
          </section>
        ),
      },
    ];
  }, [
    tabIndex,
    processedBidData,
    bidSILoading,
    refetchSI,
    refetch,
    bidId,
    isBidClosed,
    soliData,
    bidSIData,
    solicitation,
    setToaster,
  ]);

  if (!soliCalled || !bidSICalled) return null;

  if (
    soliLoading ||
    bidSILoading ||
    !bidSIData?.getBidStandardItems ||
    !soliData?.getSolicitationById
  )
    return (
      <Spinner
        data-testid="bid-dashboard-spinner"
        className="margin-top-4"
        size="medium"
      />
    );

  if (soliError || bidSIError)
    return <ErrorMessage>Something went wrong</ErrorMessage>;

  return <TabSet onSelect={setTabIndex} tabs={tabs} />;
};

export default Tabs;
