import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DetailsTable, StatusBadge, Tag } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import Truncate from '../Truncate';
import './detail-table.scss';

const DetailTable = ({ data, className, showFormModal }) => {
  const [tableData, setTableData] = useState([]);

  const getComponent = (item) => {
    switch (item.type) {
      case 'text': {
        return [item.header, item.value];
      }

      case 'link': {
        return [
          item.header,
          <Link
            to="/catalog/vehicle-standards/2021/1822/1"
            target="_blank"
            rel="noopener noreferrer"
          >
            {item.value}
          </Link>,
        ];
      }

      case 'tags': {
        const tags = [];
        item?.value?.forEach((i) => {
          tags.push(
            <Tag
              variant="Inactive-Medium"
              className="usa-tag afp-tag bg-base text-white standard-item-tag-item"
              key={i}
            >
              {i}
            </Tag>,
          );
        });
        return [item.header, tags || '&ndash;'];
      }

      case 'status': {
        return [
          item.header,
          <StatusBadge variant={item.variant}>{item.value}</StatusBadge>,
        ];
      }

      case 'multi_status': {
        return [
          item.header,
          item.statuses.map((v) => {
            return (
              <div key={v.value} className="afp-multi-tag-spacer">
                <StatusBadge variant={v.variant}>{v.value}</StatusBadge>
              </div>
            );
          }),
        ];
      }

      case 'truncate': {
        return [
          item.header,
          item.value?.content ? (
            <Truncate
              content={item.value?.content}
              showFormModal={showFormModal}
            />
          ) : undefined,
        ];
      }

      default: {
        return [item.header, item.value];
      }
    }
  };

  const getComponentWithClass = (item) => {
    const [header, value] = getComponent(item);
    if (item.className && value)
      return [header, <div className={item.className}>{value}</div>];
    return [header, value];
  };

  useEffect(() => {
    const arr = [];
    data?.forEach((item) => {
      const tablecomponent = getComponentWithClass(item);
      arr.push(tablecomponent);
    });
    setTableData(arr);
  }, [data, showFormModal]);

  return <DetailsTable data={tableData} className={className} />;
};

DetailTable.defaultProps = {
  className: '',
  showFormModal: false,
};

DetailTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
  showFormModal: PropTypes.bool,
};

export default DetailTable;
