import React from 'react';
import {
  Button,
  RequiredFieldIndicator,
  useModal,
  StatusBadge,
} from '@gsa/afp-component-library';
import {
  emDashUnicode,
  enDashUnicode,
} from '../../../../../utilities/constants';
import { useContractLine } from '../../provider/contract-line-provider';
import { getMakeModelStatusBadge } from '../../../../bid-dashboard/helpers';
import { SIMakeModelPanellProvider } from '../../../contract-line/make-model/si-make-model-panel-provider';
import SelectMakeModelModal from './select-make-model-modal';

const LineMakeModelSection = () => {
  const {
    contractLine,
    lineTabData,
    setLineTabData,
    showChassis,
    requiresChassis,
    hasSinReqChassisTag,
    canUpdateContract,
  } = useContractLine();

  const { isOpen, openModal, closeModal } = useModal();

  const genLabel = (label, required) => (
    <span className="text-bold" data-testid={label}>
      {label} {required && <RequiredFieldIndicator />}
    </span>
  );

  const makeName =
    lineTabData?.make?.makeName ||
    lineTabData?.make?.bodyMakeInputValue ||
    contractLine?.make?.makeName ||
    enDashUnicode;
  const modelName =
    lineTabData?.model?.modelName ||
    lineTabData?.model?.bodyModelInputValue ||
    contractLine?.model?.modelName ||
    enDashUnicode;
  const chassisMakeName = lineTabData?.chassisMake
    ? lineTabData?.chassisMake?.makeName
    : contractLine?.chassisMake?.makeName;
  const chassisModelName = lineTabData?.chassisModel
    ? lineTabData?.chassisModel?.modelName
    : contractLine?.chassisModel?.modelName;
  const modelYear =
    lineTabData?.modelYear || contractLine?.modelYear || enDashUnicode;

  const determineApprovalStatus = (makeStatus, modelStatus) => {
    // check rejected first, and if  rejected, return rejected
    if (makeStatus === 'Rejected' || modelStatus === 'Rejected') {
      return 'Rejected';
    }

    // if one is pending review, return pending review
    if (makeStatus === 'PendingReview' || modelStatus === 'PendingReview') {
      return 'PendingReview';
    }

    // if both are approved, return approved
    if (makeStatus === 'Approved' && modelStatus === 'Approved') {
      return 'Approved';
    }

    return 'PendingReview';
  };

  const getApprovalStatus = () => {
    // if the SIN has a chassis tag, return the status from the make-model-approval table
    if (hasSinReqChassisTag) {
      return contractLine?.makeModelApproval?.fleetApprovalStatus;
    }

    const make = lineTabData?.make || contractLine?.make;
    const model = lineTabData?.model || contractLine?.model;
    const makeStatus = make?.fleetStatus;
    const modelStatus = model?.fleetStatus;

    const makeModelApprovalStatus = determineApprovalStatus(
      makeStatus,
      modelStatus,
    );
    return makeModelApprovalStatus;
  };

  const status = getApprovalStatus();
  const badge = getMakeModelStatusBadge(status);

  const solicitation = contractLine?.contractHeader?.solicitation;
  const purchaseTypeCode = solicitation?.purchaseTypeCode;
  const isNonStandard = purchaseTypeCode === 'N';
  const isMas = purchaseTypeCode === 'M';

  const manufacturerModelNumber =
    lineTabData?.manufacturerModelNumber ||
    contractLine?.manufacturerModelNumber;
  const showManufacturerNumber =
    (isNonStandard && contractLine?.manufacturerModelNumber) || isMas;

  return (
    <div className="margin-top-2 margin-bottom-4">
      <div className="grid-row grid-gap-lg">
        <div className="grid-col flex-1">
          {genLabel('Standard item', false)}
          <div className="padding-top-1">
            {contractLine?.standardItem?.standardItemNumber || enDashUnicode}{' '}
          </div>
        </div>

        {showManufacturerNumber && (
          <>
            <div className="grid-col flex-1">
              {genLabel('Manufacturer model number', false)}
              <div className="padding-top-1">
                {manufacturerModelNumber || enDashUnicode}
              </div>
            </div>
            <div className="grid-col flex-1">
              {genLabel('Model year', false)}
              <div className="padding-top-1">
                {parseInt(modelYear, 10) === 1901 ? emDashUnicode : modelYear}
              </div>
            </div>
          </>
        )}
        {!showManufacturerNumber && (
          <>
            <div className="grid-col flex-1">
              {genLabel('Body Make and Model', false)}
              <div className="padding-top-1">
                {makeName} {modelName}
              </div>
            </div>
            {showChassis && (
              <div className="grid-col flex-1">
                {genLabel('Chassis Make and Model', false)}
                <div className="padding-top-1">
                  {chassisMakeName || enDashUnicode}{' '}
                  {chassisModelName || enDashUnicode}
                </div>
              </div>
            )}
            <div className="grid-col flex-1">
              {genLabel('Model year', false)}
              <div className="padding-top-1">
                {parseInt(modelYear, 10) === 1901 ? emDashUnicode : modelYear}
              </div>
            </div>
            <div className="grid-col flex-1">
              {genLabel('Status', false)}
              <div className="padding-top-1">
                {badge ? (
                  <StatusBadge variant={badge.variant}>
                    {badge.label}
                  </StatusBadge>
                ) : (
                  emDashUnicode
                )}
              </div>
            </div>
          </>
        )}
        {canUpdateContract && (
          <div className="grid-col flex-1 padding-top-1">
            <Button
              className="flex-justify-self-end"
              variant="outline"
              label="Edit line item"
              onClick={openModal}
            />
          </div>
        )}
      </div>

      {isOpen && (
        <SIMakeModelPanellProvider
          showChassis={showChassis}
          requiresChassis={requiresChassis}
          initialState={{
            bodyMake: lineTabData?.make || contractLine?.make,
            bodyModel: lineTabData?.model || contractLine?.model,
            chassisMake: lineTabData?.chassisMake || contractLine?.chassisMake,
            chassisModel:
              lineTabData?.chassisModel || contractLine?.chassisModel,
            modelYear: lineTabData?.modelYear || contractLine?.modelYear,
            standardItem: contractLine?.standardItem,
          }}
        >
          <SelectMakeModelModal
            showManufacturerNumber={showManufacturerNumber}
            data={contractLine}
            onClose={closeModal}
            onSelect={(data) => {
              setLineTabData((prev) => {
                return {
                  ...prev,
                  ...data,
                };
              });
              closeModal();
            }}
          />
        </SIMakeModelPanellProvider>
      )}
    </div>
  );
};

export default LineMakeModelSection;
