import {
  AFPTable,
  AFPTableRowAction,
  Button,
  Pagination,
} from '@gsa/afp-component-library';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import PredeterminedInput from '../../widgets/predetermined-input';
import useDidMountEffect from '../../hooks/use-did-mount';
import AssociationModalWrapper from './standard-item-equipment-form-modal';
import { useStandardItem } from './standard-item-provider';
import { OPERATIONS, SUBJECTS } from '../../utilities/constants';
import AssociationFilters from './standard-item-equipment-association-filter-panel';

const InputType = ({
  row: {
    original: { inputType, unit, lowerBound, upperBound, preDefinedValue },
  },
}) => {
  switch (inputType?.code?.toLowerCase()) {
    case 'c': // Comply yes/no
      return 'Comply yes/no';
    case 'p': // Predefined value
      return <PredeterminedInput preDefinedValue={preDefinedValue} />;
    case 'n': {
      // Numerical
      if (lowerBound && unit?.title) return `${lowerBound} ${unit.title}`;
      return '-';
    }

    case 'r': {
      // Numerical range
      if (lowerBound && upperBound && unit?.title)
        return `${lowerBound}-${upperBound} ${unit.title}`;
      return '-';
    }

    default:
      return inputType?.title ?? '-';
  }
};

const actionList = [
  {
    icon: 'edit',
    label: 'Edit',
    canShowIndicator: 'canEdit',
  },
  {
    icon: 'delete',
    label: 'Remove',
    canShowIndicator: 'canDelete',
  },
];

const getActions = (row) => {
  const isEquipmentCodeReadOnly =
    row?.equipmentCode?.isEquipmentCodeInOpenSolicitation;
  if (isEquipmentCodeReadOnly) {
    return [];
  }
  return actionList;
};

const multipleOptions = [
  {
    model: 'StandardsCodeModel',
    label: 'title',
    value: 'code',
    filter: {
      operator: 'EQ',
      key: 'standardCodeMetadataId',
      value: '8',
    },
    uniqueKey: 'program',
    includeValueInLabel: true,
  },
  {
    model: 'StandardsCodeModel',
    label: 'title',
    value: 'code',
    filter: { operator: 'EQ', key: 'standardCodeMetadataId', value: '14' },
    uniqueKey: 'units',
  },
  {
    model: 'StandardsCodeModel',
    label: 'title',
    value: 'code',
    filter: {
      operator: 'EQ',
      key: 'standardCodeMetadataId',
      value: '2',
    },
    uniqueKey: 'associationType',
  },
  {
    model: 'StandardsCodeModel',
    label: 'title',
    value: 'code',
    filter: {
      operator: 'EQ',
      key: 'standardCodeMetadataId',
      value: '4',
    },
    uniqueKey: 'inputType',
  },
  {
    model: 'StandardsCodeModel',
    label: 'title',
    value: 'code',
    filter: {
      operator: 'EQ',
      key: 'standardCodeMetadataId',
      value: '5',
    },
    uniqueKey: 'criteria',
  },
  {
    model: 'StandardsCodeModel',
    label: 'title',
    value: 'code',
    filter: {
      operator: 'EQ',
      key: 'standardCodeMetadataId',
      value: '9',
    },
    uniqueKey: 'fedStandardCode',
    includeValueInLabel: true,
  },
  {
    model: 'StandardsCodeModel',
    label: 'title',
    value: 'code',
    filter: {
      operator: 'EQ',
      key: 'standardCodeMetadataId',
      value: '11',
    },
    uniqueKey: 'vehicleType',
    includeValueInLabel: true,
    parent: 'parentCode',
  },
  // VechicleGroups added to auto populate fedStandardCode of an SI in SI Detail->Edit
  {
    model: 'StandardsCodeModel',
    label: 'title',
    value: 'code',
    filter: {
      operator: 'EQ',
      key: 'standardCodeMetadataId',
      value: '10',
    },
    uniqueKey: 'vehicleGroup',
    includeValueInLabel: true,
    parent: 'parentCode',
  },
  {
    model: 'StandardsCodeModel',
    label: 'title',
    value: 'code',
    filter: {
      operator: 'EQ',
      key: 'standardCodeMetadataId',
      value: '16',
    },
    uniqueKey: 'fastCodes',
    includeValueInLabel: false,
  },
  {
    model: 'StandardsCodeModel',
    label: 'title',
    value: 'code',
    filter: { operator: 'EQ', key: 'standardCodeMetadataId', value: '12' },
    uniqueKey: 'tags',
    includeValueInLabel: true,
  },
];

const hardLimit = 500;

const AssociationTable = () => {
  const {
    MODAL_MODES,
    setData,
    standardItem,
    selectedAssociations,
    equipmentAssociationList,
    getEquipmentAssociations,
    getMultipleOptions,
    selectAllSelected,
    clearCheckBox,
  } = useStandardItem();

  const tableRef = React.createRef();
  const ability = useAppAbility();

  const [filters, setFilters] = useState({});
  const [order, setOrder] = useState('equipmentCode.code ASC');
  const [paginationState, setPaginationState] = useState({
    limit: 10,
    offset: 0,
    currentPage: 1,
  });

  const getData = () => {
    const { limit, offset } = paginationState;
    getEquipmentAssociations({
      variables: { filters, limit, offset, order },
    });
  };

  const isSINReadOnly = standardItem?.isStandardItemInOpenSolicitation;

  useEffect(() => {
    if (!standardItem?.id) return;

    // Load dropdown options.
    getMultipleOptions({
      variables: {
        options: multipleOptions,
      },
    });

    setFilters({
      operator: 'AND',
      value: [
        {
          operator: 'EQ',
          value: standardItem.year,
          key: '$standardItem.year$',
        },
        {
          operator: 'EQ',
          value: standardItem.id,
          key: 'standardItemId',
        },
        {
          operator: 'EQ',
          value: 1,
          key: '$equipmentCode.is_active$',
        },
        {
          operator: 'EQ',
          value: null,
          key: '$equipmentCode.deleted_at$',
        },
      ],
    });
  }, [standardItem?.id]);

  useDidMountEffect(() => {
    getData();
  }, [filters, order]);

  useDidMountEffect(() => {
    getData();
    tableRef?.current?.toggleAllRowsSelected(false);
  }, [paginationState]);

  useEffect(() => {
    if (selectAllSelected) {
      setPaginationState((prev) => ({ ...prev, limit: hardLimit }));
      const checkbox = document.getElementById('select-row-select-all-header');
      checkbox.checked = true;
    } else {
      setPaginationState((prev) => ({ ...prev, limit: 10 }));
    }
  }, [selectAllSelected]);

  useEffect(() => {
    if (selectAllSelected && equipmentAssociationList?.rows) {
      tableRef?.current?.toggleAllRowsSelected();
    }
  }, [equipmentAssociationList]);

  const handleSelectedAction = useCallback((e, row) => {
    setData('SET_EQUIPMENT_ASSOCIATION', row.original);
    setData('SET_FORM_MODAL', true);
    setData('SET_SUCCESS_MESSAGE', '');
    setData('SET_ERROR_MESSAGE', '');

    if (e === 'Edit') {
      setData('SET_MODAL_MODE', MODAL_MODES.EDIT);
    }
    if (e === 'Remove') {
      setData('SET_MODAL_MODE', MODAL_MODES.CONFIRM_DELETE);
    }
    return null;
  }, []);

  const columns = useMemo(() => {
    const columnList = [
      {
        Header: 'Equipment Code',
        accessor: 'equipmentCode.code',
      },
      {
        Header: 'Type',
        accessor: 'associationType.title',
        Cell: ({ value }) => {
          return value?.length ? value : '-';
        },
      },
      {
        Header: 'Title',
        accessor: 'equipmentCode.title',
        Cell: ({ value }) => {
          return value?.length ? value : '-';
        },
      },
      {
        Header: 'Standard details',
        accessor: 'associationText',
        cellClassName: 'afpTable-cell-wordBreak',
        Cell: ({ value }) => {
          return value?.length ? value : '-';
        },
      },
      {
        Header: 'Minimums',
        accessor: 'inputType.title',
        Cell: (props) => <InputType {...props} />,
      },
    ];

    if (
      !isSINReadOnly &&
      ability.can(OPERATIONS.Manage, SUBJECTS.StandardItem)
    ) {
      columnList.push({
        Header: 'Actions',
        sortable: false,
        Cell: (props) => {
          // eslint-disable-next-line react/prop-types
          const { row } = props;
          return (
            <AFPTableRowAction
              // eslint-disable-next-line react/prop-types
              actions={getActions(row.original)}
              onSelectAction={(evt) => handleSelectedAction(evt, row)}
              {...props}
            />
          );
        },
      });
    }
    return columnList;
  }, [isSINReadOnly, ability, handleSelectedAction]);

  const handleClear = () => {
    tableRef?.current?.toggleAllRowsSelected(false);
    setData('SET_SELECTALL_SELECTED', false);
    setPaginationState((prev) => ({ ...prev, limit: 10 }));
  };

  useEffect(() => {
    if (clearCheckBox) {
      tableRef?.current?.toggleAllRowsSelected(false);
      setData('SET_CLEAR_CHECKBOX', false);
    }
  }, [clearCheckBox]);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    // Calculate new offset.
    const offset = (currentPage - 1) * itemsPerPage;

    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  useDidMountEffect(() => {
    handlePaginationChange(1, paginationState.limit);
    setOrder('equipmentCode.code ASC');
    getData();
  }, [filters]);

  return (
    <div className="equipment-association">
      <div className="grid-row margin-top-6">
        <h2>Equipment Codes</h2>
      </div>

      <div className="grid-row grid-gap">
        <div className="desktop:grid-col-2 tablet-lg:grid-col-3" />
        <div className="desktop:grid-col-10 tablet-lg:grid-col-9">
          <div className="grid-row margin-top-4 margin-bottom-6">
            <div className="grid-col padding-left-2">
              {selectedAssociations?.length ? (
                <span>
                  <b>{selectedAssociations.length}</b> Equipment Codes selected
                  <Button
                    onClick={handleClear}
                    className="usa-button usa-button--unstyled margin-left-1"
                    label="Clear"
                    leftIcon={{
                      name: 'close',
                      className: 'usa-icon--size-2',
                    }}
                  />
                </span>
              ) : null}
            </div>
            <div className="gird-col">
              {!isSINReadOnly &&
              selectedAssociations?.length &&
              ability.can(OPERATIONS.Manage, SUBJECTS.StandardItem) ? (
                <>
                  <Button
                    variant="outline"
                    onClick={() => {
                      setData('SET_FORM_MODAL', true);
                      setData('SET_MODAL_MODE', MODAL_MODES.COPY);
                      setData('SET_SUCCESS_MESSAGE', '');
                      setData('SET_ERROR_MESSAGE', '');
                    }}
                    label="Copy Equipment Codes to Std. Item"
                    leftIcon={{
                      name: 'content_copy',
                    }}
                  />
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setData('SET_FORM_MODAL', true);
                      setData(
                        'SET_MODAL_MODE',
                        MODAL_MODES.CONFIRM_BULK_DELETE,
                      );
                      setData('SET_SUCCESS_MESSAGE', '');
                      setData('SET_ERROR_MESSAGE', '');
                    }}
                    label="Remove Equipment Codes"
                    leftIcon={{
                      name: 'delete',
                    }}
                  />
                </>
              ) : null}
              {standardItem?.canEdit &&
                standardItem?.status === 'Active' &&
                ability.can(OPERATIONS.Manage, SUBJECTS.StandardItem) &&
                !isSINReadOnly && (
                  <Button
                    onClick={() => {
                      setData('SET_FORM_MODAL', true);
                      setData('SET_MODAL_MODE', MODAL_MODES.ADD);
                      setData('SET_SUCCESS_MESSAGE', '');
                      setData('SET_ERROR_MESSAGE', '');
                    }}
                    label="Add Equipment Code"
                    leftIcon={{
                      name: 'add',
                    }}
                  />
                )}
            </div>
          </div>
        </div>
      </div>

      <div className="grid-row grid-gap">
        <div className="desktop:grid-col-3 tablet-lg:grid-col-3 association-filter-wrapper margin-top-7">
          <AssociationFilters setFilters={setFilters} filters={filters} />
        </div>
        <div className="desktop:grid-col-9 tablet-lg:grid-col-9 association-table-wrapper margin-top-5">
          <AFPTable
            key={`table-${isSINReadOnly}`}
            ref={tableRef}
            onRowSelect={({ selectedFlatRows }) => {
              setData('SET_EQUIPMENT_ASSOCIATION_SELECTIONS', selectedFlatRows);
            }}
            columns={columns}
            data={equipmentAssociationList.rows}
            onSort={setOrder}
            defaultSort={order}
            selectable={ability.can(OPERATIONS.Manage, SUBJECTS.StandardItem)}
            selectAllHeader="Select to copy"
            selectAllHeaderClicked={() => {
              const checkbox = document.getElementById(
                'select-row-select-all-header',
              );
              setData('SET_SELECTALL_SELECTED', checkbox?.checked);
            }}
          />

          {!selectAllSelected && equipmentAssociationList?.rows ? (
            <div className="margin-y-6">
              <Pagination
                variant="advanced"
                itemsPerPageOptions={[10, 25, 50]}
                itemsCount={equipmentAssociationList?.count}
                itemsPerPage={paginationState.limit}
                currentPage={paginationState.currentPage}
                onPageChange={handlePaginationChange}
              />
            </div>
          ) : null}
          <AssociationModalWrapper />
        </div>
      </div>
    </div>
  );
};

export default AssociationTable;
