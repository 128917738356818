/*  eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useEffect, useState } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import {
  Modal,
  connectModal,
  Button,
  Alert,
  Spinner,
  SelectDropdown,
} from '@gsa/afp-component-library';
import MakesAndModleTable, {
  MakeModelTableDataType,
} from './makes-and-models-table';
import MakeAndModelSearchPanel from './make-and-model-select-panel';
import { useBidMakeAndModelContext } from './make-and-model-provider';
import {
  CREATE_BID_LINE_ITEM,
  REMOVE_BID_LINE,
  REQUEST_NEW_MAKE_MODEL_APPROVAL,
  GET_ENGINEER_DETAILS,
} from '../../../bids/bids.gql';
import { getApprovalStatus } from '../../helpers';
import { emDashUnicode } from '../../../../utilities/constants';
import {
  FLEET_APPROVAL_STATUS,
  CHASSIS_TAG_REGEX,
  ENTRY_SOURCE,
} from '../../constants';

const BidMakesAndModelsModal = ({ onClose, initialData, modalTitle }) => {
  const { siData, genericSIModal } = initialData;
  const [selectedItem, setSelectedItem] = useState(
    genericSIModal ? {} : siData?.[0],
  );

  const [tableData, setTableData] = useState(selectedItem?.afpMake);

  useEffect(() => {
    setTableData(selectedItem?.afpMake);
  }, [selectedItem]);

  const [toRefetch, setToRefetch] = useState(false);
  const [alert, setAlert] = useState(null);
  const [deleteItem, setDeleteItem] = useState(null);
  const [, setFleetStatus] = useState(FLEET_APPROVAL_STATUS.PendingReview);

  const {
    bodyMake,
    bodyModel,
    chassisMake,
    chassisModel,
    bodyMakeInputValue,
    bodyModelInputValue,
    chassisMakeInputValue,
    chassisModelInputValue,
    getErrors,
    modelYear,
    reset,
  } = useBidMakeAndModelContext();

  const {
    solicitationLineId,
    bidId,
    standardItemId,
    standardItemNumber,
    title,
    tags,
  } = selectedItem;

  const error = getErrors();

  const modalSubtitle = `${standardItemNumber} - ${title}`;
  const hasChassis = tags?.value.some((tag) => tag.match(CHASSIS_TAG_REGEX));

  // gql call to add bidLine (make/model)
  const [addBidLine, { loading: adding }] = useMutation(CREATE_BID_LINE_ITEM, {
    onCompleted: (data) => {
      const mmApprovalStatus = getApprovalStatus(data?.createBidLineItem);
      // let approvalStatus;
      if (mmApprovalStatus === FLEET_APPROVAL_STATUS.PendingReview) {
        // approvalStatus = FLEET_APPROVAL_STATUS.PendingReview;
        setFleetStatus(FLEET_APPROVAL_STATUS.PendingReview);
        setAlert({
          type: 'warning',
          message:
            'One or more Make and Model combinations require GSA review and approval.' +
            'Bid for this item may be rejected.',
        });
      } else if (mmApprovalStatus === FLEET_APPROVAL_STATUS.Approved) {
        // approvalStatus = ENGINEER_APPROVAL_STATUS.Approved;
        setFleetStatus(FLEET_APPROVAL_STATUS.Approved);
        setAlert({
          type: 'success',
          message: 'Make and model has been added to the bid.',
        });
      }

      // setAddMakeModelContext('RESET'); // reset add form
      reset();
      setToRefetch(true); // to trigger refetch when modal is closed

      // update make/model list for MakesAndModelTable
      setTableData((prevData) => [
        ...prevData,
        {
          bidLineId: data.createBidLineItem.id,
          make: {
            makeName: bodyMakeInputValue || bodyMake?.label,
            fleetStatus: data?.createBidLineItem?.afpMake?.fleetStatus,
          },
          model: {
            modelName: bodyModelInputValue || bodyModel?.label,
            fleetStatus: data?.createBidLineItem?.afpModel?.fleetStatus,
          },
          chassisMake: {
            makeName: chassisMakeInputValue || chassisMake?.label,
            fleetStatus: data?.createBidLineItem?.chassisMake?.fleetStatus,
          },
          chassisModel: {
            modelName: chassisModelInputValue || chassisModel?.label,
            fleetStatus: data?.createBidLineItem?.chassisModel?.fleetStatus,
          },
          modelYear,
          makeModelApproval: {
            fleetApprovalStatus:
              data?.createBidLineItem?.makeModelApproval?.fleetApprovalStatus,
          },
          standardItem: {
            tags: data?.createBidLineItem?.standardItem?.tags,
          },
        },
      ]);
    },
    onError: (err) => {
      setAlert({
        type: 'error',
        message: `Unable to add make and model: ${err.message}.`,
      });
    },
  });

  // gql call to get the engineer details
  const [
    getEngineerDetails,
    { loading: engineerDetailsLoading },
  ] = useLazyQuery(GET_ENGINEER_DETAILS, {
    onCompleted: (data) => {
      // Set the Alert
      setAlert({
        type: 'warning',
        message: `One or more Make and Model combinations selected are in Rejected status. Please reach out to the 
                  GSA Engineering team ( Engineer Name - ${data?.getStandardsVehicleGroupPocsByStdItemId?.name}, 
                  Email - ${data?.getStandardsVehicleGroupPocsByStdItemId?.email}) if the status needs review.`,
      });
    },
    onError: (err) => {
      setAlert({
        type: 'error',
        message: `Unable to get the engineer details: ${err.message}.`,
      });
    },
  });

  // gql call to add a new (make/model) which is subjected to approval
  const [addNewMakeModel, { loading: newMakeModelAdding }] = useMutation(
    REQUEST_NEW_MAKE_MODEL_APPROVAL,
    {
      onCompleted: (data) => {
        // Set the fleet status
        setFleetStatus(data?.requestNewMakeModelApproval?.fleetApprovalStatus);

        let createBidLineInput = {
          solicitationLineId: parseFloat(solicitationLineId),
          bidId,
          standardItemId: parseFloat(standardItemId),
          standardItemNumber,
          makeCode: parseFloat(data?.requestNewMakeModelApproval?.makeCode),
          modelCode: data?.requestNewMakeModelApproval?.modelCode,
          modelYear: parseFloat(modelYear),
        };
        if (hasChassis) {
          createBidLineInput = {
            ...createBidLineInput,
            chassisMakeCode: parseFloat(
              data?.requestNewMakeModelApproval?.chassisMakeCode,
            ),
            chassisModelCode:
              data?.requestNewMakeModelApproval?.chassisModelCode,
          };
        }

        if (
          data?.requestNewMakeModelApproval?.fleetApprovalStatus === 'Rejected'
        ) {
          getEngineerDetails({
            variables: {
              standardItemId: parseFloat(standardItemId),
            },
          });
        } else {
          addBidLine({ variables: { createBidLineInput } });
        }
      },
      onError: (err) => {
        setAlert({
          type: 'error',
          message: `Unable to add make and model: ${err.message}.`,
        });
      },
    },
  );

  // gql call to remove bidLine (make/model)
  const [removeBidLine, { loading: deleting }] = useMutation(REMOVE_BID_LINE, {
    onCompleted: () => {
      setAlert({
        type: 'success',
        message: (
          <>
            Make and model{' '}
            <b>
              {deleteItem.make?.makeName || emDashUnicode}{' '}
              {deleteItem.model?.modelName || emDashUnicode}
            </b>{' '}
            has been removed from the bid.
          </>
        ),
      });
      setToRefetch(true); // to trigger refetch when modal is closed

      // update make/model list for MakesAndModleTable
      setTableData((prevData) =>
        prevData.filter((item) => item.bidLineId !== deleteItem.bidLineId),
      );
    },
    onError: (err) => {
      setAlert({
        type: 'error',
        message: (
          <>
            Unable to remove make and model{' '}
            <b>
              {deleteItem.make?.makeName || emDashUnicode}{' '}
              {deleteItem.model?.modelName || emDashUnicode}
            </b>
            . : {err.message}.
          </>
        ),
      });
    },
  });

  const onAddMakeModel = () => {
    if (error?.length > 0) {
      setAlert({
        type: 'error',
        message: (
          <>
            <strong>{error[0].name}</strong> is a required field.
          </>
        ),
      });
    } else {
      setAlert(null);

      // if no chassis, the make and model is fleet Approved
      // create the bid line item directly without approval else send for approval
      const isBodyMakeApproved =
        bodyMake?.fleetApprovalStatus === FLEET_APPROVAL_STATUS.Approved;
      const isBodyModelApproved =
        bodyModel?.fleetApprovalStatus === FLEET_APPROVAL_STATUS.Approved;

      if (!hasChassis && isBodyMakeApproved && isBodyModelApproved) {
        addBidLine({
          variables: {
            createBidLineInput: {
              solicitationLineId: parseFloat(solicitationLineId),
              bidId,
              standardItemId: parseFloat(standardItemId),
              standardItemNumber,
              makeCode: parseFloat(bodyMake?.value),
              modelCode: bodyModel?.value,
              modelYear: parseFloat(modelYear),
            },
          },
        });
      } else {
        const makeModelApprovalRequestInput = {
          makeName: bodyMakeInputValue?.trim() || bodyMake?.label,
          modelName: bodyModelInputValue?.trim() || bodyModel?.label,
          ...(hasChassis && {
            chassisMakeName:
              chassisMakeInputValue?.trim() || chassisMake?.label,
          }),
          ...(hasChassis && {
            chassisModelName:
              chassisModelInputValue?.trim() || chassisModel?.label,
          }),
          entrySource: ENTRY_SOURCE.BID,
          standardItemInfo: [standardItemId, standardItemNumber],
        };

        addNewMakeModel({ variables: { makeModelApprovalRequestInput } });
      }
    }
  };

  const onRemoveMakeModel = (item) => {
    setDeleteItem(item);
    const cancelDelete = () => {
      setAlert(null);
      setDeleteItem(null);
    };
    setAlert({
      type: 'warning',
      onClose: cancelDelete,
      message: (
        <>
          <div tabIndex="0">
            Are you sure you want to delete make and model{' '}
            <b>
              {item.make?.makeName || emDashUnicode}{' '}
              {item.model?.modelName || emDashUnicode}
            </b>{' '}
            from the bid? Deleting this make and model will also delete the
            associated bid line from the solicitation.
          </div>
          <div>
            <Button
              label="Cancel"
              data-testid="cancel-delete-make-model"
              variant="unstyled"
              className="margin-right-2"
              onClick={cancelDelete}
            />
            <Button
              label="Delete"
              data-testid="confirm-delete-make-model"
              variant="unstyled"
              onClick={() => {
                removeBidLine({
                  variables: {
                    id: parseInt(item.bidLineId, 10),
                  },
                  skip: !item.bidLineId,
                });
              }}
            />
          </div>
        </>
      ),
    });
  };

  const showAlert = () => {
    if (!alert) return null;
    const { message, ...restProps } = alert;
    return (
      <div className="margin-top-2">
        <Alert
          slim
          showClose
          focused
          onClose={() => setAlert(null)}
          {...restProps}
        >
          {message}
        </Alert>
      </div>
    );
  };

  const onCloseModal = () => {
    onClose(toRefetch);
    // setAddMakeModelContext('RESET');
    reset();
  };

  return (
    <Modal
      id="add-makes-and-models-to-bid-si"
      className="solicitation-modal"
      variant="extra-large"
      title={
        <h2 tabIndex="0" aria-label="Select Makes and Models">
          {modalTitle}
        </h2>
      }
      onClose={onCloseModal}
      actions={
        <>
          <Button
            data-testid="close-bid-make-model-btn-modal"
            type="button"
            variant="primary"
            onClick={onCloseModal}
            label="Close"
          />
        </>
      }
    >
      {(adding || deleting || newMakeModelAdding || engineerDetailsLoading) && (
        <div
          role="none"
          className="afp-modal-overlay"
          data-testid="delete-make-model-loading-spinner"
        >
          <Spinner
            style={{
              position: 'absolute',
              top: '50%',
              transform: 'translate(0, -50%)',
            }}
          />
        </div>
      )}

      {genericSIModal && siData?.length >= 1 && (
        <SelectDropdown
          data-testid="select-standard-item-dropdown"
          aria-label="Standard Item"
          aria-required="true"
          className="margin-bottom-4"
          onChange={(ev) => {
            setSelectedItem(siData.find((item) => item.id === ev.target.value));
          }}
          options={[
            { value: '', label: 'Select Standard Item' },
            ...siData?.map((item) => ({
              value: item.id,
              label: `${item.standardItemNumber} - ${item.title}`,
            })),
          ]}
          value={selectedItem.id}
        />
      )}

      {selectedItem.standardItemNumber && (
        <div
          className="text-body text-base-dark"
          aria-label={modalSubtitle}
          tabIndex="0"
        >
          <h4>{modalSubtitle}</h4>
        </div>
      )}

      <MakeAndModelSearchPanel
        hasChassis={hasChassis}
        onAddMakeModel={onAddMakeModel}
        disabled={!selectedItem.standardItemNumber}
        action="add"
      />

      {showAlert()}

      <MakesAndModleTable
        data={tableData}
        hasChassis={hasChassis}
        onRemoveMakeModel={onRemoveMakeModel}
      />
    </Modal>
  );
};

export const StandardItemShape = PropTypes.shape({
  solicitationLineId: PropTypes.string.isRequired,
  bidId: PropTypes.number.isRequired,
  standardItemId: PropTypes.string.isRequired,
  standardItemNumber: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tags: PropTypes.shape({
    value: PropTypes.arrayOf(PropTypes.string),
  }),
  afpMake: MakeModelTableDataType.isRequired,
});

export const MakeModelModalDataType = PropTypes.shape({
  siData: PropTypes.arrayOf(StandardItemShape),
  genericSIModal: PropTypes.bool,
});

BidMakesAndModelsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  initialData: MakeModelModalDataType.isRequired,
  modalTitle: PropTypes.string,
};

BidMakesAndModelsModal.defaultProps = {
  modalTitle: 'Select Makes and Models',
};

export default connectModal(BidMakesAndModelsModal);
