import { useLazyQuery } from '@apollo/client';
import { AFPTable, Spinner, EmptyState } from '@gsa/afp-component-library';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { InputType } from '../../components/forms/eq-association-table-elements';
import {
  EQUIPMENT_CODES,
  GET_EQUIPMENT_ASSOCIATIONS,
} from '../../services/data-store';
import { useStandards } from './standards-provider';

const EquipmentAssociationsView = () => {
  const { showFormModal, selectedStandardItem } = useStandards();
  const [data, setData] = useState({
    rows: [],
    hasMore: false,
    count: 0,
  });

  const [order] = useState('equipmentCode.code ASC');
  const history = useHistory();

  const [getStdItemEquipmentAssociations, { loading }] = useLazyQuery(
    GET_EQUIPMENT_ASSOCIATIONS,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: () => {
        // TODO handle errors
      },
      onCompleted: (responseData) => {
        if (responseData?.getEquipmentAssociations) {
          setData(responseData.getEquipmentAssociations);
        }
      },
    },
  );

  const [getEquipmentCode] = useLazyQuery(EQUIPMENT_CODES, {
    onCompleted: (response) => {
      if (response?.getEquipmentCodes) {
        const equipmentCode = response?.getEquipmentCodes?.rows[0];
        const url = `/catalog/equip-codes/${equipmentCode.year}/${equipmentCode.program}/${equipmentCode.code}`;
        history.push(url, equipmentCode);
      }
    },
  });

  const getData = () => {
    // Only get date if modal is open and selectedStandardItem is available.
    if (!showFormModal || !selectedStandardItem?.id) return;

    getStdItemEquipmentAssociations({
      variables: {
        filters: {
          operator: 'AND',
          value: [
            {
              operator: 'EQ',
              value: selectedStandardItem.year,
              key: '$standardItem.year$',
            },
            {
              operator: 'EQ',
              value: selectedStandardItem.id,
              key: 'standardItemId',
            },
          ],
        },
        limit: 500,
        offset: 0,
        order,
      },
    });
  };

  useEffect(() => {
    getData();
  }, [showFormModal]);

  const handleClick = (value) => {
    getEquipmentCode({
      variables: {
        filters: { operator: 'EQ', key: 'id', value: value.equipmentCodeId },
      },
    });
  };

  /* eslint-disable react/prop-types */
  const columns = useMemo(
    () => [
      {
        Header: 'Equipment Code',
        accessor: 'equipmentCode.code',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value, row }) =>
          value ? (
            <button
              type="button"
              className="usa-button usa-button--unstyled standard-item-view-button"
              onClick={
                () =>
                  // eslint-disable-next-line react/prop-types
                  handleClick(row.original)
                // eslint-disable-next-line react/jsx-curly-newline
              }
            >
              {value}
            </button>
          ) : null,
      },
      {
        Header: 'Type',
        accessor: 'associationType.title',
        Cell: ({ value }) => {
          return value || <span>&ndash;</span>;
        },
      },
      {
        Header: 'Title',
        accessor: 'equipmentCode.title',
        Cell: ({ value }) => {
          return value || <span>&ndash;</span>;
        },
      },
      {
        Header: 'Standard details',
        accessor: 'associationText',
        Cell: ({ value }) => {
          return value || <span>&ndash;</span>;
        },
      },
      {
        Header: 'Minimums',
        accessor: 'inputType.title',
        Cell: (props) => <InputType {...props} />,
      },
    ],
    [],
  );

  if (loading) return <Spinner size="small" />;

  return (
    <div className="association-table-wrapper">
      <AFPTable columns={columns} data={data.rows} defaultSort={order} />
      {data.rows.length === 0 && (
        <EmptyState
          hasBackground
          containerStyles="padding-y-8 margin-top-1"
          topText={
            <strong>
              There are no Equipment Codes for this Standard Item.
            </strong>
          }
        />
      )}
    </div>
  );
};

EquipmentAssociationsView.prototype = {};

export default EquipmentAssociationsView;
