import React, { useState, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import {
  ErrorMessage,
  MultiSelect,
  SelectDropdown,
  TextInput,
} from '@gsa/afp-component-library';
import DescriptionInput from './description-input';
import StatusInput from './status-input';
import YearInput from './year-input';
import StandardItemCopyInput from './standard-item-copy-input';
import InteractiveCheck from '../InteractiveCheck/InteractiveCheck';
import {
  getRules,
  renderCodeError,
  renderTitleError,
  validate,
} from '../../utilities/validations/error-rules';
import { emDashUnicode } from '../../utilities/constants';

const StandardItemForm = ({
  formId,
  handleSave,
  isAddForm,
  fedStandardCodes,
  vehicleGroups,
  vehicleTypes,
  standardItemTags,
  fastCodes,
  years,
  isReadOnly,
  isPartialEditable,
  sinSelectedTags,
}) => {
  const standardItemCreationYears = years
    ?.filter((year) => Number(year?.value) > new Date().getFullYear())
    ?.reverse();

  const {
    register,
    watch,
    errors,
    control,
    handleSubmit,
    setValue,
  } = useFormContext();

  const watchContent = watch('content');
  const watchSinYear = !isAddForm ? watch('year') : null;
  const [correspondingVT, setCorrespondingVT] = useState([]);
  const [correspondingFSC, setCorrespondingFSC] = useState([]);

  const onSubmit = (data) => {
    handleSave({ ...data, content: watchContent });
  };

  // code
  const hasCodeError = errors?.standardItemNumber?.message;
  const isDirty = !isEmpty(watch('standardItemNumber')) || hasCodeError;
  const rules = getRules(watch('standardItemNumber'), 'StandardItemNumber');
  const isValid = validate(rules);
  const canShowCodeError = !isValid && isDirty;

  // standard item Title
  const hasTittleError = errors?.title?.message;
  const isTitleDirty = !isEmpty(watch('title')) || hasTittleError;
  const titleRules = getRules(watch('title'), 'Title');
  const isTitleValid = validate(titleRules);
  const canShowTitleError = !isTitleValid && isTitleDirty && hasTittleError;

  useEffect(() => {
    const VG = vehicleGroups?.find(
      (vg) => vg.value === correspondingVT?.parent,
    );
    const FSC = fedStandardCodes?.find((fsc) => fsc.value === VG?.parent);
    setCorrespondingFSC(FSC);
    setValue('fedStandardCode', FSC?.value);
  }, [correspondingVT]);

  const renderRequiredComponent = (value) => {
    return (
      <>
        {/* eslint-disable-next-line */}
        <h2 className="font-sans-xs" tabIndex="0" aria-label={value}>
          {value} <span className="usa-hint usa-hint--required">*</span>
        </h2>
      </>
    );
  };
  // if form is Edit and isReadOnly is true then disable the inputs
  const disabled = !isAddForm && isReadOnly;
  const currentYear = new Date().getFullYear();
  const isSinInCurrentYear = watchSinYear === currentYear;
  const partialEditable = !isAddForm && isPartialEditable; // Edit form and partial editable
  // tags that can be edited on current year
  const editableTags = ['LE', 'NOAREQ', 'PROP', 'REVIEWREQ'];
  const sinTagOptions = standardItemTags.map((tag) => ({
    ...tag,
    disabled: !editableTags.includes(tag.value) && partialEditable,
  }));

  return (
    <>
      <form id={formId} onSubmit={handleSubmit(onSubmit)}>
        <div
          className={`grid-row padding-bottom-2 ${
            canShowCodeError ? 'padding-left-2' : null
          }`}
        >
          <div className="grid-col-10">
            {renderRequiredComponent('Standard Item Number')}
            <>
              <TextInput
                id="si_form_standardItemNumber"
                name="standardItemNumber"
                type="text"
                className="width-full"
                aria-label="standard item number"
                aria-required="true"
                inputRef={register}
                style={{ display: isAddForm ? 'block' : 'none' }} // Hidden doesn't work
                errorMessage={renderCodeError(
                  canShowCodeError,
                  'Make sure the Standard Item number meets the requirements listed below.',
                )}
              />
              {hasCodeError && (
                <InteractiveCheck checks={rules} isDirty={isDirty} />
              )}
            </>

            {!isAddForm && (
              <span className=".text-normal">
                {watch('standardItemNumber')}
              </span>
            )}
          </div>
        </div>

        {/* Vehicle Type */}
        <div className="grid-row padding-bottom-2">
          <div className="grid-col-10">
            {renderRequiredComponent('Vehicle type')}
            <Controller
              id="si_form_vehicleType"
              name="vehicleType"
              control={control}
              render={({ onChange, onBlur, value }) => {
                const VT = vehicleTypes?.find((pvt) => pvt.value === value);
                setCorrespondingVT(VT);
                return (
                  <SelectDropdown
                    id="si_form_vehicleType_sel"
                    aria-label="vehicle type"
                    onChange={(v) => {
                      onChange(v);
                      setCorrespondingVT(
                        vehicleTypes?.find((pvt) => pvt.value === value),
                      );
                    }}
                    options={vehicleTypes}
                    value={value}
                    onBlur={onBlur}
                    // style={{ display: isAddForm ? 'block' : 'none' }}
                    disabled={disabled}
                  />
                );
              }}
            />
            {errors?.vehicleType?.message && (
              <ErrorMessage>{errors.vehicleType.message}</ErrorMessage>
            )}
          </div>
        </div>

        {/* fed std code */}
        <div className="grid-row padding-bottom-2">
          <div className="grid-col-10">
            <h2
              className="font-sans-xs"
              // eslint-disable-next-line
              tabIndex="0"
              aria-label="Federal Standard Code"
            >
              Federal Standard Code
            </h2>
            {correspondingFSC?.label ? (
              <Controller
                id="si_form_fedStandardCode"
                name="fedStandardCode"
                defaultValue={correspondingFSC?.value}
                control={control}
                render={({ onChange, onBlur }) => {
                  return (
                    <SelectDropdown
                      id="si_form_fedStandardCode_sel"
                      aria-label="federal standard code"
                      onChange={(v) => {
                        onChange(v);
                      }}
                      onBlur={onBlur}
                      options={[correspondingFSC]}
                      value={correspondingFSC?.value}
                      disabled={disabled}
                    />
                  );
                }}
              />
            ) : (
              // if there is no corresponding fedStandardCode show the empty fed-standard-code
              <Controller
                id="si_form_fedStandardCode"
                name="fedStandardCode"
                defaultValue={correspondingFSC?.value}
                control={control}
                render={({ onChange, onBlur, value }) => {
                  return (
                    <SelectDropdown
                      id="si_form_fedStandardCode_sel"
                      aria-label="federal standard code"
                      onChange={(v) => {
                        onChange(v);
                      }}
                      onBlur={onBlur}
                      options={[{ label: 'Select', value: '' }]}
                      value={value}
                      disabled={disabled}
                    />
                  );
                }}
              />
            )}

            {errors?.fedStandardCode?.message && (
              <ErrorMessage>{errors.fedStandardCode.message}</ErrorMessage>
            )}
          </div>
        </div>

        {/* Contract year */}
        <div className="grid-row padding-bottom-2">
          <div className="grid-col-10">
            <YearInput
              name="year"
              isAddForm={isAddForm}
              years={[...standardItemCreationYears]}
              isRequired
            />
          </div>
        </div>

        <div className="grid-row padding-bottom-2">
          <div className="grid-col-10">
            {/* eslint-disable-next-line */}
            <h2 className="font-sans-xs" tabIndex="0" aria-label="FAST Code">
              FAST Code
            </h2>

            <Controller
              id="si_form_fast"
              name="fast"
              control={control}
              render={({ onChange, onBlur, value }) => (
                <SelectDropdown
                  id="si_form_fastCode_sel"
                  aria-label="fast code"
                  onChange={(v) => {
                    onChange(v);
                  }}
                  options={[
                    ...fastCodes.map((fc) => {
                      if (fc.value === '') {
                        return { label: 'Select', value: '' };
                      }
                      return fc;
                    }),
                  ]}
                  value={value}
                  onBlur={onBlur}
                  disabled={disabled}
                />
              )}
            />
            {errors?.fast?.message && (
              <ErrorMessage>{errors.fast.message}</ErrorMessage>
            )}
          </div>
        </div>

        {/* Status */}
        <div className="grid-row padding-bottom-2">
          <div className="grid-col-10">
            <StatusInput
              disabled={disabled}
              isSinInCurrentYear={isSinInCurrentYear}
            />
          </div>
        </div>

        <div
          className={`grid-row padding-bottom-2 ${
            hasTittleError ? 'padding-left-2' : null
          }`}
        >
          <div className="grid-col-10">
            {renderRequiredComponent('Title')}
            <TextInput
              id="si_form_title"
              aria-label="title"
              aria-required="true"
              name="title"
              type="text"
              inputRef={register}
              errorMessage={renderTitleError(canShowTitleError)}
            />
            {hasTittleError && (
              <InteractiveCheck checks={titleRules} isDirty={isTitleDirty} />
            )}
          </div>
        </div>

        <div className="grid-row padding-bottom-2">
          <div className="grid-col-10">
            {/* eslint-disable-next-line */}
            <h2 className="font-sans-xs" tabIndex="0" aria-label="Tags">
              Tags
            </h2>
            {disabled ? (
              <span className=".text-normal">
                {(sinSelectedTags?.length && sinSelectedTags?.join(', ')) ||
                  emDashUnicode}
              </span>
            ) : (
              <>
                <Controller
                  id="si_form_tags"
                  name="tags"
                  control={control}
                  render={({ onChange, value }) => (
                    <MultiSelect
                      data-testid="si_form_tags_sel"
                      key="i_form_tags_sel"
                      name="tags"
                      options={sinTagOptions}
                      value={value}
                      onChange={(val, checked) => {
                        let v;
                        if (checked) {
                          v = [...value, val];
                        } else {
                          v = value.filter((el) => el !== val);
                        }
                        onChange(v);
                      }}
                      selectedValues={value}
                    />
                  )}
                />
                {errors?.tags?.message && (
                  <ErrorMessage>{errors.tags.message}</ErrorMessage>
                )}
              </>
            )}
          </div>
        </div>

        <div className="grid-row padding-bottom-2">
          <div className="grid-col-10">
            <DescriptionInput />
          </div>
        </div>

        {isAddForm && (
          <>
            <div className="grid-row padding-bottom-2">
              <div className="grid-col-10">
                <StandardItemCopyInput
                  isMandatory={false}
                  sectionTitle="COPY EQUIPMENT CODE ASSOCIATIONS FROM"
                  years={years}
                  yearInputName="copyFromYear"
                  sinInputName="copyFromStandardItemId"
                />
              </div>
            </div>
          </>
        )}
      </form>
    </>
  );
};

StandardItemForm.propTypes = {
  formId: PropTypes.string.isRequired,
  handleSave: PropTypes.func.isRequired,
  isAddForm: PropTypes.bool.isRequired,
  fedStandardCodes: PropTypes.shape(Object).isRequired,
  vehicleGroups: PropTypes.shape(Object).isRequired,
  vehicleTypes: PropTypes.shape(Object).isRequired,
  fastCodes: PropTypes.shape(Object).isRequired,
  standardItemTags: PropTypes.shape(Object).isRequired,
  years: PropTypes.shape(Object).isRequired,
  isReadOnly: PropTypes.bool,
  isPartialEditable: PropTypes.bool,
  sinSelectedTags: PropTypes.shape(Object),
};

StandardItemForm.defaultProps = {
  isReadOnly: false,
  isPartialEditable: false,
  sinSelectedTags: [],
};

export default StandardItemForm;
