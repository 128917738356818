import React from 'react';
import PropTypes from 'prop-types';
import {
  StandardFieldset,
  StandardFieldsetRow,
  StandardFieldsetRowCol,
} from './standard-fieldset';
import {
  getContractPeriod,
  getContractType,
  getContractYear,
  CONTRACT_TYPE_MAP,
} from './contract-helpers';
import './contract-details.scss';
import { emDashUnicode } from '../../../utilities/constants';

const contractShape = PropTypes.shape({
  contractNumber: PropTypes.string,
  contractYear: PropTypes.number,
  awardedDate: PropTypes.string,
  formalContractNumber: PropTypes.string,
  solicitation: PropTypes.shape({
    solicitationNumberLegacy: PropTypes.string,
    solicitationNumber: PropTypes.string,
    contractYear: PropTypes.number,
    purchaseTypeCode: PropTypes.string,
  }),
  solicitationPeriod: PropTypes.shape({
    periodType: PropTypes.string,
  }),
  fleetAwarded: PropTypes.bool,
  contractUpiid: PropTypes.string,
  vendor: PropTypes.shape({
    id: PropTypes.string,
    vendorName: PropTypes.string,
  }),
  publishedInFleet: PropTypes.bool,
  solicitationPeriodId: PropTypes.number,
});

const ContractDetails = ({ contract }) => {
  const contractType = getContractType(
    contract?.solicitation?.purchaseTypeCode,
  );

  const contractPeriod =
    contractType === CONTRACT_TYPE_MAP.SOP
      ? getContractPeriod(contract?.solicitationPeriod)
      : emDashUnicode;

  const contractYear =
    contractType === CONTRACT_TYPE_MAP.SOP
      ? getContractYear(contract)
      : emDashUnicode;

  return (
    <StandardFieldset className="contract-details">
      <StandardFieldsetRow>
        <StandardFieldsetRowCol
          colWidth={2}
          label="Internal contract number"
          data={contract?.contractNumber}
        />
        <StandardFieldsetRowCol
          colWidth={2}
          label="Formal contract number"
          data={contract?.formalContractNumber || emDashUnicode}
        />
        <StandardFieldsetRowCol
          colWidth={2}
          label="Solicitation uPIID"
          data={contract.solicitation?.solicitationNumber || emDashUnicode}
        />
        <StandardFieldsetRowCol
          colWidth={2}
          label="Contract year"
          data={contractYear}
        />
        <StandardFieldsetRowCol
          colWidth={2}
          label="Period"
          data={contractPeriod}
        />
        <StandardFieldsetRowCol
          colWidth={2}
          label="Awarded in GSA Fleet"
          data={contract.fleetAwarded ? 'Yes' : 'No'}
        />
        {/* <StandardFieldsetRowCol
        colWidth={2}
        label="Publish"
        data={contract.publishedInFleet}
      /> */}
      </StandardFieldsetRow>
    </StandardFieldset>
  );
};

ContractDetails.propTypes = {
  contract: contractShape.isRequired,
};

export default ContractDetails;
