import { useLazyQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import * as resolvers from '../../services/data-store';
import {
  GET_STANDARD_ITEM,
  UPDATE_STANDARD_ITEM,
} from '../../services/data-store';

export const StandardItemContext = createContext();

export const MODAL_MODES = {
  ADD: 'add',
  EDIT: 'edit',
  COPY: 'copy',
  CONFIRM_DELETE: 'confirm_delete',
  CONFIRM_BULK_DELETE: 'confirm_bulk_delete',
  CONFIRM_REPLACE: 'confirm_replace',
  EDIT_STANDARD_ITEM: 'edit_standard_item',
  CONFIRM_COPY: 'confirm_copy',
  CONFIRM_APPEND: 'confirm_append',
};

const initialState = {
  MODAL_MODES,
  errors: null,
  modalMode: null,
  showFormModal: false,
  standardItem: null,
  equipmentAssociation: null,
  selectedAssociations: [],
  multipleOptions: [],
  options: [],
  equipmentAssociationList: {
    rows: [],
    hasMore: false,
    count: 0,
  },
  copyAssociation: {},
  successMessage: '',
  errorMessage: '',
  selectAllSelected: false,
  clearCheckBox: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_SELECTALL_SELECTED':
      return { ...state, selectAllSelected: action.payload };
    case 'SET_CLEAR_CHECKBOX':
      return { ...state, clearCheckBox: action.payload };
    case 'SET_STANDARD_ITEM': {
      return { ...state, standardItem: action.payload };
    }
    case 'SET_EQUIPMENT_ASSOCIATION':
      return { ...state, equipmentAssociation: action.payload };
    case 'SET_EQUIPMENT_ASSOCIATION_LIST':
      return { ...state, equipmentAssociationList: action.payload };
    case 'SET_MODAL_MODE': {
      return { ...state, modalMode: action.payload };
    }
    case 'SET_FORM_MODAL': {
      return { ...state, showFormModal: action.payload };
    }
    case 'SET_EQUIPMENT_ASSOCIATION_SELECTIONS':
      return { ...state, selectedAssociations: action.payload };
    case 'SET_MULTIPLE_OPTIONS':
      return { ...state, multipleOptions: action.payload };
    case 'SET_OPTIONS':
      return { ...state, options: action.payload };
    case 'SET_EQUIPMENT_ASSOCIATION_COPY':
      return { ...state, copyAssociation: action.payload };
    case 'SET_SUCCESS_MESSAGE':
      return { ...state, successMessage: action.payload };
    case 'SET_ERROR': {
      return { ...state, errors: action.payload };
    }
    case 'SET_ERROR_MESSAGE': {
      return { ...state, errorMessage: action.payload };
    }
    case 'RESET': {
      return initialState;
    }
    default:
      throw new Error('Invalid action');
  }
};

function StandardItemProvider({ children, selected, ...props }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setError = (type, payload) => {
    dispatch({
      type: 'SET_ERROR',
      payload: { ...state.errors, [type]: payload },
    });
  };

  const setData = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const resetState = () => {
    setData('RESET');
  };

  useEffect(() => {
    if (selected?.id) {
      setData('SET_STANDARD_ITEM', selected);
    }
  }, [selected]);

  // Get Associations lazy.
  const [getEquipmentAssociations, { refetch }] = useLazyQuery(
    resolvers.GET_EQUIPMENT_ASSOCIATIONS,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: (requestError) => {
        setError(
          'GET_EQUIPMENT_ASSOCIATIONS',
          requestError.message ?? 'Unknown Error.',
        );
      },
      onCompleted: (responseData) => {
        if (responseData?.getEquipmentAssociations) {
          setData(
            'SET_EQUIPMENT_ASSOCIATION_LIST',
            responseData.getEquipmentAssociations,
          );
          setError('GET_EQUIPMENT_ASSOCIATIONS', '');
        }
      },
    },
  );

  // Get Multiple Options
  const [getMultipleOptions] = useLazyQuery(resolvers.GET_MULTIPLE_OPTIONS, {
    onError: (requestError) => {
      setError(
        'GET_MULTIPLE_OPTIONS',
        requestError.message ?? 'Unknown Error.',
      );
    },
    onCompleted: (multipleOptionsData) => {
      if (multipleOptionsData?.getMultipleOptions) {
        setData('SET_MULTIPLE_OPTIONS', multipleOptionsData.getMultipleOptions);
        setError('GET_MULTIPLE_OPTIONS', '');
      }
    },
  });

  // Get Options
  const [getOptions] = useLazyQuery(resolvers.GET_OPTIONS, {
    onError: (requestError) => {
      setError('GET_OPTIONS', requestError.message ?? 'Unknown Error.');
    },
    onCompleted: (optionsData) => {
      if (optionsData?.getOptions) {
        setData('SET_OPTIONS', optionsData.getOptions);
        setError('GET_OPTIONS', '');
      }
    },
  });

  // Add Association
  const [addEquipmentAssociation] = useMutation(
    resolvers.ADD_EQUIPMENT_ASSOCIATION,
    {
      onError: (requestError) => {
        setError(
          'ADD_EQUIPMENT_ASSOCIATION',
          requestError.message ?? 'Unknown Error.',
        );
        setData('SET_ERROR_MESSAGE', requestError?.message);
      },
      onCompleted: () => {
        refetch();
        const message = `You have successfully associated the equipment code to this standard item.`;
        setData('SET_SUCCESS_MESSAGE', message);
        setData('SET_FORM_MODAL', false);
        setData('SET_ERROR_MESSAGE', '');
        setError('ADD_EQUIPMENT_ASSOCIATION', '');
      },
    },
  );

  // Update Association
  const [updateEquipmentAssociation] = useMutation(
    resolvers.UPDATE_EQUIPMENT_ASSOCIATION,
    {
      onError: (requestError) => {
        setError(
          'UPDATE_EQUIPMENT_ASSOCIATION',
          requestError.message ?? 'Unknown Error.',
        );
        setData('SET_ERROR_MESSAGE', requestError?.message);
      },
      onCompleted: () => {
        setData('SET_FORM_MODAL', false);
        setData('SET_ERROR_MESSAGE', '');
        refetch();
        setData('SET_EQUIPMENT_ASSOCIATION', null);
        setError('UPDATE_EQUIPMENT_ASSOCIATION', '');
        const message = `You have successfully updated the equipment code association to this standard item.`;
        setData('SET_SUCCESS_MESSAGE', message);
      },
    },
  );

  // Delete Association
  const [deleteEquipmentAssociation] = useMutation(
    resolvers.DELETE_EQUIPMENT_ASSOCIATION,
    {
      onError: (requestError) => {
        setError(
          'DELETE_EQUIPMENT_ASSOCIATION',
          requestError.message ?? 'Unknown Error.',
        );
      },
      onCompleted: () => {
        setData('SET_FORM_MODAL', false);
        refetch();
        let message = '';
        if (state.selectedAssociations.length) {
          message = `You have successfully deleted ${state.selectedAssociations.length} Equipment Codes from Standard Item ${state.standardItem?.standardItemNumber}`;
        } else {
          message = `You have successfully deleted Equipment Code ${state.equipmentAssociation?.equipmentCode?.code} from Standard Item ${state.standardItem?.standardItemNumber}`;
        }
        setData('SET_SUCCESS_MESSAGE', message);
        setData('SET_EQUIPMENT_ASSOCIATION', null);
        setError('DELETE_EQUIPMENT_ASSOCIATION', '');
      },
    },
  );

  // Copy Association
  const [copyEquipmentAssociation] = useMutation(
    resolvers.COPY_EQUIPMENT_ASSOCIATIONS,
    {
      onError: (requestError) => {
        setError(
          'COPY_EQUIPMENT_ASSOCIATION',
          requestError.message ?? 'Unknown Error.',
        );
      },
      onCompleted: (copyResponse) => {
        if (copyResponse?.copyAssociations) {
          const { standardItem, copyAssociation } = state;

          const fromStandardItem = standardItem?.standardItemNumber;
          const toStandardItem = copyAssociation?.toStandardItem?.label;
          const selectedCount =
            copyAssociation?.associationsToCopy?.length ?? 0;

          const message = `You have successfully copied ${selectedCount} Equipment Codes from Standard Item ${fromStandardItem} to Standard Item ${toStandardItem}.`;

          // Reset
          setData('SET_SUCCESS_MESSAGE', message);
          setData('SET_FORM_MODAL', initialState.showFormModal);
          setData(
            'SET_EQUIPMENT_ASSOCIATION_COPY',
            initialState.copyAssociation,
          );
          setData('SET_MODAL_MODE', initialState.modalMode);
          setError('COPY_EQUIPMENT_ASSOCIATION', '');
          setData('SET_SELECTALL_SELECTED', false);
          setData('SET_CLEAR_CHECKBOX', true);
        }
      },
    },
  );

  const [getStandardItem, { loading: sinLoading }] = useLazyQuery(
    GET_STANDARD_ITEM,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted: (responseData) => {
        if (responseData?.getStandardItem) {
          setData('SET_STANDARD_ITEM', responseData.getStandardItem);
        }
      },
      onError: (responseError) => {
        setError((prev) => ({ ...prev, GET_STANDARD_ITEM: responseError }));
      },
    },
  );

  const [updateStandardItem] = useMutation(UPDATE_STANDARD_ITEM, {
    onError: (requestError) => {
      setError(
        'UPDATE_STANDARD_ITEM',
        requestError.message ?? 'Unknown Error.',
      );
    },
    onCompleted: (response) => {
      let message = '';
      if (response?.updateStandardItem?.standardItemNumber) {
        message = `Successfully updated Standard Item ${response.updateStandardItem.standardItemNumber} - ${response.updateStandardItem?.title}`;
      } else {
        message = `Successfully updated Standard Item ${state.standardItem?.standardItemNumber} - ${state.standardItem?.title}`;
      }
      setData('SET_SUCCESS_MESSAGE', message);
      setData('SET_FORM_MODAL', false);
      setError('UPDATE_STANDARD_ITEM', '');
      getStandardItem({
        variables: {
          filter: {
            operator: 'AND',
            value: [
              {
                operator: 'EQ',
                key: 'standardItemNumber',
                value: state.standardItem.standardItemNumber,
              },
              { operator: 'EQ', key: 'year', value: state.standardItem.year },
            ],
          },
        },
      });
    },
  });

  return (
    <StandardItemContext.Provider
      value={{
        ...state,
        setData,
        setError,
        resetState,
        getOptions,
        getMultipleOptions,
        getEquipmentAssociations,
        addEquipmentAssociation,
        updateEquipmentAssociation,
        deleteEquipmentAssociation,
        copyEquipmentAssociation,
        updateStandardItem,
        sinLoading,
        ...props, // This must be the last item as it's used to override functions and state.
      }}
    >
      {children}
    </StandardItemContext.Provider>
  );
}

export default StandardItemProvider;

StandardItemProvider.defaultProps = {
  selected: null,
};

StandardItemProvider.propTypes = {
  selected: PropTypes.shape(Object),
  children: PropTypes.element.isRequired,
};

// Custom hook that shorthands the context!
export const useStandardItem = () => useContext(StandardItemContext);
