import {
  ASSOCIATION_TYPE_CODE,
  INPUT_TYPE_CODE,
  INPUT_DROPDOWN_OTHER_OPTION,
  INPUT_DROPDOWN_DEFAULT_OPTION,
  CRITERIA_CODE,
  EC_CLARIFICATION_TYPE,
  EC_OPTION_TYPE,
  EC_CONFLICT_TYPE,
  EC_QUANTITY_REQUIRED_LIST,
  SI_MPG_TAG,
  SI_OCONUS_TAG,
  SI_GPTKM_TAG,
  EC_MPG_TAG,
  BASE_ENGINE_VIRTUAL_CODE,
  BASE_ENGINE_EC,
  CHARGE_TYPES,
  FAST_CHARGE_TYPE,
  DC_FAST_CHARGE_TYPES,
  DELIMITER,
  ENGINE_INPUT_MAP,
  number2string,
  string2number,
  string2bool,
  isEcTabReady,
  isEngineTabReady,
} from '../../../bid-dashboard/bid-line-details/provider/helpers';

export {
  ASSOCIATION_TYPE_CODE,
  INPUT_TYPE_CODE,
  INPUT_DROPDOWN_OTHER_OPTION,
  INPUT_DROPDOWN_DEFAULT_OPTION,
  CRITERIA_CODE,
  EC_CLARIFICATION_TYPE,
  EC_OPTION_TYPE,
  EC_CONFLICT_TYPE,
  EC_QUANTITY_REQUIRED_LIST,
  SI_MPG_TAG,
  SI_OCONUS_TAG,
  SI_GPTKM_TAG,
  EC_MPG_TAG,
  BASE_ENGINE_VIRTUAL_CODE,
  BASE_ENGINE_EC,
  CHARGE_TYPES,
  FAST_CHARGE_TYPE,
  DC_FAST_CHARGE_TYPES,
  DELIMITER,
  ENGINE_INPUT_MAP,
  number2string,
  string2number,
  string2bool,
  isEcTabReady,
  isEngineTabReady,
};

export const bool2string = (value) => {
  if (typeof value === 'boolean') return value ? 'Y' : 'N';
  if (typeof value === 'string') return value === '0' ? 'N' : 'Y';
  return '';
};
export const string2boolStr = (value) => {
  if (value === 'Y') return '1';
  if (value === 'N') return '0';
  return null;
};

export const getValueObj = (val) => ({
  oldValue: val ?? '',
  newValue: val ?? '',
});

export const getUpdateObj = (original, updates) =>
  Object.keys(updates).reduce((obj, key) => {
    return { ...obj, [key]: { ...original[key], newValue: updates[key] } };
  }, {});

export const getOriginalObj = (original, field) => {
  const fieldNames = field.split('.');
  const getFieldValue = (obj, i) => {
    if (i === fieldNames.length - 1) return obj[fieldNames[i]];
    return getFieldValue(obj[fieldNames[i]], i + 1);
  };
  return getFieldValue(original, 0);
};
export const getOriginalValue = (original, field) => {
  return getOriginalObj(original, field).newValue;
};
export const getOriginalOldValue = (original, field) => {
  return getOriginalObj(original, field).oldValue;
};

const MinReqFields = [
  { name: 'inputValue', apiName: 'value' },
  { name: 'comply', apiName: 'comply', converter: string2boolStr },
  { name: 'inputException', apiName: 'exception' },
  { name: 'inputExplanation', apiName: 'explanation' },
];

const relationConverter = (value, ecList) => ({
  operator: '$and',
  ecs: value.map((ec) => ({
    relatedEc: ec,
    relatedEcId: ecList.find((item) => item.equipmentCode === ec).id,
  })),
});
const includesConverter = (value, ecList) =>
  JSON.stringify(relationConverter(value, ecList));
const requiresConverter = (value, ecList) =>
  JSON.stringify({
    operator: '$or',
    relations: value.map((ecs) => relationConverter(ecs, ecList)),
  });
const OptEqFields = [
  { name: 'inputOptionType', apiName: 'optionType' },
  {
    name: 'inputMaxQuantity',
    apiName: 'maxQuantity',
    converter: string2number,
  },
  { name: 'inputUnitPrice', apiName: 'unitPrice', converter: string2number },
  {
    name: 'inputShipmentDays',
    apiName: 'additionalShippingDays',
    converter: string2number,
  },
  { name: 'inputException', apiName: 'exception' },
  { name: 'inputExplanation', apiName: 'explanation' },
  { name: 'includes', apiName: 'includes', converter: includesConverter },
  { name: 'excludes', apiName: 'excludes', converter: includesConverter },
  { name: 'requires', apiName: 'requires', converter: requiresConverter },
];

export const objEq = (a, b) => JSON.stringify(a) === JSON.stringify(b);
export const compareValue = (val1, val2) => {
  if (typeof val1 === 'object') return objEq(val1, val2);
  return val1 === val2;
};
const isValueChanged = (val) => !compareValue(val.oldValue, val.newValue);
const isMinReqItemChanged = (item) =>
  MinReqFields.some((field) => isValueChanged(item[field.name]));
const isOptEqItemChanged = (item) =>
  OptEqFields.some((field) => isValueChanged(item[field.name]));

const convertValue = (item, field, params) => {
  const val = item[field.name];
  if (!field.converter) return { ...val };
  return {
    oldValue: field.converter(val.oldValue, params),
    newValue: field.converter(val.newValue, params),
  };
};

/**
 * Helpers for EC - Minimum requriements and Optional equipment
 */
const getSaveObjHeader = (item, contractLineId) => {
  const ecCategory = `${item.categoryCode} - ${item.categoryTitle}`;
  const ecTitle = item.equipment;
  // existing line
  if (item.contractLineEcId)
    return {
      id: item.contractLineEcId,
      ecCategory,
      ecTitle,
      equipmentCode: item.equipmentCode,
    };
  // new line
  return {
    ecCategory,
    ecTitle,
    contractLineId,
    siEcAssociationId: item.id,
    equipmentCode: item.equipmentCode,
    inputType: item.inputTypeCode,
  };
};

export const getMinReqSaveObj = (cats, contractLineId) => {
  const items = [];
  cats.forEach((cat) =>
    items.push(...cat.data.filter((item) => isMinReqItemChanged(item))),
  );
  if (!items.length) return undefined;
  return items.map((item) => {
    const input = getSaveObjHeader(item, contractLineId);
    return MinReqFields.reduce((inputObj, field) => {
      if (isValueChanged(item[field.name]))
        return { ...inputObj, [field.apiName]: convertValue(item, field) };
      return inputObj;
    }, input);
  });
};

export const isMinReqItemReady = (input, comply) => {
  return !!input.newValue && !!comply.newValue;
};

export const getOptEqSaveObj = (cats, contractLineId, ecList) => {
  const items = [];
  cats.forEach((cat) =>
    items.push(...cat.data.filter((item) => isOptEqItemChanged(item))),
  );
  if (!items.length) return undefined;
  return items.map((item) => {
    const input = getSaveObjHeader(item, contractLineId);
    return OptEqFields.reduce((inputObj, field) => {
      if (isValueChanged(item[field.name]))
        return {
          ...inputObj,
          [field.apiName]: convertValue(item, field, ecList),
        };
      return inputObj;
    }, input);
  });
};

export const isOptEqItemReady = (
  { newValue: optionType },
  { newValue: maxQuantity },
  { newValue: unitPrice },
  quantityRequired,
  isAwardedInFleet,
) => {
  if (!optionType) return undefined;
  if (optionType === EC_OPTION_TYPE.na) return true;
  if (
    EC_QUANTITY_REQUIRED_LIST.includes(quantityRequired) &&
    isAwardedInFleet &&
    !maxQuantity
  )
    return false;
  return !(optionType === EC_OPTION_TYPE.standalone && unitPrice === '');
};

export const isInactiveOptionType = (optionType) =>
  !optionType.newValue || optionType.newValue === EC_OPTION_TYPE.na;

const parseClarification = (contractLineEc, type) =>
  getValueObj(
    contractLineEc?.contractLineEcClarification.find(
      (c) => c.clarificationType === type,
    )?.clarification,
  );

const parseConflict = (ecRelations, field, ecLookup) => {
  const lookupEC = (siecId) =>
    ecLookup.find((item) => item.siecId === siecId)?.ec ?? 'Unknown';
  const rows = ecRelations?.filter(
    (c) => c.relationShipCode === EC_CONFLICT_TYPE[field],
  );
  if (!rows?.length) return { oldValue: [], newValue: [] };

  // includes and excludes
  if (field !== 'requires') {
    const ecs = rows
      .map((r) => lookupEC(r.relatedSiEcAssociationId))
      .sort((a, b) => (a < b ? -1 : 1));
    return { oldValue: ecs, newValue: ecs };
  }

  // requires
  const groups = [...new Set(rows.map((r) => r.sequenceNumber).sort())];
  const reqs = groups.map((g) =>
    rows
      .filter((r) => r.sequenceNumber === g)
      .map((r) => lookupEC(r.relatedSiEcAssociationId)),
  );
  return { oldValue: reqs, newValue: reqs };
};

export const ecTitleLookup = (equipmentCode, ecList) => {
  const ec = ecList?.find((e) => e.equipmentCode === equipmentCode);
  return ec?.equipment || equipmentCode;
};

const processEcItem = (
  item,
  index,
  contractLineEcs,
  isAwardedInFleet,
  ecLookup,
) => {
  const {
    code,
    title,
    categoryCode,
    sequence,
    tags,
    quantityRequired,
  } = item.equipmentCode;
  const equipment = `${code} - ${title}`;

  const contractLineEc = contractLineEcs.find(
    (ec) => ec.siEcAssociationId === +item.id,
  );
  const inputValue = getValueObj(contractLineEc?.value);
  const inputException = parseClarification(
    contractLineEc,
    EC_CLARIFICATION_TYPE.exception,
  );
  const inputExplanation = parseClarification(
    contractLineEc,
    EC_CLARIFICATION_TYPE.explanation,
  );
  const inputOptionType =
    !isAwardedInFleet && !contractLineEc
      ? getValueObj(EC_OPTION_TYPE.na) // default to NA if legacy line ec does not exist
      : getValueObj(contractLineEc?.optionType);
  const inputMaxQuantity = getValueObj(contractLineEc?.maxQuantity?.toFixed(0));
  const inputUnitPrice = getValueObj(
    contractLineEc?.unitPrice == null
      ? ''
      : contractLineEc.unitPrice.toString(),
  );
  const inputShipmentDays = getValueObj(
    contractLineEc?.additionalShippingDays?.toFixed(0),
  );

  const comply = getValueObj(bool2string(contractLineEc?.comply));

  const commonData = {
    id: +item.id,
    contractLineEcId: contractLineEc ? +contractLineEc.id : undefined,
    categoryCode: categoryCode.code,
    categoryTitle: categoryCode.title,
    equipmentCode: code,
    equipment,
    catSequence: categoryCode.additionalProps?.sequence,
    sequence,
    associationTypeCode: item.associationTypeCode,
    inputExplanation,
    inputException,
  };
  const standardData =
    item.associationTypeCode === ASSOCIATION_TYPE_CODE.STANDARD
      ? {
          inputTypeCode: item.inputTypeCode || INPUT_TYPE_CODE.TEXT,
          lowerBound: item.lowerBound,
          upperBound: item.upperBound,
          preDefinedValue: item.preDefinedValue,
          unitCode: item.unitCode,
          criteriaCode: item.criteriaCode,
          associationText: item.associationText,
          inputValue,
          comply,
          ready: isMinReqItemReady(inputValue, comply),
        }
      : {};
  const optionalData =
    item.associationTypeCode === ASSOCIATION_TYPE_CODE.OPTIONAL
      ? {
          quantityRequired,
          inputTypeCode: 'OPT',
          inputOptionType,
          inputMaxQuantity,
          inputUnitPrice,
          inputShipmentDays,
          includes: parseConflict(
            contractLineEc?.contractLineEcRelation,
            'includes',
            ecLookup,
          ),
          excludes: parseConflict(
            contractLineEc?.contractLineEcRelation,
            'excludes',
            ecLookup,
          ),
          requires: parseConflict(
            contractLineEc?.contractLineEcRelation,
            'requires',
            ecLookup,
          ),
          hasMpgTag: tags.value.includes(EC_MPG_TAG),
          ready: isOptEqItemReady(
            inputOptionType,
            inputMaxQuantity,
            inputUnitPrice,
            quantityRequired,
            isAwardedInFleet,
          ),
        }
      : {};

  return {
    index,
    title: categoryCode.title,
    id: categoryCode.code,
    expanded: false,
    data: [{ ...commonData, ...standardData, ...optionalData }],
    tableData: [
      {
        iCat: index,
        iData: 0,
        id: commonData.id,
        categoryCode: commonData.categoryCode,
        equipment,
        sequence,
        isExpanded: false,
      },
    ],
  };
};

const categorizeData = (data, contractLineEcs, isAwardedInFleet) => {
  if (!data.length) return [];

  const ecLookup = data.map((siec) => ({
    siecId: Number(siec.id),
    ec: siec.equipmentCode.code,
  }));

  // sort category by sequence then title
  const sorted = data.sort(
    (
      { equipmentCode: { categoryCode: catA } },
      { equipmentCode: { categoryCode: catB } },
    ) => {
      if (catA.additionalProps?.sequence === catB.additionalProps?.sequence)
        return catA.title < catB.title ? -1 : 1;
      return catA.additionalProps?.sequence < catB.additionalProps?.sequence
        ? -1
        : 1;
    },
  );

  const categories = [
    processEcItem(sorted[0], 0, contractLineEcs, isAwardedInFleet, ecLookup),
  ];
  let index = 0;
  for (let i = 1; i < sorted.length; i += 1) {
    const item = processEcItem(
      sorted[i],
      index + 1,
      contractLineEcs,
      isAwardedInFleet,
      ecLookup,
    );
    if (item.id === categories[index].id) {
      categories[index].data.push(item.data[0]);
      categories[index].tableData.push({
        ...item.tableData[0],
        iCat: index,
        iData: categories[index].data.length - 1,
      });
    } else {
      categories.push(item);
      index += 1;
    }
  }

  categories.forEach((cat) => {
    // sort EC by sequence then title - reversed
    cat.tableData.sort((a, b) => {
      if (a.sequence === b.sequence) return a.equipment < b.equipment ? 1 : -1;
      return a.sequence < b.sequence ? 1 : -1;
    });
  });

  return categories;
};

/**
 * Helpers for Engine and Fuel data
 */
export const sortEngineECsFn = (a, b) => {
  if (a.catSequence === b.catSequence) return a.sequence < b.sequence ? 1 : -1;
  return a.catSequence < b.catSequence ? 1 : -1;
};

const processEngineMpgItem = (fuelType, type, engineMgps) => {
  const mpg = engineMgps.find(
    (m) => m.fuelType === fuelType && m.type === type,
  );
  return getValueObj(number2string(mpg?.value));
};

const processEngineMpg = (fuelType, engineMgps) => {
  const mpg = {};
  [
    { type: 'City', key: 'city' },
    { type: 'Highway', key: 'highway' },
    { type: 'Combined', key: 'combined' },
    { type: 'Grams/mi', key: 'gpmi' },
  ].forEach((item) => {
    mpg[`${item.key}Input`] = processEngineMpgItem(
      fuelType,
      item.type,
      engineMgps,
    );
  });
  return mpg;
};

export const getFuelInputMap = (fuelTypeCodeId, fuelTypes) => {
  if (!fuelTypeCodeId) return {};
  const fuelType = fuelTypes.find((ft) => fuelTypeCodeId - ft.id === 0);
  return ENGINE_INPUT_MAP.find((item) => fuelType?.code === item.code) ?? {};
};

const arrayConverter = (arr) => arr.join(DELIMITER);
const EngineFields = [
  { name: 'engineModel', apiName: 'engineModel' },
  {
    name: 'fuelTypeCodeInput',
    apiName: 'fuelTypeCodeId',
    converter: string2number,
  },
  {
    name: 'isLowGHGInput',
    apiName: 'isLowGHG',
    converter: string2bool,
  },
  { name: 'deliveryRegionType', apiName: 'deliveryRegionType' },
  {
    name: 'deliveryStatesInput',
    apiName: 'deliveryStates',
    converter: arrayConverter,
  },
  { name: 'isFuelDataUnknown', apiName: 'isFuelDataUnknown' },
  { name: 'cylindersInput', apiName: 'cylinders', converter: string2number },
  {
    name: 'engineLitresInput',
    apiName: 'engineLitres',
    converter: string2number,
  },
  { name: 'rangeInput', apiName: 'range', converter: string2number },
  {
    name: 'rangeElectricInput',
    apiName: 'rangeElectric',
    converter: string2number,
  },
  { name: 'explanation', apiName: 'explanation' },
];
const Mpgs = ['mpgC', 'mpgA', 'mpgE'];
const MpgFields = [
  { name: 'cityInput', apiName: 'city', converter: string2number },
  { name: 'highwayInput', apiName: 'highway', converter: string2number },
  { name: 'combinedInput', apiName: 'combined', converter: string2number },
  { name: 'gpmiInput', apiName: 'gpmi', converter: string2number },
];
const ChargingFields = [
  { name: 'typeInput', apiName: 'type', converter: arrayConverter },
  {
    name: 'isFastChargeCapableInput',
    apiName: 'isFastChargeCapable',
    converter: string2bool,
  },
  { name: 'bkwhInput', apiName: 'bkwh', converter: string2number },
];
const ChargingFcTypeFields = [
  { name: 'checked', apiName: 'checked' },
  { name: 'chargeOption', apiName: 'chargeOption' },
  { name: 'userEnteredType', apiName: 'userEnteredType' },
];

const getSaveEngineChargingObj = (charging, isDeleted) => {
  const chargingData = ChargingFields.reduce((inputObj, field) => {
    if (isDeleted || isValueChanged(charging[field.name]))
      return {
        ...inputObj,
        [field.apiName]: convertValue(charging, field),
      };
    return inputObj;
  }, {});

  const fcTypes = [];
  DC_FAST_CHARGE_TYPES.forEach((fcType) => {
    const fcTypeData = ChargingFcTypeFields.reduce((inputObj, field) => {
      if (isDeleted || isValueChanged(charging.fcTypes[fcType][field.name]))
        return {
          ...inputObj,
          [field.apiName]: convertValue(charging.fcTypes[fcType], field),
        };
      return inputObj;
    }, {});
    if (Object.keys(fcTypeData).length) fcTypes.push({ fcType, ...fcTypeData });
  });
  if (fcTypes.length) chargingData.fcTypes = fcTypes;

  return Object.keys(chargingData).length === 0
    ? {}
    : { charging: chargingData };
};

const getSaveEngineObjHeader = (item, contractLineId) => {
  const ecTitle = item.equipment;
  // existing line
  if (item.contractLineEngineId)
    return { id: +item.contractLineEngineId, ecTitle };
  // new line
  return {
    ecTitle,
    contractLineId,
    siEcAssociationId: item.id,
    isMetric: item.requiresOCONUS,
    isGPTKM: item.requiresGPTKM,
  };
};

export const getEngineSaveObj = (engines, contractLineId) => {
  const engineSaveObj = engines
    .filter((eng) => eng.contractLineEngineId != null || !eng.isDeleted)
    .map((eng) => {
      const isDeleted = !!eng.isDeleted;
      const inputHeader = getSaveEngineObjHeader(eng, contractLineId);

      const inputData = EngineFields.reduce((inputObj, field) => {
        if (isDeleted || isValueChanged(eng[field.name]))
          return {
            ...inputObj,
            [field.apiName]: convertValue(eng, field),
          };
        return inputObj;
      }, {});

      const mpgs = [];
      Mpgs.forEach((mpg) => {
        const mpgData = MpgFields.reduce((inputObj, field) => {
          if (isDeleted || isValueChanged(eng[mpg][field.name]))
            return {
              ...inputObj,
              [field.apiName]: convertValue(eng[mpg], field),
            };
          return inputObj;
        }, {});
        if (Object.keys(mpgData).length) {
          mpgs.push({ fuelType: mpg.substring(3), ...mpgData });
        }
      });

      if (mpgs.length) inputData.mpgs = mpgs;
      const chargingData = getSaveEngineChargingObj(eng.charging, isDeleted);

      return Object.keys(inputData).length || Object.keys(chargingData).length
        ? { ...inputHeader, isDeleted, ...inputData, ...chargingData }
        : null;
    })
    .filter((eng) => eng);
  return engineSaveObj.length ? engineSaveObj : undefined;
};

export const isEngineDataReady = (data, isAwardedInFleet) => {
  const isMpgReady = (mpg, isMpgE = false) =>
    Boolean(
      !isAwardedInFleet ||
        (mpg.cityInput.newValue &&
          mpg.highwayInput.newValue &&
          mpg.combinedInput.newValue &&
          (isMpgE || mpg.gpmiInput.newValue)),
    );
  const isFastChargeTypesReady = (fcTypes) => {
    const selected = Object.values(fcTypes).filter(
      (item) => item.checked.newValue,
    );
    return (
      selected.length > 0 &&
      selected.every(
        (item) =>
          item.chargeOption.newValue &&
          (item.fastChargeType.newValue !== 'Other' ||
            item.userEnteredType.newValue),
      )
    );
  };
  const isChargingReady = (charging) => {
    if (!isAwardedInFleet) return true;
    if (
      charging.typeInput.newValue.includes(FAST_CHARGE_TYPE) &&
      charging.isFastChargeCapableInput.newValue !== 'Y'
    )
      return false;
    return Boolean(
      charging.typeInput.newValue.length &&
        charging.bkwhInput.newValue &&
        charging.isFastChargeCapableInput.newValue &&
        (charging.isFastChargeCapableInput.newValue === 'N' ||
          isFastChargeTypesReady(charging.fcTypes)),
    );
  };

  if (!data.fuelTypeCodeInput.newValue) return false;
  if (
    isAwardedInFleet &&
    !data.requiresOCONUS &&
    (!data.deliveryRegionType.newValue ||
      (data.deliveryRegionType.newValue === 'Other' &&
        !data.deliveryStatesInput.newValue.length))
  )
    return false;

  const fuelMap = data.fuelInputMap;
  if (Object.keys(fuelMap).length === 0) return true;

  if (
    fuelMap.displacement &&
    !(data.cylindersInput.newValue && data.engineLitresInput.newValue)
  )
    return false;

  if (!isAwardedInFleet) return true; // rest of the fields are all optional

  if (
    data.requiresMPG &&
    !data.requiresOCONUS &&
    !data.isFuelDataUnknown.newValue &&
    ((fuelMap.mpgC && !isMpgReady(data.mpgC)) ||
      (fuelMap.mpgA && !isMpgReady(data.mpgA)) ||
      (fuelMap.mpgE && !isMpgReady(data.mpgE, true)))
  )
    return false;

  return Boolean(
    (!fuelMap.range ||
      !data.requiresMPG ||
      data.requiresOCONUS ||
      fuelMap.rangeE ||
      data.isFuelDataUnknown.newValue ||
      data.rangeInput.newValue) &&
      (!fuelMap.rangeE || data.rangeElectricInput.newValue) &&
      (!fuelMap.charging || isChargingReady(data.charging)),
  );
};

const getEmptyFcTypeObject = (fcType) => ({
  checked: getValueObj(false),
  fastChargeType: getValueObj(fcType),
  chargeOption: getValueObj(''),
  userEnteredType: getValueObj(''),
});

export const processEngineItem = (
  ecItem,
  contractLineEngines,
  isAwardedInFleet,
  fuelTypes,
  siTags,
) => {
  const engineData = contractLineEngines.find(
    (eng) =>
      eng.siEcAssociationId === ecItem.id ||
      (!eng.siEcAssociationId && ecItem.id === 0), // base engine NULL
  );
  const fuelInputMap = getFuelInputMap(engineData?.fuelTypeCodeId, fuelTypes);

  const getFastChargeTypes = (fcTypes = []) => {
    const result = {};
    DC_FAST_CHARGE_TYPES.forEach((fcType) => {
      const fcObj = fcTypes.find((fct) => fct.fastChargeType === fcType);
      result[fcType] = fcObj
        ? {
            checked: getValueObj(true),
            fastChargeType: getValueObj(fcType),
            chargeOption: getValueObj(fcObj.chargeOption),
            userEnteredType: getValueObj(fcObj.userEnteredType),
          }
        : getEmptyFcTypeObject(fcType);
    });
    return result;
  };

  const inputItem = {
    // control data
    id: ecItem.id,
    isDeleted: false,
    contractLineEngineId: engineData?.id,
    // associationTypeCode: ecItem.associationTypeCode,
    catSequence: ecItem.catSequence,
    sequence: ecItem.sequence,
    fuelInputMap,
    requiresMPG: siTags.includes(SI_MPG_TAG),
    requiresOCONUS: siTags.includes(SI_OCONUS_TAG),
    requiresGPTKM: siTags.includes(SI_GPTKM_TAG),
    isMetric: engineData?.isMetric,
    isGPTKM: engineData?.isGPTKM,
    // table row data
    equipment: ecItem.equipment,
    engineModel: getValueObj(engineData?.engineModel),
    isLowGHGInput: getValueObj(bool2string(engineData?.isLowGHG)),
    fuelTypeCodeInput: getValueObj(number2string(engineData?.fuelTypeCodeId)),
    ready: false,
    // delivery data
    deliveryRegionType: getValueObj(engineData?.deliveryRegionType),
    deliveryStatesInput: getValueObj(
      engineData?.deliveryStates
        ? engineData.deliveryStates.split(DELIMITER)
        : [],
    ),
    // fuel data - flag
    isFuelDataUnknown: getValueObj(!!engineData?.isFuelDataUnknown),
    // fuel data - engine displacement
    cylindersInput: getValueObj(number2string(engineData?.cylinders)),
    engineLitresInput: getValueObj(number2string(engineData?.engineLitres)),
    // fuel data - range
    rangeInput: getValueObj(number2string(engineData?.range)),
    rangeElectricInput: getValueObj(number2string(engineData?.rangeElectric)),
    // fuel data - charging blocks
    charging: {
      typeInput: getValueObj(
        engineData?.charging?.type
          ? engineData.charging.type.split(DELIMITER)
          : [],
      ),
      isFastChargeCapableInput: getValueObj(
        bool2string(engineData?.charging?.isFastChargeCapable),
      ),
      fcTypes: getFastChargeTypes(engineData?.charging?.fastChargeTypes),
      bkwhInput: getValueObj(number2string(engineData?.charging?.bkwh)),
    },
    // fuel data - mpg blocks
    mpgC: processEngineMpg('C', engineData?.mpgs || []),
    mpgA: processEngineMpg('A', engineData?.mpgs || []),
    mpgE: processEngineMpg('E', engineData?.mpgs || []),
    // optional clarification
    explanation: getValueObj(
      engineData?.contractLineEngineClarification[0]?.clarification,
    ),
  };
  inputItem.ready = isEngineDataReady(inputItem, isAwardedInFleet);
  return inputItem;
};

// eslint-disable-next-line
const processEngineData = (
  optionalEcData,
  contractLine,
  isAwardedInFleet,
  fuelTypes,
) => {
  const engineECs = [BASE_ENGINE_EC];
  optionalEcData.forEach((cat) => {
    engineECs.push(
      ...cat.data.filter(
        (ec) =>
          ec.hasMpgTag &&
          ec.inputOptionType.newValue === EC_OPTION_TYPE.standalone,
      ),
    );
  });
  return engineECs
    .map((item) =>
      processEngineItem(
        item,
        contractLine.contractLineEngines,
        isAwardedInFleet,
        fuelTypes,
        contractLine?.standardItem?.tags?.value || [],
      ),
    )
    .sort(sortEngineECsFn);
};

export const mapEngineDataToTable = ({ id, isDeleted, equipment }) => ({
  id,
  isDeleted,
  equipment,
  isExpanded: false,
});

// const getOptionalEcsForLegacy = (optEcs) => {
//   if (!contractLine || !siecData?.getEquipmentOptions?.length) return;

// };

/**
 * Helper function to generate provider data from equipment code data and
 * saved bid line data for 'Minimum requirements', 'Optional equipment',
 * and 'Engine and fuel' tabs
 */
export const processEquipmentCodesData = (
  equipmentCodeOptions, // ecs  siecData?.getEquipmentOptions
  contractLine,
  isAwardedInFleet,
  fuelTypes,
) => {
  // process min-req ec data
  // For legacy lines, show only existing min-req ec entries
  const fvsStandardEquipmentCodes = equipmentCodeOptions.filter(
    (item) =>
      item.equipmentCode &&
      item.associationTypeCode === ASSOCIATION_TYPE_CODE.STANDARD,
  );

  const legacyFvsStandardEquipmentCodes = fvsStandardEquipmentCodes.filter(
    (item) =>
      contractLine?.contractLineEcs?.find(
        (ec) => Number(ec?.siEcAssociationId) === Number(item?.id),
      ),
  );

  const standard = categorizeData(
    isAwardedInFleet
      ? fvsStandardEquipmentCodes
      : legacyFvsStandardEquipmentCodes,
    contractLine.contractLineEcs,
    isAwardedInFleet,
  );

  // process opt-eq ec data
  // For legacy lines, show all si-ec but default to NA if ec entry does not exist
  const fvsOptionalEquipmentCodes = equipmentCodeOptions.filter(
    (item) =>
      item.equipmentCode &&
      item.associationTypeCode === ASSOCIATION_TYPE_CODE.OPTIONAL,
  );

  const optional = categorizeData(
    fvsOptionalEquipmentCodes,
    contractLine.contractLineEcs,
    isAwardedInFleet,
  );

  // process engine data
  const engines = processEngineData(
    optional,
    contractLine,
    isAwardedInFleet,
    fuelTypes,
  );
  return { standard, optional, engines };
};

/** Helper function to get equipment code lookup list from optional equipment */
export const getOptionalEcList = (optionalECs) => {
  const ecList = [];
  optionalECs.forEach((cat) => {
    ecList.push(
      ...cat.data.map(({ id, inputOptionType, equipmentCode, equipment }) => ({
        id,
        inputOptionType,
        equipmentCode,
        equipment,
      })),
    );
  });
  return ecList;
};

/** Helper functions to check conflict dependencies */
export const hasConflicts = (original) =>
  ['includes', 'excludes', 'requires'].some(
    (field) => original[field].newValue?.length > 0,
  );

export const getConflictDependencies = (original, optionalECs) => {
  const isDependencyOf = (ecItem) => {
    if (original.id === ecItem.id) return false;
    if (ecItem.includes.newValue.includes(original.equipmentCode)) return true;
    if (ecItem.excludes.newValue.includes(original.equipmentCode)) return true;
    return ecItem.requires.newValue.some((ecs) =>
      ecs.includes(original.equipmentCode),
    );
  };
  const dependencies = [];
  optionalECs.forEach((cat) => {
    cat.data.forEach((ecItem) => {
      if (isDependencyOf(ecItem))
        dependencies.push({
          categoryTitle: ecItem.categoryTitle,
          equipment: ecItem.equipment,
        });
    });
  });
  return dependencies;
};
