import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage, SelectDropdown } from '@gsa/afp-component-library';

export const STATUSES = [
  {
    value: '',
    label: 'Select',
  },
  {
    value: 'Active',
    label: 'Active',
  },
  {
    value: 'Inactive',
    label: 'Inactive',
  },
];

const StatusInput = ({ disabled, isSinInCurrentYear }) => {
  const { errors, control } = useFormContext();

  if (disabled || isSinInCurrentYear) {
    STATUSES.push({
      value: 'Archived',
      label: 'Archived',
    });
  }

  const renderRequiredComponent = (value) => {
    return (
      <>
        {/* eslint-disable-next-line */}
        <h2 className="font-sans-xs" tabIndex="0" ariaLabel={value}>
          {value} <span className="usa-hint usa-hint--required">*</span>
        </h2>
      </>
    );
  };

  return (
    <>
      {renderRequiredComponent('Status')}
      <Controller
        id="si_form_status"
        name="status"
        defaultValue="Select"
        control={control}
        render={({ onChange, onBlur, value }) => (
          <SelectDropdown
            aria-label="status"
            id="si_form_status_sel"
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            options={STATUSES}
            disabled={disabled || isSinInCurrentYear}
          />
        )}
      />
      {errors?.status?.message && (
        <ErrorMessage>{errors.status.message}</ErrorMessage>
      )}
    </>
  );
};

StatusInput.propTypes = {
  disabled: PropTypes.bool,
  isSinInCurrentYear: PropTypes.bool,
};

StatusInput.defaultProps = {
  disabled: false,
  isSinInCurrentYear: false,
};

export default StatusInput;
