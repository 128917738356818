import React, { useCallback } from 'react';
import {
  RequiredFieldIndicator,
  FlexView,
  TextInput,
  SelectDropdown,
} from '@gsa/afp-component-library';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { useContractLine } from '../provider/contract-line-provider';
import LineMakeModelSection from './make-model/line-make-model';
import { enDashUnicode, emSpaceUnicode } from '../../../../utilities/constants';
import StartEndDateTimePicker from './start-end-date-time-picker';
import { ReadOnlyWrapper } from '../helpers/ec-components';

const LineSection = () => {
  const {
    contractLine,
    lineTabData,
    setLineTabData,
    canUpdateContract,
    canVendorUpdateContract,
    countries,
    isAwardedInFleet,
    isSOP,
  } = useContractLine();

  const validationSchema = yup.object().shape({
    originCountryId: yup.string().when([], {
      is: () => isSOP && isAwardedInFleet,
      then: yup.string().required('Country of origin is required.'),
      otherwise: yup.string().nullable(),
    }),
    quantity: yup
      .number()
      .transform((value) =>
        value === '' || Number.isNaN(value) ? undefined : value,
      )
      .moreThan(0, 'Allocation quantity must be 1 or more.'),
  });

  const canUpdateVehicleInfo = canUpdateContract || canVendorUpdateContract;

  const { control, register, errors } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      originCountryId:
        lineTabData.originCountryId || contractLine.originCountryId?.toString(),
      quantity: lineTabData.quantity || contractLine.quantity,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const countryOptions = [{ value: '', label: '-Select-' }];
  countryOptions.push(
    ...countries
      .map((country) => ({
        value: country.id,
        label: country.countryName,
      }))
      .sort((a, b) => {
        if (a.label === 'United States of America') return -1;
        if (b.label === 'United States of America') return 1;
        return a.label > b.label ? 1 : -1;
      }),
  );

  const genLabel = (label, required) => (
    <span className="text-bold" data-testid={label}>
      {label} {required && <RequiredFieldIndicator />}
    </span>
  );

  const estimatedTotalQuantity =
    contractLine.contractLineFinancialRef?.estimatedTotalQuantity;
  const estimatedFleetQuantity =
    contractLine.contractLineFinancialRef?.estimatedFleetQuantity;

  const onFieldBlur = useCallback(
    (fieldName, event) => {
      const newValue = event.target.value;
      const currentValue =
        (lineTabData[fieldName] ?? contractLine[fieldName]) || '';
      if (String(newValue) !== String(currentValue))
        setLineTabData({ ...lineTabData, [fieldName]: newValue });
    },
    [lineTabData],
  );

  return (
    <>
      <LineMakeModelSection />

      {isSOP && (
        <Controller
          name="originCountryId"
          control={control}
          render={({ value, onChange }) => {
            return (
              <div className="margin-top-4">
                <ReadOnlyWrapper wrapperType="grid" dataType="select">
                  <SelectDropdown
                    name="originCountryId"
                    label={genLabel('Country of origin', false)}
                    data-testid="country-of-origin"
                    value={value ?? ''}
                    errorMessage={errors.originCountryId?.message}
                    options={countryOptions}
                    onChange={onChange}
                    onBlur={(e) => {
                      if (!errors.originCountryId) {
                        onFieldBlur('originCountryId', e);
                      }
                    }}
                    required={isAwardedInFleet}
                    disabled={!canUpdateContract}
                  />
                </ReadOnlyWrapper>
              </div>
            );
          }}
        />
      )}

      <StartEndDateTimePicker />

      <div>
        <Controller
          name="quantity"
          control={control}
          render={({ value, onChange }) => {
            return (
              <div className="margin-top-4">
                <ReadOnlyWrapper>
                  <TextInput
                    name="quantity"
                    label={genLabel('Allocation', false)}
                    ref={register}
                    help="Enter a value if allocation is limited or leave blank for unlimited."
                    value={value === 0 ? emSpaceUnicode : value}
                    data-testid="quantity"
                    type="number"
                    min={0}
                    errorMessage={errors.quantity?.message}
                    onChange={onChange}
                    onBlur={(e) => {
                      if (!errors.quantity) {
                        onFieldBlur('quantity', e);
                      }
                    }}
                    disabled={!canUpdateVehicleInfo}
                  />
                </ReadOnlyWrapper>
              </div>
            );
          }}
        />
      </div>

      <FlexView className="margin-top-4">
        <div className="margin-right-8">
          <div className="text-bold">Estimated total quantity</div>
          <div>{estimatedTotalQuantity || enDashUnicode}</div>
        </div>
        <div>
          <div className="text-bold">Estimated fleet quantity</div>
          <div>{estimatedFleetQuantity || enDashUnicode}</div>
        </div>
      </FlexView>
    </>
  );
};

export default LineSection;
