import { useLazyQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useReducer } from 'react';
import { useHistory } from 'react-router';
import {
  CREATE_STANDARD_ITEM,
  DELETE_STANDARD_ITEM,
  GET_MULTIPLE_OPTIONS,
  STANDARD_ITEM_TYPEAHEAD_OPTIONS_SEARCH,
  STANDARD_ITEMS,
  UPDATE_STANDARD_ITEM,
  IS_SIN_IN_OPEN_SOLICITATION,
} from '../../services/data-store';
import { CUSTOM_ERROR } from '../../utilities/constants';
import {
  addOrReplaceMessageById,
  removeMessageById,
} from '../../utilities/messages-util';
import groupMultipleOptions from '../../utilities/options-helper';

export const StandardsContext = createContext({});

const initialState = {
  errors: null,
  modalMode: null,
  standardItemList: {
    rows: [],
    hasMore: false,
    count: 0,
  },
  selectedStandardItem: null,
  successMessage: '',
  errorMessage: '',
  selectedYearFilter: `${new Date().getFullYear()}`,
  selectedOptions: {
    fedStandardCode: '',
    vehicleType: '',
  },
  standardItemMessages: [],
  typeaheadData: null,
  isSinInOpenSolicitation: false,
};

const standardsReducer = (state, action) => {
  switch (action.type) {
    case 'SET_STANDARD_ITEMS': {
      return { ...state, standardItemList: { ...action.payload } };
    }
    case 'SET_SELECTED_ITEM': {
      return { ...state, selectedStandardItem: { ...action.payload } };
    }
    case 'SET_MODAL_MODE': {
      return { ...state, modalMode: action.payload };
    }
    case 'SET_FORM_MODAL': {
      return { ...state, showFormModal: action.payload };
    }
    case 'SET_MULTIPLE_OPTIONS': {
      return { ...state, multipleOptions: action.payload };
    }
    case 'SET_SELECTED_FILTER_YEAR':
      return { ...state, selectedYearFilter: action.payload };
    case 'SET_TYPEAHEAD_FILTER_DATA':
      return { ...state, typeaheadData: action.payload };
    case 'SET_SELECTED_VEH_HIERARCHY':
      return { ...state, selectedOptions: action.payload };
    case 'SET_SI_MESSAGES':
      return { ...state, standardItemMessages: action.payload };
    case 'SET_IS_SIN_IN_OPEN_SOLICITATION':
      return { ...state, isSinInOpenSolicitation: action.payload };
    case 'RESET': {
      return initialState;
    }
    default:
      throw new Error('Invalid action');
  }
};

function StandardsProvider({ children, ...props }) {
  const history = useHistory();
  const [state, dispatch] = useReducer(standardsReducer, initialState, () => {
    return initialState;
  });

  const setData = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const setStandardItemTableError = (error, showInModal) => {
    const message = {
      id:
        (error?.graphQLErrors &&
          error?.graphQLErrors[0]?.extensions?.exception?.name) ||
        'SI_ERROR',
      message: error?.message,
      showInModal,
      type: 'error',
    };
    setData(
      'SET_SI_MESSAGES',
      addOrReplaceMessageById(message, state.standardItemMessages),
    );
  };

  const removeStandardItemsMessageById = (id) => {
    setData(
      'SET_SI_MESSAGES',
      removeMessageById(id, state.standardItemMessages),
    );
  };

  const resetState = () => {
    setData('RESET');
  };

  // Get Multiple Options
  const [getStandardItemOptions] = useLazyQuery(GET_MULTIPLE_OPTIONS, {
    fetchPolicy: 'network-only',
    onError: (requestError) => {
      setStandardItemTableError(requestError, false);
    },
    onCompleted: (multipleOptionsData) => {
      if (multipleOptionsData?.getMultipleOptions) {
        const allOptions = groupMultipleOptions(
          multipleOptionsData?.getMultipleOptions,
        );
        allOptions?.vehicleType?.unshift({ label: 'Select', value: '' });
        allOptions?.fedStandardCode?.unshift({ label: 'Select', value: '' });

        setData('SET_MULTIPLE_OPTIONS', allOptions);
        removeStandardItemsMessageById('SI_ERROR');
        removeStandardItemsMessageById(CUSTOM_ERROR);
      }
    },
  });

  const [getStandardItems, { refetch }] = useLazyQuery(STANDARD_ITEMS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (requestError) => {
      setStandardItemTableError(requestError, false);
    },
    onCompleted: (responseData) => {
      if (responseData?.getStandardItems) {
        setData('SET_STANDARD_ITEMS', responseData.getStandardItems);
        removeStandardItemsMessageById('SI_ERROR');
        removeStandardItemsMessageById(CUSTOM_ERROR);
      }
    },
  });

  const [createStandardItem] = useMutation(CREATE_STANDARD_ITEM, {
    onError: (requestError) => {
      setStandardItemTableError(requestError, true);
    },
    onCompleted: (response) => {
      if (response?.addStandardItem) {
        const { year, standardItemNumber } = response?.addStandardItem;
        setData('SET_FORM_MODAL', false);
        removeStandardItemsMessageById('SI_ERROR');
        removeStandardItemsMessageById(CUSTOM_ERROR);
        const m = `Successfully created Standard Item ${response?.addStandardItem?.standardItemNumber} - ${response?.addStandardItem?.title}`;
        history.push(`/catalog/standard-item/${standardItemNumber}-${year}`, m);
      }
    },
  });

  const [updateStandardItem] = useMutation(UPDATE_STANDARD_ITEM, {
    onError: (requestError) => {
      setStandardItemTableError(requestError, true);
    },
    onCompleted: (response) => {
      refetch();
      removeStandardItemsMessageById('SI_ERROR');
      removeStandardItemsMessageById(CUSTOM_ERROR);
      let message = '';
      if (response?.updateStandardItem?.standardItemNumber) {
        message = `Successfully updated Standard Item ${response?.updateStandardItem?.standardItemNumber} - ${response?.updateStandardItem?.title}`;
      } else {
        message = `Successfully updated Standard Item ${state.selectedStandardItem?.standardItemNumber} - ${state.selectedStandardItem?.title}`;
      }
      const m = {
        id: 'SI_MESSAGE',
        message,
        type: 'success',
        closeable: true,
        showInModal: false,
      };
      setData(
        'SET_SI_MESSAGES',
        addOrReplaceMessageById(m, state.standardItemMessages),
      );

      setData('SET_FORM_MODAL', false);
    },
  });

  const [deleteStandardItem] = useMutation(DELETE_STANDARD_ITEM, {
    onError: (requestError) => {
      setStandardItemTableError(requestError, true);
    },
    onCompleted: () => {
      refetch();
      removeStandardItemsMessageById('SI_ERROR');
      removeStandardItemsMessageById(CUSTOM_ERROR);
      const m = {
        id: 'SI_MESSAGE',
        message: `You have successfully deleted a Standard Item`,
        type: 'success',
        closeable: true,
        showInModal: false,
      };
      setData(
        'SET_SI_MESSAGES',
        addOrReplaceMessageById(m, state.standardItemMessages),
      );
      setData('SET_FORM_MODAL', false);
    },
  });

  const [getFilterTypeAheadOptions] = useLazyQuery(
    STANDARD_ITEM_TYPEAHEAD_OPTIONS_SEARCH,
    {
      fetchPolicy: 'network-only',
      onError: (requestError) => {
        setStandardItemTableError(requestError, true);
      },
      onCompleted: ({ getStandardItemTypeAheadOptions }) => {
        if (getStandardItemTypeAheadOptions) {
          const { options, key } = getStandardItemTypeAheadOptions;
          setData('SET_TYPEAHEAD_FILTER_DATA', { field: key, values: options });
        }
      },
    },
  );

  const [isStandardItemInOpenSolicitation] = useLazyQuery(
    IS_SIN_IN_OPEN_SOLICITATION,
    {
      fetchPolicy: 'network-only',
      onError: (requestError) => {
        setStandardItemTableError(requestError, true);
      },
      onCompleted: ({ data: sinData }) => {
        setData(
          'SET_IS_SIN_IN_OPEN_SOLICITATION',
          sinData.isStandardItemInOpenSolicitation,
        );
      },
    },
  );

  return (
    <StandardsContext.Provider
      value={{
        ...state,
        setData,
        resetState,
        getStandardItemOptions,
        getStandardItems,
        createStandardItem,
        updateStandardItem,
        deleteStandardItem,
        getFilterTypeAheadOptions,
        removeStandardItemsMessageById,
        isStandardItemInOpenSolicitation,
        ...props,
      }}
    >
      {children}
    </StandardsContext.Provider>
  );
}

export default StandardsProvider;

StandardsProvider.defaultProps = {};

StandardsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useStandards = () => useContext(StandardsContext);
