import { useLazyQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { groupBy, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { GET_STANDARD_ITEMS_PUBLIC } from '../../services/data-store';
import {
  COUNT_OPEN_COMMENTING_PERIODS,
  GET_APPLICABLE_YEARS,
  GET_FVS_OPTIONS_FOR_YEAR,
  GET_VEHICLES_STANDARDS,
} from './fvs.gql';

export const FvsContext = createContext({});

const initialState = {
  fvsMessages: [],
  applicableFvsYears: [],
  vehicleClassificationOptions: {},
  selectedYear: new Date().getFullYear(),
  selectedOptions: {
    fedStandardCode: '',
    vehicleGroup: '',
    vehicleType: '',
    standardItemNumber: null,
  },
  vehicleStandard: null,
  commentingPeriodCount: null,
  commentingPeriodsData: {},
  standardItems: [],
  standardItemsAsOptions: [],
  standardItem: null,
  isCommentingAllowed4Fvs4User: false,
  fvsError: null,
  equipmentCodeQueryString: '',
};

const FvsReducer = (state, action) => {
  switch (action.type) {
    case 'SET_FVS_MESSAGES': {
      return { ...state, fvsMessages: action.payload };
    }
    case 'SET_FVS_YEARS': {
      return { ...state, applicableFvsYears: action.payload };
    }
    case 'SET_EQUIPMENT_CODE_QUERY_STRING': {
      return { ...state, equipmentCodeQueryString: action.payload };
    }
    case 'SET_FVS_OPTIONS': {
      return { ...state, vehicleClassificationOptions: action.payload };
    }
    case 'SET_SELECTED_YEAR': {
      return { ...state, selectedYear: action.payload };
    }
    case 'SET_SELECTED_OPTIONS': {
      return { ...state, selectedOptions: action.payload };
    }
    case 'SET_VEHICLE_STANDARDS': {
      return { ...state, vehicleStandard: { ...action.payload } };
    }
    case 'SET_COMMENTING_PERIOD_COUNT': {
      return { ...state, commentingPeriodCount: action.payload };
    }
    case 'SET_COMMENTING_PERIOD_DATA': {
      return { ...state, commentingPeriodsData: action.payload };
    }
    case 'SET_STANDARD_ITEMS': {
      return {
        ...state,
        standardItems: action.payload.standardItems,
        standardItemsAsOptions: action.payload.standardItemsAsOptions,
      };
    }
    case 'SET_SELECTED_STANDARD_ITEM': {
      if (action.payload === null) {
        return {
          ...state,
          standardItem: null,
        };
      }
      const si = state.standardItems.find(
        (s) => s.standardItemNumber === action.payload,
      );
      return {
        ...state,
        selectedOptions: {
          ...state.selectedOptions,
          standardItemNumber: action.payload,
        },
        standardItem: si,
      };
    }
    case 'SET_SELECTED_STANDARD_ITEM_OBJ': {
      return {
        ...state,

        standardItem: action.payload,
      };
    }
    case 'SET_COMMENTING_ALLOWED': {
      return { ...state, isCommentingAllowed4Fvs4User: action.payload };
    }
    default:
      return state;
  }
};

function FvsProvider({ children, ...props }) {
  const [state, dispatch] = useReducer(FvsReducer, initialState, () => {
    return initialState;
  });

  const location = useLocation();

  const setFvsContextData = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const addOrReplaceFvsMessageById = (message) => {
    const existingMessages = [...state.fvsMessages];
    const splicedArray = existingMessages.filter((m) => m.id !== message.id);
    setFvsContextData('SET_FVS_MESSAGES', [...splicedArray, message]);
  };

  const removeFvsMessageById = (id) => {
    const existingMessages = [...state.fvsMessages];
    const splicedArray = existingMessages.filter((m) => m.id !== id);
    setFvsContextData('SET_FVS_MESSAGES', [...splicedArray]);
  };

  const clearAllMessages = () => {
    setFvsContextData('SET_FVS_MESSAGES', []);
  };

  const setFvsError = (error) => {
    const message = {
      id: error?.graphQLErrors[0]?.extensions?.exception?.name || 'FVS_ERROR',
      message: error?.message,
      type: 'error',
    };
    addOrReplaceFvsMessageById(message);
  };

  const [getApplicableFvsYears] = useLazyQuery(GET_APPLICABLE_YEARS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (requestError) => {
      setFvsError(requestError);
    },
    onCompleted: (responseData) => {
      if (responseData?.getYearsForFVS) {
        setFvsContextData('SET_FVS_YEARS', [...responseData.getYearsForFVS]);
      }
    },
  });

  const sortByLabel = (items) => {
    return items?.sort((a, b) => {
      if (a.label > b.label) {
        return 1;
      }
      if (a.label < b.label) {
        return -1;
      }
      return 0;
    });
  };

  const [getFvsOptionsForYear] = useLazyQuery(GET_FVS_OPTIONS_FOR_YEAR, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (requestError) => {
      setFvsError(requestError);
    },
    onCompleted: (responseData) => {
      if (responseData?.getVehicleClassificationFVS) {
        const allOptionsGrouped = groupBy(
          responseData.getVehicleClassificationFVS,
          'uniqueKey',
        );
        const allOptions = {};

        if (allOptionsGrouped?.vehicleGroup) {
          const sortedVehGrps = sortByLabel(allOptionsGrouped.vehicleGroup);
          sortedVehGrps.unshift({ label: '', value: '' });
          allOptions.vehicleGroup = sortedVehGrps;
        }
        if (allOptionsGrouped?.vehicleType) {
          const sortedVehTypes = sortByLabel(allOptionsGrouped.vehicleType);
          sortedVehTypes.unshift({ label: '', value: '' });
          allOptions.vehicleType = sortedVehTypes;
        }
        if (allOptionsGrouped?.fedStandardCodes) {
          allOptions.fedStandardCode = [
            { label: '', value: '' },
            ...allOptionsGrouped.fedStandardCodes,
          ];
        }

        setFvsContextData('SET_FVS_OPTIONS', allOptions);
      }
    },
  });

  const [
    getVehicleStandards,
    { loading: vehicleStandardsLoading },
  ] = useLazyQuery(GET_VEHICLES_STANDARDS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (requestError) => {
      setFvsError(requestError);
    },
    onCompleted: (responseData) => {
      if (responseData?.getFederalStandards) {
        if (
          !responseData.getFederalStandards?.rows ||
          responseData.getFederalStandards?.rows.length < 1
        ) {
          const message = {
            id: 'FVS_ERROR',
            message: 'Not a valid Federal Standard Code.',
            type: 'error',
          };
          addOrReplaceFvsMessageById(message);
          setFvsContextData('SET_VEHICLE_STANDARDS', null);
          return;
        }
        setFvsContextData(
          'SET_VEHICLE_STANDARDS',
          responseData.getFederalStandards?.rows[0],
        );
        removeFvsMessageById('FVS_ERROR');
        if (!state?.selectedOptions.fedStandardCode) {
          setFvsContextData('SET_SELECTED_OPTIONS', {
            ...state?.selectedOptions,
            fedStandardCode:
              responseData.getFederalStandards?.rows[0].fedStandardCode,
          });
        }
        const fvsCurrentStatus =
          responseData.getFederalStandards?.rows[0].status;

        if (
          fvsCurrentStatus === 'AllCommentOpen' ||
          fvsCurrentStatus === 'PublicCommentOpen'
        ) {
          setFvsContextData('SET_COMMENTING_ALLOWED', true);
        }
      }
    },
  });

  const [getStandardItems, { loading: isSILoading }] = useLazyQuery(
    GET_STANDARD_ITEMS_PUBLIC,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: (requestError) => {
        setFvsError(requestError);
      },
      onCompleted: (responseData) => {
        if (responseData?.getStandardItems) {
          const standardItemsOptions = responseData?.getStandardItems?.rows.map(
            (option) => ({
              value: option.standardItemNumber,
              label: `${option.standardItemNumber} - ${option.title}`,
            }),
          );
          const options = [...standardItemsOptions];
          setFvsContextData('SET_STANDARD_ITEMS', {
            standardItems: responseData?.getStandardItems?.rows,
            standardItemsAsOptions: options,
          });
        }
      },
    },
  );

  const [
    getOpenCommentingPeriodCount,
    { loading: isCommentingPeriodDataLoading },
  ] = useLazyQuery(COUNT_OPEN_COMMENTING_PERIODS, {
    fetchPolicy: 'network-only',
    onError: (requestError) => {
      setFvsError(requestError);
    },
    onCompleted: (periodResponse) => {
      setFvsContextData(
        'SET_COMMENTING_PERIOD_DATA',
        periodResponse.getCountOpenCommentingPeriods,
      );
    },
  });

  useEffect(() => {
    getApplicableFvsYears();
  }, []);

  useEffect(() => {
    if (state?.selectedYear) {
      getFvsOptionsForYear({
        variables: {
          year: Number.parseInt(state.selectedYear, 10),
          includeOnlyCommentable: false,
        },
      });
      getOpenCommentingPeriodCount();
    }
  }, [state?.selectedYear]);

  useEffect(() => {
    setFvsContextData('SET_COMMENTING_ALLOWED', false);
    const isInDocPreviewPage = location?.pathname?.includes('/preview');
    if (
      state?.selectedYear &&
      state?.selectedOptions.fedStandardCode &&
      !isInDocPreviewPage
    ) {
      getVehicleStandards({
        variables: {
          filters: {
            operator: 'AND',
            value: [
              {
                operator: 'EQ',
                key: 'year',
                value: Number.parseInt(state.selectedYear, 10),
              },
              {
                operator: 'EQ',
                key: 'fedStandardCode',
                value: state.selectedOptions.fedStandardCode,
              },
            ],
          },
          limit: 1,
          offset: 0,
          order: 'fedStandardCode ASC',
        },
      });
    } else {
      setFvsContextData('SET_VEHICLE_STANDARDS', null);
    }
  }, [state?.selectedYear, state?.selectedOptions.fedStandardCode]);

  useEffect(() => {
    if (isEmpty(state?.selectedOptions?.fedStandardCode)) {
      return;
    }

    const filters = {
      operator: 'AND',
      value: [
        { operator: 'EQ', key: 'year', value: state.selectedYear },
        { operator: 'EQ', key: 'deletedAt', value: null },
        { operator: 'EQ', key: 'isActive', value: 1 },
      ],
    };

    if (!isEmpty(state?.selectedOptions?.fedStandardCode)) {
      filters.value.push({
        operator: 'EQ',
        key: 'fedStandardCode',
        value: state.selectedOptions.fedStandardCode,
      });
    }

    if (!isEmpty(state?.selectedOptions?.vehicleGroup)) {
      filters.value.push({
        operator: 'EQ',
        key: '$vehicleTypeCode.parent_code$',
        value: state.selectedOptions.vehicleGroup,
      });
    }

    if (!isEmpty(state?.selectedOptions?.vehicleType)) {
      filters.value.push({
        operator: 'EQ',
        key: 'vehicleType',
        value: state.selectedOptions.vehicleType,
      });
    }

    getStandardItems({
      variables: {
        filters,
        limit: 1000,
        offset: 0,
        order: `standardItemNumber ASC`,
      },
    });
  }, [
    state?.selectedYear,
    state?.selectedOptions?.fedStandardCode,
    state?.selectedOptions?.vehicleGroup,
    state?.selectedOptions?.vehicleType,
  ]);

  return (
    <FvsContext.Provider
      value={{
        ...state,
        addOrReplaceFvsMessageById,
        removeFvsMessageById,
        setFvsContextData,
        getVehicleStandards,
        vehicleStandardsLoading,
        isSILoading,
        getOpenCommentingPeriodCount,
        setFvsError,
        clearAllMessages,
        isCommentingPeriodDataLoading,
        ...props,
      }}
    >
      {children}
    </FvsContext.Provider>
  );
}

export default FvsProvider;

FvsProvider.defaultProps = {};

FvsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useFederalStandards = () => useContext(FvsContext);
