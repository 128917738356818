import React, { useMemo, useCallback, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Alert,
  Button,
  AFPTable,
  EmptyState,
} from '@gsa/afp-component-library';
import { useBidLineDetails } from '../provider/bid-line-details-provider';
import { formatPrice } from '../details/sections/price-info';
import { emDashUnicode } from '../../../../utilities/constants';
import VendorAssociatedLocationTable from '../details/sections/vendor-associated-location/vendor-associated-location-table';
import {
  getReadOnlyMinReqColumns,
  EcExpandedRow,
  getOptEqReadOnlyColumns,
} from '../provider/table-defs-ec';
import {
  getEngineReadOnlyColumns,
  EngineExpandedReadonlyRow,
} from '../provider/table-defs-engine';
import {
  getColorsReadOnlyColumns,
  getDocumentReadOnlyColumns,
  getPhotosReadonlyColumns,
} from '../provider/table-defs';
import CanIChangeBidData from '../../../bids/components/protect-bid-data-crud';
import ConnectedHighlightsReviewConfirm from './highlights-review-confirm';

const BidLineReviewAndSubmitDetail = ({
  editable,
  lineStatus,
  onSubmitClick,
  isSubmitting,
  bidId,
  solId,
  history,
}) => {
  const location = useLocation();
  const bidDetailRef = useRef(null);
  const minReqRef = useRef(null);
  const optEqRef = useRef(null);
  const engineRef = useRef(null);
  const colorRef = useRef(null);
  const docRef = useRef(null);

  useEffect(() => {
    switch (location.hash) {
      case '#bid-detail':
        bidDetailRef.current.scrollIntoView();
        break;
      case '#min-requirements':
        minReqRef.current.scrollIntoView();
        break;
      case '#opt-equipment':
        optEqRef.current.scrollIntoView();
        break;
      case '#engine':
        engineRef.current.scrollIntoView();
        break;
      case '#colors':
        colorRef.current.scrollIntoView();
        break;
      case '#documentation':
        docRef.current.scrollIntoView();
        break;
      default:
    }
  }, []);

  const {
    solicitation,
    bidLine,
    standardECTable,
    optionalECTable,
    engineTable,
    colors,
    documents,
    photos,
    gsaColors,
    vendorData,
    hasSomeHighlights,
    Countries,
    invalidConflictECs,
  } = useBidLineDetails();

  const {
    standardItem,
    scheduleLine,
    afpMake,
    afpModel,
    chassisMake,
    chassisModel,
  } = bidLine;
  const bidDetailLocation = `/catalog/solicitations/${solId}/bid-dashboard/${bidId}/bid-line-details/${bidLine.id}`;

  const bodyMakeModel = `${afpMake.makeName} ${afpModel.modelName}`;
  const chassedMakeModel =
    chassisMake && chassisModel
      ? `${chassisMake.makeName} ${chassisModel.modelName}`
      : emDashUnicode;

  const countryOfOrigin =
    Countries.find((country) => +country.id === bidLine.originCountryId)
      ?.countryName || emDashUnicode;

  const standardECReadOnlyColumns = getReadOnlyMinReqColumns();
  const standardECReadOnlySubrow = useMemo(
    // eslint-disable-next-line
    () => ({ row }) => <EcExpandedRow ecType="S" row={row} isReadOnly />,
    [],
  );

  const optionalEqReadOnlyColumns = getOptEqReadOnlyColumns();
  const optionalEqReadOnlySubrow = useMemo(
    // eslint-disable-next-line
    () => ({ row }) => <EcExpandedRow ecType="O" row={row} isReadOnly />,
    [],
  );

  const engineTableColumns = getEngineReadOnlyColumns();
  const engineTableSubrow = useMemo(
    // eslint-disable-next-line
    () => ({ row }) => <EngineExpandedReadonlyRow row={row} />,
    [],
  );

  const colorsReadonlyColumns = getColorsReadOnlyColumns(gsaColors);
  const documentReadOnlyColmns = getDocumentReadOnlyColumns();
  const photosReadonlyColumns = getPhotosReadonlyColumns(gsaColors);

  const photosWithRepresentativeImage = useMemo(() => {
    if (!photos || photos.length === 0) return [];
    if (photos.length === 1) {
      return [{ ...photos[0], representativeImage: true }];
    }
    const sortedPhotos = photos.sort((a, b) => a.orderNum - b.orderNum);
    sortedPhotos[0].representativeImage = true;
    return [...sortedPhotos];
  }, [photos]);

  const [showConfirmation, setShowConfirmation] = React.useState(false);

  const handleSubmit = useCallback(() => {
    if (hasSomeHighlights) {
      setShowConfirmation(true);
    } else {
      onSubmitClick();
    }
  }, [hasSomeHighlights, onSubmitClick]);

  return (
    <div>
      <div className="margin-top-4">
        <div className="display-flex flex-justify flex-align-end">
          <div className="title-xl text-ink">Line Item {scheduleLine}</div>
        </div>
        <div className="title-m text-base-dark margin-top-2">
          {standardItem?.title}
        </div>
      </div>
      <section className="margin-top-3">
        <div className="grid-row grid-gap">
          <div className="grid-col-3">
            <div className="text-bold">Solicitation</div>
            <div>{solicitation.solicitationNumber}</div>
          </div>
          <div className="grid-col-3">
            <div className="text-bold">Line Status</div>
            <div>{lineStatus}</div>
          </div>
          <div className="grid-col-3">
            <div className="text-bold">Vendor Name</div>
            <div>{vendorData?.name}</div>
          </div>
          <div className="grid-col-3">
            <div className="text-bold">SAM UEI</div>
            <div>{vendorData?.uniqueEntityIdentifier ?? emDashUnicode}</div>
          </div>
        </div>
      </section>
      <section className="margin-top-4">
        <div className="title-l">Review & submit</div>
        <hr />
        <p>
          Review your bid line information for accuracy. To make changes, use
          the Edit link for that section. You may also use the Back to bid input
          button to navigate back to the Bid details tab. Click submit bid
          button to send your bid line to GSA.
        </p>
      </section>
      <div className="bid-sections-wrapper">
        <section ref={bidDetailRef} className="margin-top-8">
          <header className="display-flex flex-justify flex-align-end">
            <div className="title-m-bold" style={{ fontWeight: 700 }}>
              Bid detail
            </div>
            {editable && (
              <Button
                data-testid="edit-bid-detail"
                variant="unstyled"
                label="Edit"
                leftIcon={{ name: 'edit' }}
                onClick={() => {
                  history.push({
                    pathname: bidDetailLocation,
                    state: {
                      tabIndex: 0,
                    },
                  });
                }}
              />
            )}
          </header>
          <hr />
          <section className="margin-top-2">
            <header className="title-s-caps text-primary">
              VEHICLE INFORMATION
            </header>

            <div className="display-flex flex-justify flex-wrap flex-align-start margin-top-1">
              <div>
                <div className="text-bold">Body Make and Model</div>
                <div>{bodyMakeModel}</div>
              </div>
              <div>
                <div className="text-bold">Chassis Make and Model</div>
                <div>{chassedMakeModel}</div>
              </div>
              <div>
                <div className="text-bold">Model Year</div>
                <div>{bidLine?.modelYear}</div>
              </div>
              <div>
                <div className="text-bold">Country of origin</div>
                <div>{countryOfOrigin}</div>
              </div>
            </div>
            <div className="margin-top-1">
              <div className="text-bold">
                Do you want this line to be a part of the 1122 program?
              </div>
              <div>{bidLine.isIn1122Program ? 'Yes' : 'No'}</div>
            </div>
            <div className="margin-top-1">
              <div className="text-bold">Shipping time</div>
              <div>
                {bidLine.shipmentDays
                  ? `${bidLine.shipmentDays} days`
                  : emDashUnicode}{' '}
              </div>
            </div>
            <div className="margin-top-1">
              <div className="text-bold">Justification</div>
              <div>{bidLine.shipmentJustification ?? emDashUnicode}</div>
            </div>
          </section>
          <section className="margin-top-2">
            <header className="title-s-caps text-primary">
              PRICE INFORMATION
            </header>
            <div className="display-flex flex-justify flex-wrap flex-align-start margin-top-1">
              <div>
                <div className="text-bold">Previous price</div>
                <div>{emDashUnicode}</div>
              </div>
              <div>
                <div className="text-bold">Current price</div>
                <div>$ {formatPrice(bidLine.unitPrice)}</div>
              </div>
              <div>
                <div className="text-bold">Net invoice price</div>
                <div>$ {formatPrice(bidLine.invoicePrice)}</div>
              </div>
              <div>
                <div className="text-bold">Net MSRP</div>
                <div>$ {formatPrice(bidLine.msrp)}</div>
              </div>
              <div>
                <div className="text-bold">AS&E unit price</div>
                <div>$ {formatPrice(bidLine.asePrice)}</div>
              </div>
              <div>
                <div className="text-bold">Destination price</div>
                <div>$ {formatPrice(bidLine.destinationCharge)}</div>
              </div>
              <div>
                <div className="text-bold">Allocation</div>
                <div>{bidLine.quantity}</div>
              </div>
              <div>
                <div className="text-bold">Estimated quantity</div>
                <div>
                  {bidLine?.solicitationLine?.estimatedQty || emDashUnicode}
                </div>
              </div>
              <div>
                <div className="text-bold">Total price</div>
                <div>
                  ${' '}
                  {formatPrice(
                    bidLine?.solicitationLine?.estimatedQty * bidLine.unitPrice,
                  )}
                </div>
              </div>
            </div>
          </section>
          <section className="margin-top-2">
            <header className="title-s-caps text-primary">
              Final assembly point/inspection point
            </header>
            <div className="margin-top-1">
              <VendorAssociatedLocationTable isReadOnly />
            </div>
          </section>
          <section className="margin-top-2">
            <header className="title-s-caps text-primary">
              Clarifications
            </header>
            <div className="margin-top-1">
              {bidLine.clarifications?.filter(
                (c) => c.clarificationType === 'C',
              )?.length > 0
                ? bidLine.clarifications
                    ?.filter((c) => c.clarificationType === 'C')
                    ?.map((item) => {
                      return <p key={item.id}>{item.clarification}</p>;
                    })
                : emDashUnicode}
            </div>
          </section>
          <section className="margin-top-2">
            <header className="title-s-caps text-primary">
              Technical exceptions
            </header>
            <div className="margin-top-1">
              {bidLine.clarifications?.filter(
                (c) => c.clarificationType === 'E',
              )?.length > 0
                ? bidLine.clarifications
                    ?.filter((c) => c.clarificationType === 'E')
                    ?.map((item) => {
                      return <p key={item.id}>{item.clarification}</p>;
                    })
                : emDashUnicode}
            </div>
          </section>
        </section>
        <section ref={minReqRef} className="margin-top-8">
          <header className="display-flex flex-justify flex-align-end">
            <div className="title-m-bold" style={{ fontWeight: 700 }}>
              Minimum requirements
            </div>
            {editable && (
              <Button
                data-testid="edit-min-req"
                variant="unstyled"
                label="Edit"
                leftIcon={{ name: 'edit' }}
                onClick={() => {
                  history.push({
                    pathname: bidDetailLocation,
                    state: {
                      tabIndex: 1,
                    },
                  });
                }}
              />
            )}
          </header>
          <hr />
          <p>{bidLine.standardItem?.content?.content}</p>
          {standardECTable.map((item) => {
            return (
              <section className="margin-top-4" key={item.id}>
                <header className="title-s-caps text-primary">
                  {item.title}
                </header>
                <div className="margin-top-1">
                  <AFPTable
                    fullWidth
                    columns={standardECReadOnlyColumns}
                    data={item.data}
                    renderRowSubComponent={standardECReadOnlySubrow}
                  />
                </div>
              </section>
            );
          })}
        </section>
        <section ref={optEqRef} className="margin-top-8">
          <header className="display-flex flex-justify flex-align-end">
            <div className="title-m-bold" style={{ fontWeight: 700 }}>
              Optional equipment
            </div>
            {editable && (
              <Button
                data-testid="edit-opt-eq"
                variant="unstyled"
                label="Edit"
                leftIcon={{ name: 'edit' }}
                onClick={() => {
                  history.push({
                    pathname: bidDetailLocation,
                    state: {
                      tabIndex: 2,
                    },
                  });
                }}
              />
            )}
          </header>
          <hr />
          {editable && invalidConflictECs.length > 0 && (
            <div className="margin-bottom-4">
              <Alert type="error">
                The following equipment codes have contradicting conflicts:{' '}
                <span className="text-bold">
                  {invalidConflictECs.map(({ rootEc }) => rootEc).join(', ')}
                </span>
                . Please resolve the issue before submitting the bid line.
              </Alert>
            </div>
          )}
          {optionalECTable.map((item) => {
            return (
              <section className="margin-top-4" key={item.id}>
                <header className="title-s-caps text-primary">
                  {item.title}
                </header>
                <div className="margin-top-1">
                  <AFPTable
                    fullWidth
                    columns={optionalEqReadOnlyColumns}
                    data={item.data}
                    renderRowSubComponent={optionalEqReadOnlySubrow}
                  />
                </div>
              </section>
            );
          })}
        </section>

        <section ref={engineRef} className="margin-top-8">
          <div className="margin-bottom-8 bid-line-item-details">
            <header className="display-flex flex-justify flex-align-end">
              <div className="title-m-bold" style={{ fontWeight: 700 }}>
                Engine and fuel
              </div>
              {editable && (
                <Button
                  data-testid="edit-engine-fuel"
                  variant="unstyled"
                  label="Edit"
                  leftIcon={{ name: 'edit' }}
                  onClick={() => {
                    history.push({
                      pathname: bidDetailLocation,
                      state: {
                        tabIndex: 3,
                      },
                    });
                  }}
                />
              )}
            </header>
            <hr />
            <AFPTable
              fullWidth
              columns={engineTableColumns}
              data={engineTable}
              renderRowSubComponent={engineTableSubrow}
            />
            {!engineTable.length && (
              <div className="table-empty-state">
                <EmptyState bottomText="Engine and fuel not available" />
              </div>
            )}
          </div>
        </section>

        <section ref={colorRef} className="margin-top-8">
          <header className="display-flex flex-justify flex-align-end">
            <div className="title-m-bold" style={{ fontWeight: 700 }}>
              Colors
            </div>
            {editable && (
              <Button
                data-testid="edit-colors"
                variant="unstyled"
                label="Edit"
                leftIcon={{ name: 'edit' }}
                onClick={() => {
                  history.push({
                    pathname: bidDetailLocation,
                    state: {
                      tabIndex: 4,
                    },
                  });
                }}
              />
            )}
          </header>
          <hr />
          <AFPTable
            fullWidth
            columns={colorsReadonlyColumns}
            data={colors}
            defaultSort={[['vendorColorName', 'asc']]}
          />
          {!colors.length && (
            <div className="table-empty-state">
              <EmptyState bottomText="Add colors to the table" />
            </div>
          )}
        </section>

        <section ref={docRef} className="margin-top-8">
          <header className="display-flex flex-justify flex-align-end">
            <div className="title-m-bold" style={{ fontWeight: 700 }}>
              Documentation
            </div>
            {editable && (
              <Button
                data-testid="edit-documentation"
                variant="unstyled"
                label="Edit"
                leftIcon={{ name: 'edit' }}
                onClick={() => {
                  history.push({
                    pathname: bidDetailLocation,
                    state: {
                      tabIndex: 5,
                    },
                  });
                }}
              />
            )}
          </header>
          <hr />
          <header className="title-s-caps text-primary margin-top-4">
            documents
          </header>
          <AFPTable
            fullWidth
            columns={documentReadOnlyColmns}
            data={documents}
          />
          {documents.length === 0 && (
            <div className="table-empty-state">
              <EmptyState bottomText="No documents available" />
            </div>
          )}
          <header className="title-s-caps text-primary margin-top-4">
            photos
          </header>
          <AFPTable
            fullWidth
            columns={photosReadonlyColumns}
            data={photosWithRepresentativeImage}
            defaultSort={[['orderNum', 'asc']]}
          />
          {photosWithRepresentativeImage.length === 0 && (
            <div className="table-empty-state">
              <EmptyState bottomText="No photos available" />
            </div>
          )}
        </section>
      </div>
      <div className="margin-top-8 margin-bottom-8">
        {editable && (
          <>
            <Button
              data-testid="bidline-bottom-back-btn"
              onClick={() => {
                history.push(bidDetailLocation);
              }}
              label="Back to bid input"
              variant="outline"
              className="margin-right-2"
              disabled={isSubmitting}
            />
            <CanIChangeBidData>
              {lineStatus !== 'Submitted' && (
                <Button
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  label={isSubmitting ? 'Submitting...' : 'Submit bid'}
                />
              )}
              {showConfirmation && (
                <ConnectedHighlightsReviewConfirm
                  isOpen={showConfirmation}
                  onClose={() => setShowConfirmation(false)}
                  onConfirm={() => {
                    setShowConfirmation(false);
                    onSubmitClick();
                  }}
                />
              )}
            </CanIChangeBidData>
          </>
        )}
      </div>
    </div>
  );
};

BidLineReviewAndSubmitDetail.propTypes = {
  editable: PropTypes.bool.isRequired,
  lineStatus: PropTypes.string.isRequired,
  onSubmitClick: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  bidId: PropTypes.string.isRequired,
  solId: PropTypes.string.isRequired,
  history: PropTypes.shape(Object).isRequired,
};

export default BidLineReviewAndSubmitDetail;
