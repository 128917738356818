import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compact } from 'lodash';
import { useMutation } from '@apollo/client';
import {
  Alert,
  AccordionItem,
  Button,
  AFPTable,
} from '@gsa/afp-component-library';
import { useBidLineDetails } from '../provider/bid-line-details-provider';
import { AccordionTitle } from '../components/tags';
import {
  getHandelToggle,
  getToggleAccordion,
} from '../components/accordion-helpers';
import { getOptEqColumns, EcExpandedRow } from '../provider/table-defs-ec';
import {
  EC_OPTION_TYPE,
  isInactiveOptionType,
  deleteEngineLine,
  addEngineLine,
  isOptEqItemReady,
} from '../provider/helpers';
import { AUTO_SAVE_BID_LINE_EC } from '../provider/queries';
import EditConflictModal from './edit-conflict-modal';
import '../bid-line-details.scss';
import { TAB_KEY } from '../copy-line-item/helpers';

const OptionalEquipment = ({ openCopyModal }) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const {
    bidLine,
    optionalECs,
    optionalECTable: items,
    setOptionalECTable: setItems,
    setOptionalEC,
    invalidConflictECs,
    setEngineData,
    setEngineTable,
    hasSomeHighlightByRowIds,
    isAdmin,
  } = useBidLineDetails();

  const toggleAccordion = getToggleAccordion(setItems);
  const handleToggle = getHandelToggle(setItems);

  const [autoSaveBidLineEC] = useMutation(AUTO_SAVE_BID_LINE_EC);

  const autoSave = async (original, updates) => {
    const parseTextInput = (inputValue) => {
      return inputValue || null;
    };
    const parseNumberInput = (inputValue) => {
      return inputValue ? parseFloat(inputValue) : null;
    };
    const input = {};
    if (original.bidLineEcId) {
      // existing line
      input.id = original.bidLineEcId;
    } else {
      // new line
      input.bidLineId = +bidLine.id;
      input.associationId = original.id;
      input.associationTypeCode = original.associationTypeCode;
      input.equipmentCode = original.equipmentCode;
      input.inputType = original.inputTypeCode;
    }
    if (updates.inputOptionType !== undefined)
      input.optionType = parseTextInput(updates.inputOptionType);
    if (updates.inputMaxQuantity !== undefined)
      input.maxQuantity = parseNumberInput(updates.inputMaxQuantity);
    if (updates.inputUnitPrice !== undefined)
      input.unitPrice = parseNumberInput(updates.inputUnitPrice);
    if (updates.inputShipmentDays !== undefined)
      input.shipmentDays = parseNumberInput(updates.inputShipmentDays);
    if (updates.inputExplanation !== undefined)
      input.explanation = parseTextInput(updates.inputExplanation);
    if (updates.inputException !== undefined)
      input.exception = parseTextInput(updates.inputException);

    // for admin user, return the original bid-line-ec id
    // this prevents the auto-save but still allows the UI to update
    if (isAdmin) {
      return { bidLineEcId: original.bidLineEcId };
    }

    return autoSaveBidLineEC({ variables: { input } })
      .then(({ data }) => ({ bidLineEcId: data.autoSaveBidLineEC }))
      .catch((error) => ({ error }));
  };

  const updateItem = async (original, data, table) => {
    // update UI states
    const newLine = { ...original, ...data };
    const ready = isOptEqItemReady(
      newLine.inputOptionType,
      newLine.inputMaxQuantity,
      newLine.inputUnitPrice,
      newLine.quantityRequired,
    );
    setOptionalEC(original, { data: { ...data, ready }, table });

    // auto save updates
    const { bidLineEcId, error } = await autoSave(original, data);

    // roll back UI states if autosave fails
    if (error) {
      setOptionalEC(original, { data: original });
      // global gql API error Alert automatically shown
      return;
    }

    // update bidLineEcId if new line is successfully added
    if (bidLineEcId !== original.bidLineEcId)
      setOptionalEC(original, { data: { bidLineEcId } });

    // update data rows in the "Engine and fuel" tab
    if (data.inputOptionType !== undefined && original.hasMpgTag) {
      if (original.inputOptionType === EC_OPTION_TYPE.standalone)
        deleteEngineLine(original.id, setEngineData, setEngineTable);
      else if (data.inputOptionType === EC_OPTION_TYPE.standalone)
        addEngineLine(
          original,
          setEngineData,
          setEngineTable,
          bidLine.standardItem.tags.value,
        );
    }
  };

  const onUpdate = (original, field, value) => {
    let addInput = {};
    let tableUpdate = null;
    switch (field) {
      case 'inputOptionType':
        if (original.inputOptionType !== value) {
          if (value === EC_OPTION_TYPE.standard)
            addInput = { inputUnitPrice: '0' };
          else if (isInactiveOptionType(value)) {
            addInput = {
              inputMaxQuantity: '',
              inputUnitPrice: '',
              inputShipmentDays: '',
              inputExplanation: '',
              inputException: '',
            };
            tableUpdate = { isExpanded: false };
          }
          updateItem(
            original,
            { inputOptionType: value, ...addInput },
            tableUpdate,
          );
        }
        break;
      default:
        // 'inputUnitPrice', 'inputMaxQuantity', 'inputShipmentDays',
        // 'inputExplanation' and 'inputException',
        if (original[field] !== value) {
          updateItem(original, { [field]: value });
        }
    }
  };

  const onCloseModal = () => setSelectedRow(null);

  const onActionClick = (_action, row) => {
    // open conflict modal
    setSelectedRow(row);
  };

  const columns = useMemo(() => getOptEqColumns(onUpdate, onActionClick), [
    onUpdate,
  ]);
  const subrow = useMemo(
    // eslint-disable-next-line
    () => ({ row }) => (
      <EcExpandedRow
        ecType="O"
        row={row}
        onUpdate={onUpdate}
        onActionClick={onActionClick}
      />
    ),
    [onUpdate, onActionClick],
  );

  return (
    <div className="margin-bottom-8 bid-line-item-details">
      <div className="display-flex flex-row flex-justify flex-align-end margin-bottom-4">
        <h3>Optional equipment</h3>
        <div>
          <Button
            variant="unstyled"
            label="Open/close all"
            leftIcon={{
              name: 'unfold_more',
            }}
            onClick={toggleAccordion}
          />
          <Button
            variant="outline"
            label="Copy optional equipment"
            leftIcon={{
              name: 'content_copy',
            }}
            className="margin-left-2"
            onClick={() => openCopyModal(TAB_KEY.optionalEquipment)}
          />
        </div>
      </div>

      {selectedRow && (
        <EditConflictModal row={selectedRow} onClose={onCloseModal} />
      )}

      {invalidConflictECs.length > 0 && (
        <div className="margin-bottom-4">
          <Alert type="error">
            The following equipment codes have contradicting conflicts:{' '}
            <span className="text-bold">
              {invalidConflictECs.map(({ rootEc }) => rootEc).join(', ')}
            </span>
            . Please resolve the issue before submitting the bid line.
          </Alert>
        </div>
      )}

      {items.map((item, i) => {
        const ecs = optionalECs[item.index].data;
        const bidLineEcIds = ecs.map((ec) => ec.bidLineEcId);
        const bidLineEcRelIds = ecs.flatMap((ec) => {
          const relIds = compact([
            ec.includes.id,
            ec.excludes.id,
            ec.requires.id,
          ]);
          return relIds;
        });
        const clarificationIds = bidLine?.equipmentCodes
          ?.filter((c) => bidLineEcIds.includes(c.id))
          .flatMap((ec) =>
            ec.clarifications
              ?.filter((c) => c.clarificationType === 'C')
              ?.map((c) => c.id),
          );
        const hasSomeClirificationHighlights = hasSomeHighlightByRowIds(
          'optional',
          'bid_line_ec_clarification',
          clarificationIds,
        );
        const hasSomeOptEqEcHighlights = hasSomeHighlightByRowIds(
          'optional',
          'bid_line_ec',
          bidLineEcIds,
        );
        const hasSomeConflictsHighlights = hasSomeHighlightByRowIds(
          'optional',
          'bid_line_ec_relation',
          bidLineEcRelIds,
        );
        return (
          <AccordionItem
            key={item.id}
            id={item.id}
            title={
              <AccordionTitle
                title={item.title}
                // eslint-disable-next-line
                isReady={ecs.every((row) => row.ready)}
                isHighlighted={
                  hasSomeOptEqEcHighlights ||
                  hasSomeConflictsHighlights ||
                  hasSomeClirificationHighlights
                }
              />
            }
            expanded={item.expanded}
            content={
              item.expanded && (
                <div className="padding-top-1">
                  <AFPTable
                    fullWidth
                    columns={columns}
                    data={item.data}
                    renderRowSubComponent={subrow}
                  />
                </div>
              )
            }
            handleToggle={() => handleToggle(i)}
          />
        );
      })}
    </div>
  );
};

OptionalEquipment.propTypes = {
  openCopyModal: PropTypes.func.isRequired,
};

export default OptionalEquipment;
