import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useQuery, useMutation } from '@apollo/client';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';

import {
  GET_CONTRACT_LINE_BY_ID,
  GET_CONTRACT_LINE_VERSION_WITH_HISTORY,
  GET_EQUIPMENT_OPTIONS,
  GET_GSA_CONSTANTS,
  SAVE_CONTRACT_LINE_BY_ID,
} from './query';
import { GET_VENDOR_LOCATIONS_BY_TYPES } from '../../../../utilities/feature-toggle/get-vendors-query';
import {
  processEquipmentCodesData,
  mapEngineDataToTable,
  processEngineItem,
  sortEngineECsFn,
} from '../helpers/ec-helpers';
import useDocumentationTab from './use-documentation-tab';
import ReviewItemError from '../../contract-line/review-item-error';
import { OPERATIONS, SUBJECTS } from '../../../../utilities/constants';
import { isContractSOP } from '../../components/contract-helpers';
import { getInvalidConflictECs } from '../optional-equipment/conflicts-helpers';

const useVendorLocations = (vendorId) => {
  const vendorLocationQueryResult = useQuery(GET_VENDOR_LOCATIONS_BY_TYPES, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      vendorId,
      locationTypeCodes: ['AsmPoint', 'InspPoint'],
    },
    skip: !vendorId,
  });

  return vendorLocationQueryResult;
};

export const ContractLineContext = createContext({});

export const ContractLineProvider = ({ children }) => {
  const { contractHeaderId, contractLineId, versionNumber } = useParams();

  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const tabIndexFromQueryParams = parseInt(queryParams.tabIndex, 10);
  const initialTabIndex =
    location.state?.tabIndex || tabIndexFromQueryParams || 0;
  const [tabIndex, setTabIndex] = useState(initialTabIndex);
  const [alert, setAlert] = useState(null);

  const [gsaColors, setGsaColors] = useState([]);
  const [fuelTypes, setFuelTypes] = useState([]);
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);

  const [lineTabData, setLineTabData] = useState({});
  const [makeAvailableForOrdering, setMakeAvailableForOrdering] = useState(
    null,
  );
  const detailTabDataInitialState = {
    participant1122Prog: null,
    shipmentDays: null,
    shipmentJustification: null,
    associatedLocations: null,
    clarifications: null,
  };
  const [detailTabData, setDetailTabData] = useState(detailTabDataInitialState);

  // min-req and opt-eq tabs
  const [standardECs, setStandardECs] = useState([]);
  const [standardECTable, setStandardECTable] = useState([]);
  const [optionalECs, setOptionalECs] = useState([]);
  const [optionalECTable, setOptionalECTable] = useState([]);
  const [invalidConflictECs, setInvalidConflictECs] = useState([]);

  const getStandardEC = ({ iCat, iData }) => standardECs[iCat].data[iData];
  const getOptionalEC = ({ iCat, iData }) => optionalECs[iCat].data[iData];

  const setEC = (
    setEcFn,
    setEcTableFn,
    { categoryCode, id },
    { data, table },
  ) => {
    const genMapFn = (update) => (item) => {
      if (item.id !== id) return item;
      return { ...item, ...update };
    };
    if (table)
      setEcTableFn((prevItems) =>
        prevItems.map((cat) => {
          if (cat.id === categoryCode) {
            const tableItem = cat.data.find((item) => item.id === id);
            if (table.isExpanded !== tableItem.isExpanded)
              return { ...cat, data: cat.data.map(genMapFn(table)) };
          }
          return cat;
        }),
      );
    if (data)
      setEcFn((prevItems) =>
        prevItems.map((cat) => {
          if (cat.id !== categoryCode) return cat;
          return { ...cat, data: cat.data.map(genMapFn(data)) };
        }),
      );
  };
  const setStandardEC = (original, updates) =>
    setEC(setStandardECs, setStandardECTable, original, updates);
  const setOptionalEC = (original, updates) =>
    setEC(setOptionalECs, setOptionalECTable, original, updates);

  // engine-and-fuel tab
  const [engineData, setEngineData] = useState([]);
  const [engineTable, setEngineTable] = useState([]);
  const setEngine = (id, { data, table }) => {
    if (table)
      setEngineTable((prevItems) =>
        prevItems.map((line) => {
          if (line.id !== id) return line;
          return { ...line, ...table };
        }),
      );
    if (data)
      setEngineData((prevItems) =>
        prevItems.map((line) => {
          if (line.id !== id) return line;
          return { ...line, ...data };
        }),
      );
  };
  const deleteEngineLine = (id) => {
    setEngine(id, { data: { isDeleted: true }, table: { isDeleted: true } });
  };
  const addEngineLine = (ecItem, siTags) => {
    const deletedEngine = engineTable.find((line) => line.id === ecItem.id);
    if (deletedEngine)
      setEngine(deletedEngine.id, {
        data: { isDeleted: false },
        table: { isDeleted: false },
      });
    else {
      const newItem = processEngineItem(ecItem, [], true, [], siTags);
      setEngineData((prev) => {
        const newEngines = [...prev];
        newEngines.unshift(newItem);
        newEngines.sort(sortEngineECsFn);
        setEngineTable(newEngines.map(mapEngineDataToTable));
        return newEngines;
      });
    }
  };

  const [colorsTabData, setColorsTabData] = useState(null);

  const {
    photos,
    documents,
    setDocuments,
    setPhotos,
    loadContractLineAttachments,
    getSignedUrl,
    setSignedUrl,
  } = useDocumentationTab(contractLineId);

  const [contractLine, setContractLine] = useState({});
  const [isContractLineInitialized, setIsContractLineInitialized] = useState(
    false,
  );
  const [contractLineData, setContractLineData] = useState({});
  const [vendorData, setVendorData] = useState({});
  const [vendorId, setVendorId] = useState(null);
  const [isAwardedInFleet, setIsAwardedInFleet] = useState(false);
  const isSOP = isContractSOP(
    contractLine?.contractHeader?.solicitation?.purchaseTypeCode,
  );

  const hasSinReqChassisTag = contractLine?.standardItem?.tags?.value?.includes?.(
    'REQ_CHASSIS_MOD',
  );

  const requiresChassis = isAwardedInFleet && isSOP && hasSinReqChassisTag;

  const showChassis = !isAwardedInFleet || !isSOP ? true : requiresChassis;
  const [contractLineError, setContractLineError] = useState(null);
  let called = false;
  let clLoading = false;
  let refetch = null;

  // fetch contract line data
  const getContractLineQueryResult = useQuery(GET_CONTRACT_LINE_BY_ID, {
    variables: {
      contractLineId: parseFloat(contractLineId),
    },
    fetchPolicy: 'network-only',
    skip: !contractLineId || versionNumber,
    onCompleted: (data) => {
      const results = data?.getContractLineTemplateById;
      if (!results) return;

      setContractLine(results);
      setContractLineData({ data: results });
      setVendorData(results.contractHeader?.vendor);
      setVendorId(results.contractHeader?.vendor?.id);
      setIsAwardedInFleet(Boolean(results.contractHeader?.fleetAwarded));

      const docs = data?.getContractLineTemplateById?.contractLineAttachments?.filter(
        (d) => d.documentType === 'D',
      );
      const pics = data?.getContractLineTemplateById?.contractLineAttachments?.filter(
        (d) => d.documentType === 'I',
      );
      setDocuments(docs);
      setPhotos(pics);
      setIsContractLineInitialized(true);
    },
    onError: (error) => {
      setIsContractLineInitialized(true);
      setContractLineError(error);
    },
  });

  called = called || getContractLineQueryResult.called;
  refetch = refetch || getContractLineQueryResult.refetch;
  clLoading = clLoading || getContractLineQueryResult.loading;

  const getContractLineVersionWithHistory = useQuery(
    GET_CONTRACT_LINE_VERSION_WITH_HISTORY,
    {
      variables: {
        versionNumber: parseFloat(versionNumber),
        contractLineId: parseFloat(contractLineId),
      },
      skip: !contractLineId || !versionNumber,
      onCompleted: (data) => {
        const results = data?.getContractLineVersionWithHistory;
        if (!results) return;

        const contractHeaderVersion = results.contractHeaderVersion || {};
        // NOTE: If the contract line has the latest contract header, there is no history yet.
        // Use the contract header from the contract line.
        const contractHeader = contractHeaderVersion.contractHeaderHistory
          ?.contractUpiid
          ? contractHeaderVersion.contractHeaderHistory
          : contractHeaderVersion.contractHeader;

        const contractLineResults = results.contractLineHistory || {};

        setContractLine({ ...contractLineResults, contractHeader });
        setContractLineData({ data: contractLineResults });
        setVendorData(contractHeader?.vendor);
        setVendorId(contractHeader?.vendor?.id);
        setIsAwardedInFleet(Boolean(contractHeader?.fleetAwarded));

        const docs = contractLineResults.contractLineAttachments?.filter(
          (d) => d.documentType === 'D',
        );
        const pics = contractLineResults.contractLineAttachments?.filter(
          (d) => d.documentType === 'I',
        );
        setDocuments(docs || []);
        setPhotos(pics || []);
        setIsContractLineInitialized(true);
      },
      onError: (error) => {
        setContractLineError(error);
        setIsContractLineInitialized(true);
      },
    },
  );

  clLoading = clLoading || getContractLineVersionWithHistory.loading;
  refetch = refetch || getContractLineVersionWithHistory.refetch;
  clLoading = clLoading || getContractLineVersionWithHistory.loading;

  const { data: siecData, loading: siecLoading } = useQuery(
    GET_EQUIPMENT_OPTIONS,
    {
      fetchPolicy: 'cache-and-network',
      skip: !contractLine?.standardItemId,
      variables: {
        standardItemId: contractLine?.standardItemId,
        getActive: true,
      },
      notifyOnNetworkStatusChange: true,
    },
  );
  const { loading: constLoading } = useQuery(GET_GSA_CONSTANTS, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setFuelTypes(data.fuelTypes);
      setGsaColors(data.gsaColors);
      setStates(data.states);
      setCountries(data.countries);
    },
    onError: () => {},
  });

  useEffect(() => {
    const ecs = siecData?.getEquipmentOptions;
    if (!contractLine || !fuelTypes || !ecs?.length) return;

    const procData = processEquipmentCodesData(
      ecs,
      contractLine,
      isAwardedInFleet,
      fuelTypes,
    );
    const mapDataFn = ({ id, data }) => ({ id, data });
    const mapTableFn = ({ data, tableData, ...catData }) => ({
      ...catData,
      data: tableData,
    });
    setStandardECs(procData.standard.map(mapDataFn));
    setStandardECTable(procData.standard.map(mapTableFn));
    const optECs = procData.optional.map(mapDataFn);
    setOptionalECs(optECs);
    setOptionalECTable(procData.optional.map(mapTableFn));
    setInvalidConflictECs(getInvalidConflictECs(optECs));
    setEngineData(procData.engines);
    setEngineTable(procData.engines.map(mapEngineDataToTable));
  }, [contractLine, siecData, isAwardedInFleet, fuelTypes]);

  const vendorLocQueryResult = useVendorLocations(vendorId);
  const vendorLocations = vendorLocQueryResult?.data?.getVendorLocationsByTypes;

  const loading = clLoading || siecLoading || constLoading;

  const [saveContractLineMutation, { loading: saving }] = useMutation(
    SAVE_CONTRACT_LINE_BY_ID,
    {
      onCompleted: (data) => {
        if (data?.saveContractLineById?.error) {
          setAlert({
            type: 'error',
            message: (
              <div>
                An error occurred while saving the contract line:
                <div className="margin-top-2">
                  <ReviewItemError error={data?.saveContractLineById?.error} />{' '}
                </div>
              </div>
            ),
          });
        } else {
          window.history.replaceState({ showSuccessMessageOnLoad: true }, '');
          window.location.reload();
        }
      },
      onError: (error) => {
        setAlert({
          type: 'error',
          message: `An error occurred while saving the contract line: ${error?.message}`,
        });
      },
    },
  );
  const ErrorContentRenderer = ({ errors = [] }) => (
    <div>
      <p> An error occurred while saving the contract line:</p>
      <ul>
        {errors.map((error) => (
          <li>{error}</li>
        ))}
      </ul>
    </div>
  );

  ErrorContentRenderer.propTypes = {
    errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  };

  const ability = useAppAbility();
  // NOTE: If there is a versionNumber, we are on the history page. We do not allow updates.
  const canUpdateContract =
    !versionNumber && ability.can(OPERATIONS.Update, SUBJECTS.Contract);
  const canUpdateContractAsOrderingAdmin =
    !versionNumber &&
    isSOP &&
    ability.can(OPERATIONS.OrderAdminUpdate, SUBJECTS.Contract);
  const canVendorUpdateContract =
    !versionNumber &&
    isSOP &&
    ability.can(OPERATIONS.VendorUpdate, SUBJECTS.Contract);

  const contextValue = {
    getContractLineQueryResult,
    contractHeaderId,
    contractLineId,
    versionNumber,
    contractLine,
    isAwardedInFleet,
    isSOP,
    contractLineData,
    loading,
    saving,
    contractLineError,
    vendorData,
    showChassis,
    requiresChassis,
    hasSinReqChassisTag,
    isContractLineInitialized,

    gsaColors,
    fuelTypes,
    states,
    countries,

    vendorLocQueryResult,
    vendorLocations,

    saveContractLineMutation,

    alert,
    setAlert,
    called,
    refetch,

    lineTabData,
    setLineTabData,

    detailTabData,
    setDetailTabData,

    standardECs,
    standardECTable,
    setStandardECTable,
    getStandardEC,
    setStandardEC,

    optionalECs,
    optionalECTable,
    setOptionalECTable,
    getOptionalEC,
    setOptionalEC,
    invalidConflictECs,
    setInvalidConflictECs,

    engineData,
    engineTable,
    setEngine,
    deleteEngineLine,
    addEngineLine,

    photos,
    documents,
    loadContractLineAttachments,
    getSignedUrl,
    setSignedUrl,

    colorsTabData,
    setColorsTabData,

    tabIndex,
    setTabIndex,

    makeAvailableForOrdering,
    setMakeAvailableForOrdering,
    canUpdateContract,
    canVendorUpdateContract,
    canUpdateContractAsOrderingAdmin,
  };

  return (
    <ContractLineContext.Provider value={contextValue}>
      {children}
    </ContractLineContext.Provider>
  );
};

ContractLineProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useContractLine = () => useContext(ContractLineContext);
